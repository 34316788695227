import { Col, Row } from "antd";
import React from "react";
import ComponentDropdown from "../../atoms/ComponentDropdown";

import { ReactComponent as TrashIcon } from "../../assets/svg-icons/trashIcon.svg";

import {
  SortableHandle,
  //   SortableContainer,
  //   SortableElement,
} from "react-sortable-hoc";

const style = { padding: "8px 0", display: "flex" };

const DragHandle = SortableHandle(() => (
  <span style={{ cursor: "grab", marginRight: "11px" }}>::</span>
));

const OperatorItem = ({ item, onDeleteEquationItem, indexValue }) => {
  return (
    <div>
      <Row gutter={16}>
        <Col className="gutter-row" span={6}>
          <div style={style}>
            <DragHandle />
            <span style={{ fontSize: "16px" }}>{item?.value}</span>
          </div>
        </Col>
        <Col className="gutter-row" span={6}>
          <div style={style}></div>
        </Col>
        <Col className="gutter-row" span={6}>
          <div style={style}></div>
        </Col>
        <Col className="gutter-row" span={6}>
          <div
            style={{
              ...style,
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            <div style={{ flex: "1 1 auto" }}></div>
            <TrashIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                onDeleteEquationItem(indexValue);
              }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default OperatorItem;
