import "./Header.less";

import logo from "../../assets/logo.svg";
import userIcon from "../../assets/svg-icons/user.svg";
import ComponentPopup from "../../atoms/ComponentPopup";
import { useState } from "react";
import PopupItem from "../../atoms/ComponentPopup/PopupItem";
import { LogoutOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
const Header = () => {
  const [isPopupOpened, setIsPopupOpened] = useState(false);

  const user = useSelector((state) => state.user.userInfo);

  const onLogout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  const getName = () => {
    let name;
    if (user?.firstName) {
      name = user.firstName;
    }
    if (user?.lastName) {
      name = `${name} ${user.lastName}`;
    }
    if (!name) {
      name = user?.email;
    }
    return name;
  };
  return (
    <div className="header-container">
      <div style={{ flex: "1 1 auto" }}>
        <img src={logo} alt="logo" />
      </div>

      <ComponentPopup
        trigger={
          <div
            style={{ display: "flex", alignItems: "center" }}
            onClick={() => {
              setIsPopupOpened(true);
            }}
          >
            <img src={userIcon} alt="user" />
            <div className="header-user-name">{getName()}</div>
          </div>
        }
        handleClose={() => {
          console.log("On Handle close");
          setIsPopupOpened(false);
        }}
        // title="SELECT ACTION"
        open={isPopupOpened}
      >
        <div>
          <PopupItem name="Logout" Icon={LogoutOutlined} onClick={onLogout} />
        </div>
      </ComponentPopup>
    </div>
  );
};

export default Header;
