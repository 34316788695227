import axios from "axios";
import api from "./api-intercepter";

export const fetchYearsHook = (callback) => {
  api
    .get("/fiscal-years")
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};

export const createFinancialYearHook = (yearValue, callback) => {
  const data = JSON.stringify({
    name: yearValue,
  });
  api
    .post("/fiscal-years", data)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};
export const updateFinancialYearHook = (values, callback) => {
  const data = JSON.stringify({
    name: values?.name,
  });
  api
    .patch(`/fiscal-years/${values?.id}`, data)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};
