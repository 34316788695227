import api from "./api-intercepter";
export const getReportTemplatesByTypeHook = (type, companyName, callback) => {
  api
    // .get(
    //   `/report-template-categories?join=templates&&filter=type||eq||${type}&&filter=name||cont||${companyName} -`
    // )
    .get(`/report-template-categories?join=templates&&filter=type||eq||${type}`)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};
