import { Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import ComponentPopup from "../../atoms/ComponentPopup";
import PopupItem from "../../atoms/ComponentPopup/PopupItem";
import "./data-upload.less";
import { useDispatch } from "react-redux";
import {
  setFirstDataSourceId,
  setSecondDataSourceId,
} from "../../store/report";

const HeaderItem = ({
  currentSelectedCol,
  selectedColumns,
  setSelectedColumns,
  index,
  selectedFirstSheet,
}) => {
  const [isPopupOpened, setIsPopupOpened] = useState(false);
  const [nameObj, setNameObj] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!nameObj.id) {
      console.log(nameObj.id, index);
      setSelectedColumns((curr) => {
        const oldState = {};
        Object.keys(curr).forEach((id) => {
          const item = curr[id];
          oldState[id] = {
            ...item,
            col:
              item.col === index + 1 && item.id !== nameObj.id ? -1 : item.col,
          };
        });

        if (oldState[nameObj.id].col === index + 1) {
          oldState[nameObj.id].col = -1;
        } else {
          oldState[nameObj.id] = {
            ...oldState[nameObj.id],
            col: index + 1,
          };
        }
        return oldState;
      });
    }
  }, [nameObj, index]);

  return (
    <ComponentPopup
      trigger={
        <div
          onClick={(e) => {
            setIsPopupOpened(true);
          }}
          className="data-cell is-disabled"
          style={{
            width: "130px",
            // width:
            //   index === 0
            //     ? "50px"
            //     : index === 1
            //     ? "213px"
            //     : index === 2
            //     ? "213px"
            //     : "122px",
            minWidth: "130px",
            // minWidth:
            //   index === 0
            //     ? "50px"
            //     : index === 1
            //     ? "213px"
            //     : index === 2
            //     ? "213px"
            //     : "122px",
            maxWidth: index === 0 ? "32px" : "130px",
            // width: "80px",
            // minWidth: "80px",
            flex: "1 1 auto",
          }}
        >
          <Checkbox checked={!!currentSelectedCol?.id} style={{ width: "90%" }}>
            {!!currentSelectedCol ? currentSelectedCol.label : "Select"}
          </Checkbox>
        </div>
      }
      handleClose={() => {
        console.log("On Handle close");
        setIsPopupOpened(false);
      }}
      title="Select Column"
      open={isPopupOpened}
    >
      {Object.values(selectedColumns).map(({ label, id, col }) => (
        <PopupItem
          name={label}
          isDisabled={col >= 0 && col !== index + 1}
          onClick={() => {
            setNameObj({
              label,
              id,
            });
            setIsPopupOpened(false);
            // if (selectedFirstSheet) {
            dispatch(setFirstDataSourceId(""));

            dispatch(setSecondDataSourceId(""));
          }}
        />
      ))}
    </ComponentPopup>
  );
};

const TableHeader = ({
  totalColumns,
  selectedColumns,
  setSelectedColumns,
  selectedFirstSheet,
}) => {
  const eachHeaders = [
    <div
      className="data-cell is-disabled"
      style={{
        maxWidth: "32px",
        height: "40px",
        flex: "1 1 auto",
        minWidth: "32px",
      }}
    ></div>,
  ];

  let i = 0;
  while (i < totalColumns - 1) {
    const index = i;
    const currentSelectedCol = Object.values(selectedColumns)?.filter(
      (item) => item?.col === index + 1
    )?.[0];
    eachHeaders.push(
      <HeaderItem
        currentSelectedCol={currentSelectedCol}
        selectedColumns={selectedColumns}
        index={index}
        setSelectedColumns={setSelectedColumns}
        selectedFirstSheet={selectedFirstSheet}
      />
    );
    i++;
  }

  return (
    <div className={`table-each-row table-header`} style={{ height: "47px" }}>
      {eachHeaders}
    </div>
  );
};

const TableCommon = ({
  tableRowsData,
  totalColumns,
  selectedColumns,
  setSelectedColumns,
  selectedFirstSheet,
}) => {

  return (
    <div className={"table-common"}>
      {/* <header className={"table-header"}>
        {tableHeaders.map(({ label, width = "40px", flex }) => (
          <div className={"header-cell"} style={{ width, flex }} key={label}>
            {label}
          </div>
        ))}
      </header> */}
      <main className={"table-body"}>
        <TableHeader
          totalColumns={totalColumns}
          selectedColumns={selectedColumns}
          setSelectedColumns={setSelectedColumns}
          selectedFirstSheet={selectedFirstSheet}
        />
       
        {tableRowsData.map(({ isDisabled, colItems }, inx) => {
          let i=0;
          return (
            <div className={`table-each-row`} key={`${inx}-${isDisabled}`}>
              {colItems.map(({ label: cellLabel }, colInx) => {
                const getClass = () => {
                  let className = "data-cell";
                  if (isDisabled) {
                    className = `${className} ${"is-disabled"}`;
                  }
                  if (colInx === 0) {
                    className = `${className} ${"header-bg"} ${"disabled-text"}`;
                  }
                  return className;
                };
                return (
                  <div
                    key={`${colInx}-${cellLabel}`}
                    // className={
                    //   styles[
                    //     `data-cell${isDisabled ? " is-disabled" : ""}${
                    //       tableMetadata.isSerialNum && colInx === 0
                    //         ? " header-bg disabled-text"
                    //         : ""
                    //     }`
                    //   ]
                    // }
                    className={getClass()}
                    style={{
                      width: colInx === 0 ? "32px" : "130px",
                      // colInx === 0
                      //   ? "32px"
                      //   : colInx === 1
                      //   ? "50px"
                      //   : colInx === 2
                      //   ? "213px"
                      //   : "122px",
                      minWidth: colInx === 0 ? "32px" : "130px",
                      // colInx === 0
                      //   ? "32px"
                      //   : colInx === 1
                      //   ? "50px"
                      //   : colInx === 2
                      //   ? "213px"
                      //   : "122px",
                      maxWidth: colInx === 0 ? "32px" : "130px",

                      // maxWidth:
                      //   colInx === 0
                      //     ? "32px"
                      //     : colInx === 1
                      //     ? "50px"
                      //     : colInx === 2
                      //     ? "213px"
                      //     : "122px",
                      flex: "1 1 auto",
                      paddingLeft: colInx === 0 ? "12px" : "16px",
                    }}
                  >
                    {cellLabel}
                  </div>
                );
              })}
              {colItems?.length < 5 && (
                <div
                  // className={
                  //   styles[
                  //     `data-cell${isDisabled ? " is-disabled" : ""}${
                  //       tableMetadata.isSerialNum && colInx === 0
                  //         ? " header-bg disabled-text"
                  //         : ""
                  //     }`
                  //   ]
                  // }
                  className="data-cell"
                  style={{
                    width: "130px",
                    minWidth: "130px",
                    flex: "1 1 auto",
                    paddingLeft: "16px",
                  }}
                ></div>
              )}
            </div>
          );
        })}
      </main>
    </div>
  );
};

export default TableCommon;
