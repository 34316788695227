import "./balance.less";
import React, { useEffect, useState } from "react";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useRouter } from "../../routes";

import {
  statementChangesInEquityMock,
  statementChangesInEquityMock2,
} from "./table-balance.mock";
import ComponentInput from "../../atoms/ComponentInput";
import ComponentButton from "../../atoms/ComponentButton";
import {
  addColumnHook,
  addRowsHook,
  addValuesHook,
  deleteColumnHook,
  deleteRowHook,
  getColumns,
  getRows,
  getValues,
  updateColumnHook,
  updateRowsHook,
  updateValuesHook,
} from "../../api-hooks/equity";
const StatementChangesTable = ({ currentFileName }) => {
  const [equityTable, setEquityTable] = React.useState({
    headers: [],
    rows: [],
    dataItems: [],
    dataItemObj: {},
  });
  const router = useRouter();
  const { query } = router;

  const [headerEditableId, setHeaderEditableId] = useState(null);

  useEffect(() => {
    const statementChangesInEquityMock = {};
    const emptyHeader = {
      label: "",
      width: "180px",
      flex: "1 1 auto",
      minWidth: "180px",
      maxWidth: "180px",
    };
    const headers = [
      // {
      //   label: "",
      //   width: "194px",
      //   flex: "1 1 auto",
      //   minWidth: "230px",
      //   maxWidth: "230px",
      // },
    ];
    const rows = [];
    const dataItems = [];
    const dataItemObj = {};
    getColumns((response) => {
      if (response?.length) {
        response?.forEach((item, index) => {
          headers.unshift({
            id: item?.id,
            label: item?.name,
            width: "180px",
            flex: "1 1 auto",
            minWidth: "180px",
            maxWidth: "180px",
          });
          // if (index > 0) {
          // dataItems.push({
          //   id: "",
          //   label: "",
          //   columnId: item?.id,
          //   rowId: "",
          // });
          // }
        });

        getRows((response) => {
          if (response?.length) {
            response?.forEach((item, rowIndex) => {
              rows.unshift({
                id: item?.id,
                label: item?.name,
                // width: "187px",
                // flex: "1 1 auto",
              });
              // dataItems[rowIndex].rowId = item?.id;
            });

            headers?.forEach((headerItem, index) => {
              // if (index > 0) {
              rows.forEach((rowItem, rowIndex) => {
                dataItems.push({
                  id: "",
                  label: "",
                  columnId: headerItem?.id,
                  rowId: rowItem?.id,
                });
                dataItemObj[`${headerItem?.id}:${rowItem?.id}`] = {
                  id: "",
                  label: "",
                  columnId: headerItem?.id,
                  rowId: rowItem?.id,
                  colIndex: index,
                  rowIndex: rowIndex,
                  index: dataItems?.length - 1,
                };
              });
              // }
            });

            getValues((itemsRepose) => {
              if (itemsRepose?.length) {
                itemsRepose?.forEach((item) => {
                  const dataIndex =
                    dataItemObj[`${item?.column?.id}:${item?.row?.id}`]?.index;
                  dataItems[dataIndex].id = item?.id;
                  dataItems[dataIndex].label = item?.value;
                });
              }
              statementChangesInEquityMock.headers = [emptyHeader, ...headers];
              statementChangesInEquityMock.rows = rows;
              statementChangesInEquityMock.dataItems = dataItems;
              statementChangesInEquityMock.dataItemObj = dataItemObj;

              setEquityTable(statementChangesInEquityMock);
            });
          }
        });
      }
    });
  }, [equityTable?.headers?.length, equityTable?.rows?.length]);

  console.log("statementChangesInEquityMock", equityTable);

  const onSaveNewRow = () => {
    addRowsHook(
      {
        reportId: query?.reportId,
        name: `Row ${equityTable.rows?.length}`,
        visibleIndex: equityTable.rows?.length - 1,
      },
      (response) => {
        if (response) {
          equityTable.rows.push({
            id: response?.id,
            label: response?.name,
          });

          setEquityTable({ ...equityTable });
        }
      }
    );
  };

  const onUpdateRow = (rowId, name, isSave) => {
    if (isSave) {
      updateRowsHook(
        {
          id: rowId,
          name,
        },
        (response) => {
          if (response) {
            equityTable.rows.forEach((row) => {
              if (row.id === rowId) {
                row.label = name;
              }
            });
            setEquityTable({ ...equityTable });
          }
        }
      );
    } else {
      equityTable.rows.forEach((row) => {
        if (row.id === rowId) {
          row.label = name;
        }
      });
      // setEquityTable({ ...equityTable });
    }
  };

  const onSaveNewColumn = () => {
    addColumnHook(
      {
        reportId: query?.reportId,
        name: `Column${equityTable.headers?.length} `,
      },
      (response) => {
        if (response) {
          equityTable.headers.push({
            id: response?.id,
            label: response?.name,
          });

          setEquityTable({ ...equityTable });
        }
      }
    );
  };

  const onUpdateColumn = (columnId, name, isSave) => {
    if (isSave) {
      updateColumnHook(
        {
          id: columnId,
          name,
        },
        (response) => {
          if (response) {
            equityTable.headers.forEach((column) => {
              if (column.id === columnId) {
                column.label = name;
              }
            });
            setEquityTable({ ...equityTable });
          }
        }
      );
    } else {
      equityTable.headers.forEach((row) => {
        if (row.id === columnId) {
          row.label = name;
        }
      });
      // setEquityTable({ ...equityTable });
    }
    setHeaderEditableId(null);
  };

  const onDeleteRow = (rowId) => {
    deleteRowHook(
      {
        id: rowId,
      },
      (response) => {
        const rows = equityTable.rows;
        let deleteIndex = -1;
        rows?.forEach((row, index) => {
          if (row.id === rowId) {
            deleteIndex = index;
          }
        });
        rows.splice(deleteIndex, 1);
        equityTable.rows = [...rows];

        setEquityTable({ ...equityTable });
      }
    );
  };

  const onDeleteColumn = (columnId) => {
    deleteColumnHook(
      {
        id: columnId,
      },
      (response) => {
        const headers = equityTable.headers;
        let deleteIndex = -1;
        headers?.forEach((header, index) => {
          if (header.id === columnId) {
            deleteIndex = index;
          }
        });
        headers.splice(deleteIndex, 1);
        equityTable.headers = [...headers];

        setEquityTable({ ...equityTable });
      }
    );
  };

  const onCreateValue = (rowId, columnId, value) => {
    addValuesHook(
      {
        value: value,
        reportId: query?.reportId,
        rowId: rowId,
        columnId,
      },
      (response) => {
        if (response) {
          const index = equityTable.dataItemObj[`${columnId}:${rowId}`].index;
          equityTable.dataItems[index].label = value;
          setEquityTable({ ...equityTable });
        }
      }
    );
  };

  const onUpdateValue = (valueId, value, rowId, columnId) => {
    updateValuesHook(
      {
        value: value,
        id: valueId,
      },
      (response) => {
        if (response) {
          const index = equityTable.dataItemObj[`${columnId}:${rowId}`].index;
          equityTable.dataItems[index].label = value;
          setEquityTable({ ...equityTable });
        }
      }
    );
  };

  return (
    <div style={{ display: "flex" }}>
      <div className={"table-balance"}>
        <header className={"table-header"}>
          {equityTable?.headers.map(
            (
              {
                label,
                width = "40px",
                flex,
                minWidth,
                maxWidth,
                isEditable,
                id,
              },
              colInx
            ) => (
              <div
                className={"header-cell"}
                style={{
                  width,
                  flex,
                  backgroundColor: "rgba(250, 251, 255, 1)",
                  minWidth,
                  maxWidth,
                }}
                key={label}
                onClick={() => {
                  setHeaderEditableId(id);
                }}
              >
                {colInx > 0 && (
                  <>
                    {(!id || headerEditableId === id) && (
                      <ComponentInput
                        defaultValue={label}
                        onPressEnter={(e) => {
                          onUpdateColumn(id, e.target.value, true);
                        }}
                      />
                    )}

                    {headerEditableId !== id && id && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {label}
                        <div
                          className="statement-of-equity-delete-column"
                          onClick={() => {
                            onDeleteColumn(id);
                          }}
                        >
                          -
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            )
          )}
        </header>
        <main className={"table-body"}>
          {equityTable?.rows?.map?.((row, inx) => {
            return (
              <div className={`table-each-row`} key={`${inx}`}>
                <div
                  key={`${inx}-${row.label}`}
                  className={`data-cell`}
                  style={{
                    width: equityTable?.headers?.[0]?.width,
                    flex: equityTable?.headers?.[0]?.flex,
                    minWidth: equityTable?.headers?.[0]?.minWidth,
                    maxWidth: equityTable?.headers?.[0]?.maxWidth,
                    //   backgroundColor:
                    //     colInx < 2 ? "rgba(250, 251, 255, 1)" : "unset",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <DeleteOutlined
                      style={{ marginRight: "9.5px" }}
                      onClick={() => {
                        onDeleteRow(row.id);
                      }}
                    />
                    <ComponentInput
                      defaultValue={row?.label}
                      onPressEnter={(e) => {
                        onUpdateRow(row.id, e.target.value, true);
                      }}
                    />
                  </div>
                </div>
                {/* {getValues()} */}
                {equityTable?.dataItems?.map((cellVal, colInx) => {
                  if (cellVal?.rowId === row?.id) {
                    let child = "NA";
                    // if (colInx === 0) {
                    //   child = (
                    //     <div style={{ display: "flex", alignItems: "center" }}>
                    //       <DeleteOutlined style={{ marginRight: "9.5px" }} />
                    //       <ComponentInput value={cellVal?.label} />
                    //     </div>
                    //   );
                    // } else {
                    //   child = <ComponentInput value={cellVal?.label} />;
                    // }
                    child = (
                      <ComponentInput
                        defaultValue={cellVal?.label}
                        onPressEnter={(e) => {
                          if (!cellVal?.id) {
                            onCreateValue(
                              row.id,
                              cellVal?.columnId,
                              e.target.value
                            );
                          } else {
                            // update Value
                            onUpdateValue(
                              cellVal?.id,
                              e.target.value,
                              row.id,
                              cellVal?.columnId
                            );
                          }
                          // onUpdateRow(row.id, e.target.value, true);
                        }}
                      />
                    );

                    return (
                      <div
                        key={`${colInx}-${cellVal?.label}`}
                        className={`data-cell`}
                        style={{
                          width: equityTable?.headers?.[colInx]?.width,
                          flex: equityTable?.headers?.[colInx]?.flex,
                          minWidth: equityTable?.headers?.[colInx]?.minWidth,
                          maxWidth: equityTable?.headers?.[colInx]?.maxWidth,
                          //   backgroundColor:
                          //     colInx < 2 ? "rgba(250, 251, 255, 1)" : "unset",
                        }}
                      >
                        {child}
                      </div>
                    );
                  }
                })}
              </div>
            );
          })}
          <div
            style={{
              marginTop: "6px",
              paddingBottom: "8px",
              borderBottom: "1px solid #D8DAE5",
              marginBottom: "10px",
            }}
          >
            <ComponentButton
              title="Add New Row"
              icon={<PlusOutlined />}
              style={{
                color: "#4BB354",
                backgroundColor: "#F5FBF8",
                height: "36px",
                width: "100%",
                border: "none",
              }}
              onClick={onSaveNewRow}
            />
          </div>
        </main>
      </div>
      <div
        className={"statement-of-equity-add-column"}
        onClick={onSaveNewColumn}
      >
        +
      </div>
    </div>
  );
};

export default StatementChangesTable;
