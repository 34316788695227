import { message } from "antd";
import { deleteReportDrive } from "../../api-hooks/report";
import ComponentModal from "../../atoms/ComponentModal";

const DeleteTemplateItemModal = ({
  open,
  handleClose,
  confirmDelete,
  deleteWarningModalName,
}) => {

  return (
    <ComponentModal
      open={open}
      handleClose={handleClose}
      backText="No, Cancel"
      title="Delete This File"
      width={592}
      handleOk={confirmDelete}
      submitText="Yes, Delete"
      footer={true}
    >
      <div style={{ fontSize: "14px", lineHeight: "20px" }}>
        Are you sure you want to Delete this {deleteWarningModalName}? This action can not be
        undone.
      </div>
    </ComponentModal>
  );
};

export default DeleteTemplateItemModal;
