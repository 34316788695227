import parse from "html-react-parser";
import { useState } from "react";
import ComponentDropdown from "../../atoms/ComponentDropdown";

const ReportTemplatePreviewVersion = ({
  selectedTemplate,
  selectedTemplateList,
  setSelectedTemplateList,
  indexValue,
}) => {
  const [selectedVersionId, setSelectedVersionId] = useState(
    selectedTemplate?.versions?.[0]?.id
  );
  const getVersionDropdown = (selectedVersionList) => {
    const dropdownList = [];
    selectedVersionList?.forEach((item) => {
      dropdownList.push({
        label: item?.name,
        value: item.id,
        ...item,
      });
    });
    return dropdownList;
  };

  const getVersionHtml = (versionId) => {
    let text = "<p></p>";
    selectedTemplate?.versions?.forEach((version) => {
      if (version?.id === versionId) {
        text = version?.data?.html;
      }
    });
    return text;
  };
  return (
    <>
      <div className="notes-preview-version-container">
        <div className="notes-preview-disclaimer-text">
          <sup>*</sup>This will not be displayed in your report
        </div>
        <div className="notes-preview-body-template">Main Body template</div>
        <ComponentDropdown
          optionList={getVersionDropdown(selectedTemplate?.versions)}
          value={selectedVersionId}
          style={{ width: "40%" }}
          handleChange={(value) => {
            setSelectedVersionId(value);
            const text = getVersionHtml(value);
            selectedTemplate.data.html = text;
            selectedTemplateList.splice(indexValue, 1, selectedTemplate);
            setSelectedTemplateList([...selectedTemplateList]);
          }}
        />
      </div>
      <div
        style={{ marginTop: "12px", fontSize: "12px" }}
        className="notes-preview-data-container"
      >
        {parse(getVersionHtml(selectedVersionId))}
      </div>
    </>
  );
};

export default ReportTemplatePreviewVersion;
