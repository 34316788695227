import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { bulkUpdateNoteTemSubCatHook } from "../../api-hooks/note-templates";

const NoteExpandableItems = ({
  item,
  index,
  setViewSubSectionTitle,
  viewNoteItemTemplate,
  showNoteTemplateSubId,
  noteTemplateData,
  setNoteTemplateData,
}) => {
  const onDragEnd = (e) => {
    let sourceIndex = e?.source?.index;
    let destinationIndex = e?.destination?.index;

    if (destinationIndex !== sourceIndex) {
      const copyList = [...item.templates];
      if (sourceIndex > destinationIndex) {
        copyList.splice(destinationIndex, 0, item.templates[sourceIndex]);
        copyList.splice(sourceIndex + 1, 1);
      } else if (sourceIndex < destinationIndex) {
        copyList.splice(destinationIndex + 1, 0, item.templates[sourceIndex]);
        copyList.splice(sourceIndex, 1);
      }
      noteTemplateData.map((noteItem) => {
        if (noteItem?.id === item?.id) {
          noteItem.templates = copyList;
        }
      });
      setNoteTemplateData([...noteTemplateData]);
      const updatedArray = [...copyList];

      const updatedList = [];
      updatedArray.forEach((item, index) => {
        updatedList.push({
          ...item,
          visibleIndex: index + 1,
        });
      });
      bulkUpdateNoteTemSubCatHook(updatedList);
    }
  };

  return (
    <div className="expanded-item-list">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId="droppable"
          direction="vertical"
          ignoreContainerClipping={true}
        >
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="dropp-able-box"
            >
              {item.templates?.map((subItem, subIndex) => {
                if (subItem.data.name) {
                  let style = {};
                  if (subItem.id === showNoteTemplateSubId) {
                    style = {
                      backgroundColor: "#f5fbf8",
                      border: "0.5 solid #208460",
                      color: "#3d8e44",
                    };
                  }

                  return (
                    <Draggable
                      key={subItem.id}
                      draggableId={subItem.id}
                      index={subIndex}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          item={JSON.stringify(subItem)}
                        >
                          <div
                            className="subitem-name"
                            style={style}
                            onClick={() => {
                              setViewSubSectionTitle(true);
                              viewNoteItemTemplate(
                                item,
                                index,
                                subItem,
                                subIndex
                              );
                            }}
                          >
                            {subItem.data.name}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                }
              })}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};
export default NoteExpandableItems;
