import ContentCard from "../../atoms/ContentCard";
import React, { useEffect, useState } from "react";
import LeftControl from "../../sub-components/LeftControl";
import RightControl from "../../sub-components/RightControl";
import Title from "../../atoms/Title";
import ComponentTitle from "../../atoms/ComponentTitle";
import ComponentDropdown from "../../atoms/ComponentDropdown";
import { PlusOutlined } from "@ant-design/icons";
import ComponentItem from "../../atoms/ComponentItem";
import BalanceSheet from "./BalanceSheet";
import IncomeSheet from "./IncomeSheet";
import StatementChangesInEquity from "./StatementChangesInEquity";
import { useRouter } from "../../routes";
import {
  getAccountByType,
  getAccountDataHook,
  getReportAccountList,
  getReportByIdHook,
  getReportGenerators,
  updateReportAccountingTemplateHook,
} from "../../api-hooks/report";
import ComponentButton from "../../atoms/ComponentButton";
import AccountTemplatesHoc from "../../sub-components/AccountTemplatesHoc";
import { useSelector } from "react-redux";
import {
  applyTemplateToReportHook,
  removeAccountMapping,
  updateAccountTemplatesHook,
} from "../../api-hooks/account-templates";
import PreviewSheet from "./PreviewSheet";
import EquationPreviewSheet from "./EquationPreviewSheet";
import ChangeMappingLogicWarning from "../../sub-components/ChangeMappingLogicWarning";
import { find } from "lodash";
import SaveNewTemplateModal from "../../sub-components/SaveNewTemplateModal";
import AllAccountSheet from "./AllAccountSheet";
import CategoryWarningModal from "../../sub-components/CategoryWarningModal";

const StepTwo = ({
  boxHeight,
  balanceSheetData,
  incomeSheetData,
  currentFileName,
  setBalanceSheetData,
  setIncomeSheetData,
  allAccountsData,
  setAllAccountsData,
}) => {
  const [selectedtemplate, setSelectedTemplate] = useState("");
  const [selectedStatement, setSelectedStatement] = useState("Balance Sheet");
  const [isBalaceMapped, setIsBalanceMapped] = useState(false);
  const [isIncomeMapped, setIsIncomeMapped] = useState(false);
  const [isSelectedMapped, setIsSelectedMapped] = useState(false);
  const [isSaveNewTemplateOpened, setIsSaveNewTemplateOpened] = useState(false);

  const [isWarningDialogOpened, setIsWarningDialogOpened] = useState(false);

  const [isMappingInitiated, setIsMappingInitiated] = useState(false);

  const [applyTemplateId, setApplyTemplateId] = useState("");

  const [selectedTemplateObj, setSelectedTemplateObj] = useState({});

  const templates = useSelector((state) => state.accountTemplates.templates);

  const router = useRouter();

  const { query } = router;

  const [isSheetPreviewEnabled, setIsSheetPreviewEnabled] = useState(false);
  const [accountListObj, setAccountListObj] = useState({});

  const [isMappingChanged, setIsMappingChanged] = useState(false);
  const [duplicateReportId, setDuplicateReportId] = useState("");
  const [showAllCategoryData, setShowAllCategorydata] = useState([]);
  const [showCategoryDataModal, setShowCategoryDataModal] = useState(false);

  const reportPeriodObj = useSelector((store) => store.report.reportPeriod);

  const closeWarningDialog = () => {
    setIsWarningDialogOpened(false);
  };
  const closeCategoryDataModal = () => {
    setShowCategoryDataModal(false);
  };

  const openWarningDialog = () => {
    setIsWarningDialogOpened(true);
  };
  useEffect(() => {
    getReportAccountList({ reportId: query?.reportId }, (response) => {
      let tableAllAccountMock = {
        headers: [
          // 1st and 2nd index are checkbox and text respectively, and post that are all dropdowns
          {
            label: "",
            width: "39px",
          },
          {
            label: "Account Name",
            width: "187px",
            flex: "1 1 auto",
          },
          {
            label: "Category",
            width: "172px",
          },
          {
            label: "FS ITEM",
            width: "169px",
          },
        ],
        dataItems: [],
      };
      let tableBalanceMock = {
        headers: [
          // 1st and 2nd index are checkbox and text respectively, and post that are all dropdowns
          {
            label: "B/S",
            width: "39px",
          },
          {
            label: "Account Name",
            width: "187px",
            flex: "1 1 auto",
          },
          {
            label: "B/S Category",
            width: "172px",
          },
          {
            label: "FS ITEM",
            width: "169px",
          },
        ],
        dataItems: [],
      };
      let balaceDataItems = [];
      let accountListObj = {};
      let allDataItems = [];
      console.log("Report Account List", response);
      Object.keys(response).forEach((key) => {
        const accounData = response[key]?.accounts;
        const firstItem = accounData[0];
        const secondItem = accounData[1];
        accountListObj[firstItem?.id] = secondItem?.id;
        // setAccountListObj;
        // accountList.push({
        //   firstAccountItemId: firstItem?.id,
        //   secondAccountItemId: secondItem?.id,
        // });
        balaceDataItems.push({
          colItems: [
            {
              itemType: firstItem?.category?.type,
              isChecked: firstItem?.category?.type === "balance_item",
            },
            {
              accountId: firstItem?.id,
              label: firstItem?.name,
            },
            {
              id: firstItem?.category?.parent?.id,
              name: firstItem?.category?.parent?.name,
              confidence: 0,
              value: 0,
            },
            {
              id: firstItem?.category?.id,
              name: firstItem?.category?.name,
              confidence: 0,
              value: 0,
            },
          ],
        });
        allDataItems.push({
          colItems: [
            {
              itemType:
                firstItem?.category?.type === "income_item"
                  ? "I/S"
                  : firstItem?.category?.type === "balance_item"
                  ? "B/S"
                  : "",
              // isChecked: true
            },
            {
              accountId: firstItem?.id,
              label: firstItem?.name,
            },
            {
              id: firstItem?.category?.parent?.id,
              name: firstItem?.category?.parent?.name,
              confidence: 0,
              value: 0,
            },
            {
              id: firstItem?.category?.id,
              name: firstItem?.category?.name,
              confidence: 0,
              value: 0,
            },
          ],
        });
        if (!isBalaceMapped && firstItem?.category?.type === "balance_item") {
          setIsBalanceMapped(true);
        }
      });

      console.log("balaceDataItems *****", [...balaceDataItems]);
      setAccountListObj({ ...accountListObj });

      console.log("Before Table Balance Mock", tableBalanceMock);

      tableBalanceMock.dataItems = [...balaceDataItems];
      tableAllAccountMock.dataItems = [...allDataItems];

      console.log("Table Balance Mock", tableBalanceMock);

      setBalanceSheetData(tableBalanceMock);
      setAllAccountsData(tableAllAccountMock);

      let tableIncomeMock = {
        headers: [
          // 1st and 2nd index are checkbox and text respectively, and post that are all dropdowns
          {
            label: "I/S",
            width: "39px",
          },
          {
            label: "Account Name",
            width: "187px",
            flex: "1 1 auto",
          },
          {
            label: "I/S Category",
            width: "172px",
          },
          {
            label: "FS ITEM",
            width: "169px",
          },
        ],
        dataItems: [],
      };
      let incomeDataItems = [];

      Object.keys(response).forEach((key) => {
        const accounData = response[key]?.accounts;
        const firstItem = accounData[0];
        incomeDataItems.push({
          colItems: [
            {
              itemType: firstItem?.category?.type,
              isChecked: firstItem?.category?.type === "income_item",
            },
            {
              accountId: firstItem?.id,
              label: firstItem?.name,
            },
            {
              id: firstItem?.category?.parent?.id,
              name: firstItem?.category?.parent?.name,
              confidence: 0,
              value: 0,
            },
            {
              id: firstItem?.category?.id,
              name: firstItem?.category?.name,
              confidence: 0,
              value: 0,
            },
          ],
        });
        if (!isIncomeMapped && firstItem?.category?.type === "income_item") {
          setIsIncomeMapped(true);
        }
      });

      tableIncomeMock.dataItems = incomeDataItems;

      setIncomeSheetData(tableIncomeMock);
    });
    getReportByIdHook(query?.reportId, (response) => {
      if (response?.selectedAccountTemplate) {
        setSelectedTemplateObj({
          templateId: response?.selectedAccountTemplate?.id,
          templateName: response?.selectedAccountTemplate?.name,
        });
      }
      setDuplicateReportId(response?.duplicatefromReport);
    });
  }, [query?.reportId, isMappingInitiated]);

  const updateAllData = (callback) => {
    getReportAccountList({ reportId: query?.reportId }, (response) => {
      let allDataItems = [];

      Object.keys(response).forEach((key) => {
        const accounData = response[key]?.accounts;
        const firstItem = accounData[0];
        const secondItem = accounData[1];

        // setAccountListObj;
        // accountList.push({
        //   firstAccountItemId: firstItem?.id,
        //   secondAccountItemId: secondItem?.id,
        // });
        if (!firstItem?.category?.type) {
          allDataItems.push({
            id: firstItem?.id,
            label: firstItem?.name,
          });
        }
      });
      console.log("All Data Items", allDataItems);
      if (allDataItems.length) {
        setShowAllCategorydata(allDataItems);
      }
      callback(allDataItems);
    });
  };
  console.log("All data Items Final", showAllCategoryData);

  useEffect(() => {
    getAccountByType(
      {
        reportId: query?.reportId,
        type: "balance_item",
      },
      (response) => {
        if (response?.length) {
          setIsBalanceMapped(true);
        } else {
          setIsBalanceMapped(false);
        }
      }
    );
    getAccountByType(
      {
        reportId: query?.reportId,
        type: "income_item",
      },
      (response) => {
        if (response?.length) {
          setIsIncomeMapped(true);
        } else {
          setIsIncomeMapped(false);
        }
      }
    );
  }, [isMappingInitiated]);

  const getCheckedData = () => {
    let checkedData = {};
    // console.log(
    //   "Balance Sheet Data",
    //   balanceSheetData,
    //   selectedStatement,
    //   incomeSheetData
    // );
    if (
      balanceSheetData?.dataItems?.length &&
      selectedStatement !== "Income Statement"
    ) {
      balanceSheetData.dataItems.forEach((data, index) => {
        console.log("Checked Data", data);
        if (data?.colItems?.[0]?.isChecked) {
          checkedData = {
            ...checkedData,
            [index]: true,
          };
        }
      });
      // setCheckedRows({...checkedData});
    } else if (
      incomeSheetData?.dataItems?.length &&
      selectedStatement === "Income Statement"
    ) {
      incomeSheetData.dataItems.forEach((data, index) => {
        if (data?.colItems?.[0]?.isChecked) {
          checkedData = {
            ...checkedData,
            [index]: true,
          };
        }
      });

      // setCheckedRows({...checkedData});

      // console.log("Income Statememnt Checked Data", checkedData);
    }
    return checkedData;
  };

  const getTemplatesDropdown = () => {
    let list = [];
    templates?.forEach((item) => {
      list.push({
        label: item?.name,
        value: item?.id,
      });
    });

    return list;
  };

  const user = useSelector((state) => state.user.userInfo);

  const applyMapping = () => {
    const templateId = selectedtemplate;

    const reportId = query?.reportId;
    if (reportId && templateId) {
      setIsMappingInitiated(true);
      getReportGenerators(
        {
          reportId: reportId,
        },
        (reportGeneratorsReponse) => {
          applyTemplateToReportHook(
            {
              reportId,
              templateId,
              reportGenerators: reportGeneratorsReponse,
              reportPeriodObj: reportPeriodObj,
            },
            user?.email,
            (response) => {
              updateReportAccountingTemplateHook(
                {
                  id: reportId,
                  templateId,
                },
                (response) => {
                  console.log("Mapping Suucessfully applied", response);
                  setSelectedTemplateObj({
                    templateId: templateId,
                    templateName: find(templates, (item) => {
                      if (item?.id === templateId) {
                        return item?.name;
                      }
                    })?.name,
                  });
                  setSelectedTemplate("");
                  setIsMappingInitiated(false);
                }
              );
              setSelectedTemplate("");
              setIsMappingInitiated(false);
            }
          );
        }
      );
    }
  };

  const removeMapping = () => {
    setIsMappingInitiated(true);
    const reportId = query?.reportId;
    removeAccountMapping(
      {
        reportId: query?.reportId,
      },
      (response) => {
        updateReportAccountingTemplateHook(
          {
            id: reportId,
            templateId: null,
          },
          (response) => {
            console.log("Mapping Suucessfully removed", response);
            setSelectedTemplateObj({
              templateId: "",
              templateName: "",
            });
            setSelectedTemplate("");
            setIsMappingInitiated(false);
          }
        );
      }
    );
  };

  const onCreateNewTemplate = () => {
    setIsSaveNewTemplateOpened(true);
  };

  const onCloseSaveNewTemplateModal = () => {
    console.log("Close getting called");
    setIsSaveNewTemplateOpened(false);
  };

  console.log("setShowCategoryDatamodal", showCategoryDataModal);
  const saveAccountTemplate = () => {
    let accountObj = {};
    getAccountDataHook({ reportId: query?.reportId }, (response) => {
      console.log("Get Account Data Inside Template", response);
      response?.forEach((data) => {
        accountObj[data?.name] = data;
      });
      const templateAccountList = [];
      Object.keys(accountObj).forEach((key) => {
        const item = accountObj[key];
        // {
        //     "name": "Furniture and Fixtures",
        //     "category": {
        //         "type": "balance_item",
        //         "name": "Property, plant and equipment",
        //         "parent": {
        //             "type": "balance_header",
        //             "name": "NON-CURRENT ASSET"
        //         }
        //     }
        // },
        templateAccountList.push({
          name: key,
          category: {
            type: item?.category?.type,
            name: item?.category?.name,
            parent: {
              type: item?.category?.parent?.type,
              name: item?.category?.parent?.name,
            },
          },
        });
      });
      console.log("Final Account Obj", templateAccountList);
      updateAccountTemplatesHook(
        {
          id: selectedTemplateObj?.templateId,
          accounts: templateAccountList,
          name: selectedTemplateObj?.name,
        },
        (response) => {
          console.log("Template Updated Successfully", response);
          setIsMappingChanged(false);
        }
      );
    });
  };

  return (
    <div style={{ display: "flex", flex: "1 1 auto" }}>
      <LeftControl boxHeight={boxHeight}>
        <ContentCard
          style={{ padding: 32, height: "100%" }}
          bodyStyle={{ padding: 0 }}
          size="large"
        >
          <div style={{ width: "inherit" }}>
            <Title
              title="FS Item Mapping"
              titleStyle={{
                fontSize: "18px",
                fontWeight: "800",
                lineHeight: "24px",
              }}
            />
            <div style={{ marginTop: "28px" }}>
              <ComponentTitle name="Select Mapping Template" />
              <AccountTemplatesHoc
                isSaveNewTemplateOpened={isSaveNewTemplateOpened}
              >
                <ComponentDropdown
                  style={{ width: "100%" }}
                  optionList={getTemplatesDropdown()}
                  value={selectedtemplate}
                  handleChange={(value) => {
                    setSelectedTemplate(value);
                  }}
                />
              </AccountTemplatesHoc>

              <ComponentButton
                title="Apply Mapping Template"
                style={{ marginTop: "13px" }}
                disabled={!selectedtemplate}
                onClick={() => {
                  const templateId = selectedtemplate;

                  const reportId = query?.reportId;
                  console.log("template Id", reportId, templateId);
                  if (isBalaceMapped && isIncomeMapped) {
                    openWarningDialog();
                  } else {
                    applyMapping();
                    // if (reportId && templateId) {
                    //   setIsMappingInitiated(true);
                    //   applyTemplateToReportHook(
                    //     {
                    //       reportId,
                    //       templateId,
                    //     },
                    //     (response) => {
                    //       console.log("Mapping Suucessfully applied", response);
                    //       setSelectedTemplate("");
                    //       setIsMappingInitiated(false);
                    //     }
                    //   );
                    // }
                  }
                }}
              />
              {/* <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "13px",
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "16px",
                  color: "#4BB354",
                  marginLeft: "7px",
                  cursor: "pointer",
                }}
              >
                <PlusOutlined />
                <div style={{ marginLeft: "11px" }}>Create New Template</div>
              </div> */}
            </div>

            <div style={{ marginTop: "36px" }}>
              <ComponentTitle name="Select the Statement" />
              <div style={{ marginTop: "19px" }}>
                <ComponentItem
                  name="Show All"
                  isSelected={selectedStatement === "Show All"}
                  onClick={() => {
                    setSelectedStatement("Show All");
                  }}
                  isCompleted={isIncomeMapped && isBalaceMapped}
                />
                <ComponentItem
                  name="Balance Sheet"
                  isSelected={selectedStatement === "Balance Sheet"}
                  onClick={() => {
                    setSelectedStatement("Balance Sheet");
                  }}
                  isCompleted={isBalaceMapped}
                />
                <ComponentItem
                  name="Income Statement"
                  isSelected={selectedStatement === "Income Statement"}
                  onClick={() => {
                    setSelectedStatement("Income Statement");
                  }}
                  isCompleted={isIncomeMapped}
                />
                {/* <ComponentItem
                  name="Statement of Changes in Equity"
                  isSelected={
                    selectedStatement === "Statement of Changes in Equity"
                  }
                  onClick={() => {
                    setSelectedStatement("Statement of Changes in Equity");
                  }}
                /> */}
              </div>
            </div>

            {/*  show equations starts */}
            {/* {isIncomeMapped && isBalaceMapped && (
              <div style={{ marginTop: "36px" }}>
                <ComponentTitle name="Manage Formula" />
                <div style={{ marginTop: "19px" }}>
                  <ComponentItem
                    name="Show Equations"
                    isSelected={selectedStatement === "Show Equations"}
                    onClick={() => {
                      setSelectedStatement("Show Equations");
                    }}
                  />
                </div>
              </div>
            )} */}
            {/*  show equations ends */}
          </div>
        </ContentCard>
      </LeftControl>
      <RightControl boxHeight={boxHeight}>
        <ContentCard
          style={{ padding: "0px 32px", height: "100%" }}
          bodyStyle={{ padding: 0, height: "100%" }}
          size="large"
        >
          {!selectedStatement && (
            <div
              style={{
                height: "inherit",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              No actions Available
            </div>
          )}
          {/* show all sheet */}
          {selectedStatement === "Show All" && (
            <>
              {!isSheetPreviewEnabled && (
                <AllAccountSheet
                  selectedStatement={selectedStatement}
                  incomeSheetData={allAccountsData}
                  checkedData={getCheckedData()}
                  setIsBalanceMapped={setIsBalanceMapped}
                  setIsIncomeMapped={setIsIncomeMapped}
                  isLoading={isMappingInitiated}
                  setIsSheetPreviewEnabled={setIsSheetPreviewEnabled}
                  templateId={selectedTemplateObj?.templateId}
                  templateName={selectedTemplateObj?.templateName}
                  isIncomeMapped={isIncomeMapped}
                  isBalaceMapped={isBalaceMapped}
                  onCreateNewTemplate={onCreateNewTemplate}
                  accountListObj={accountListObj}
                  removeMapping={removeMapping}
                  setIsMappingChanged={setIsMappingChanged}
                />
              )}
              {/* {isSheetPreviewEnabled && (
              <PreviewSheet
                type="income_item"
                title="Income Statement Preview"
                setIsSheetPreviewEnabled={setIsSheetPreviewEnabled}
              />
            )} */}
            </>
          )}
          {/* show all sheet ends */}
          {selectedStatement === "Balance Sheet" && (
            <>
              {!isSheetPreviewEnabled && (
                <BalanceSheet
                  selectedStatement={selectedStatement}
                  balanceSheetData={balanceSheetData}
                  checkedData={getCheckedData()}
                  setIsBalanceMapped={setIsBalanceMapped}
                  setIsIncomeMapped={setIsIncomeMapped}
                  isLoading={isMappingInitiated}
                  setIsSheetPreviewEnabled={setIsSheetPreviewEnabled}
                  templateId={selectedTemplateObj?.templateId}
                  templateName={selectedTemplateObj?.templateName}
                  isBalaceMapped={isBalaceMapped}
                  isIncomeMapped={isIncomeMapped}
                  onCreateNewTemplate={onCreateNewTemplate}
                  accountListObj={accountListObj}
                  removeMapping={removeMapping}
                  setIsMappingChanged={setIsMappingChanged}
                  isMappingChanged={isMappingChanged}
                  setSelectedTemplateObj={setSelectedTemplateObj}
                  saveAccountTemplate={saveAccountTemplate}
                  updateAllData={updateAllData}
                  setShowCategoryDataModal={setShowCategoryDataModal}
                />
              )}
              {isSheetPreviewEnabled && (
                <PreviewSheet
                  type="balance_item"
                  title="Balance Sheet Preview"
                  setIsSheetPreviewEnabled={setIsSheetPreviewEnabled}
                  setIsMappingChanged={setIsMappingChanged}
                  isMappingChanged={isMappingChanged}
                />
              )}
            </>
          )}
          {selectedStatement === "Income Statement" && (
            <>
              {!isSheetPreviewEnabled && (
                <IncomeSheet
                  selectedStatement={selectedStatement}
                  incomeSheetData={incomeSheetData}
                  checkedData={getCheckedData()}
                  setIsBalanceMapped={setIsBalanceMapped}
                  setIsIncomeMapped={setIsIncomeMapped}
                  isLoading={isMappingInitiated}
                  setIsSheetPreviewEnabled={setIsSheetPreviewEnabled}
                  templateId={selectedTemplateObj?.templateId}
                  templateName={selectedTemplateObj?.templateName}
                  isIncomeMapped={isIncomeMapped}
                  isBalaceMapped={isBalaceMapped}
                  onCreateNewTemplate={onCreateNewTemplate}
                  accountListObj={accountListObj}
                  removeMapping={removeMapping}
                  setIsMappingChanged={setIsMappingChanged}
                  isMappingChanged={isMappingChanged}
                  setSelectedTemplateObj={setSelectedTemplateObj}
                  saveAccountTemplate={saveAccountTemplate}
                  updateAllData={updateAllData}
                  setShowCategoryDataModal={setShowCategoryDataModal}
                />
              )}
              {isSheetPreviewEnabled && (
                <PreviewSheet
                  type="income_item"
                  title="Income Statement Preview"
                  setIsSheetPreviewEnabled={setIsSheetPreviewEnabled}
                />
              )}
            </>
          )}
          {/* selected statement mange equations */}
          {/* {selectedStatement === "Show Equations" && (
            <>
              <EquationPreviewSheet
                type="equation_item"
                title="Equation Statement Preview"
                setIsSheetPreviewEnabled={setIsSheetPreviewEnabled}
              />
            </>
          )} */}
          {/* selected statement mange equations ends*/}
          {selectedStatement === "Statement of Changes in Equity" && (
            <StatementChangesInEquity currentFileName={currentFileName} />
          )}
        </ContentCard>
      </RightControl>
      {showCategoryDataModal && (
        <CategoryWarningModal
          open={showCategoryDataModal}
          handleClose={closeCategoryDataModal}
          showAllCategoryData={showAllCategoryData}
          setShowCategoryDataModal={setShowCategoryDataModal}
        />
      )}
      {isWarningDialogOpened && (
        <ChangeMappingLogicWarning
          open={isWarningDialogOpened}
          handleClose={closeWarningDialog}
          applyMapping={applyMapping}
          duplicateReportId={duplicateReportId}
          selectedTemplateObj={selectedTemplateObj}
        />
      )}
      {isSaveNewTemplateOpened && (
        <SaveNewTemplateModal
          open={isSaveNewTemplateOpened}
          handleClose={onCloseSaveNewTemplateModal}
          setSelectedTemplateObj={setSelectedTemplateObj}
          setIsMappingChanged={setIsMappingChanged}
        />
      )}
    </div>
  );
};

export default StepTwo;
