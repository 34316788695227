import { DatePicker } from "antd";
import moment from "moment";
const ComponentDatePicker = ({ value, placeholder, onChange }) => {
  return (
    <DatePicker
      value={value && moment(value)}
      placeholder={placeholder}
      format={"DD/MM/YYYY"}
      style={{ width: "100%" }}
      onChange={(value) => {
        console.log("Component Date Picker", value);
        onChange(value);
        // dateRangeValue[0] = value;
        // setDateRangeValue([...dateRangeValue]);
        // //参数变化：通知父组件
        // updateParentData({
        //   dataType: dataType,
        //   dateRangeVal: dateRangeValue,
        // });
      }}
    />
  );
};

export default ComponentDatePicker;
