import React from "react";
const Operator = ({ operator, onClick }) => {
  return (
    <div onClick={onClick} className="operator-container">
      {operator}
    </div>
  );
};

export default Operator;
