import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import ComponentDropdown from "../../atoms/ComponentDropdown";
import {
  SortableHandle,
  SortableContainer,
  SortableElement,
} from "react-sortable-hoc";

import { ReactComponent as TrashIcon } from "../../assets/svg-icons/trashIcon.svg";
import { numberWithCommas } from "../../helper";

const style = { padding: "8px 0" };

const DragHandle = SortableHandle(() => (
  <span style={{ cursor: "grab", marginRight: "11px" }}>::</span>
));

const EquationItem = ({
  yearList,
  previewObj,
  itemAmountObj,
  onDeleteEquationItem,
  indexValue,
  onUpdateEquationItemValue,
  item,
  hideTrash,
  hideDrag,
}) => {
  console.log(
    "Item Equation Type",
    item,
    previewObj[item?.equationType],
    itemAmountObj
  );
  const [accountList, setAccountList] = useState([]);

  // const getAccountList = () => {
  //   if (item?.equationType) {
  //     return previewObj[item?.equationType];
  //   }
  // };
  useEffect(() => {
    if (item?.equationType) {
      setAccountList(previewObj[item?.equationType]);
    }
  }, [item?.equationType]);
  console.log("Account Liost", accountList, item);
  return (
    <div>
      <Row gutter={16}>
        <Col className="gutter-row" span={6}>
          <div style={{ ...style, display: "flex" }}>
            {!hideDrag && <DragHandle />}
            <ComponentDropdown
              style={{ width: "100%", fontSize: "12px" }}
              optionList={yearList}
              value={item?.year}
              allowClear
              handleChange={(value) => {
                // setSelectedYear(value);
                onUpdateEquationItemValue(indexValue, "year", value);
              }}
            />
          </div>
        </Col>
        <Col className="gutter-row" span={6}>
          <div style={style}>
            {" "}
            <ComponentDropdown
              style={{ width: "100%", fontSize: "12px" }}
              optionList={[
                {
                  label: "Balance sheet parent account",
                  value: "balance_header",
                },
                {
                  label: "Balance sheet child account",
                  value: "balance_item",
                },
                {
                  label: "Income parent account",
                  value: "income_header",
                },

                {
                  label: "Income child account",
                  value: "income_item",
                },
                {
                  label: "Equation",
                  value: "equation",
                },
              ]}
              value={item?.equationType}
              handleChange={(value) => {
                onUpdateEquationItemValue(indexValue, "equationType", value);
                onUpdateEquationItemValue(indexValue, "value", "");
              }}
            />
          </div>
        </Col>
        <Col className="gutter-row" span={6}>
          <div style={style}>
            <ComponentDropdown
              style={{ width: "100%", fontSize: "12px" }}
              optionList={previewObj[item?.equationType]}
              value={item?.value}
              handleChange={(value) => {
                onUpdateEquationItemValue(indexValue, "value", value);
              }}
            />
          </div>
        </Col>
        <Col className="gutter-row" span={6}>
          <div
            style={{
              ...style,
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            <div style={{ flex: "1 1 auto", textAlign: "center" }}>
              {item?.value &&
                numberWithCommas(
                  itemAmountObj?.[item?.value]?.[item?.year || "current"]
                )}
            </div>
            {!hideTrash && (
              <TrashIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  onDeleteEquationItem(indexValue);
                }}
              />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default EquationItem;
