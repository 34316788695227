import { createSlice } from "@reduxjs/toolkit";
// Slice
const reportTemplatesSlice = createSlice({
  name: "reportTemplates",
  initialState: {
    text: [],
    notes: [],
  },
  reducers: {
    setTextTemplates: (state, action) => {
      state.text = action.payload;
    },
    setNotesTemplates: (state, action) => {
      state.notes = action.payload;
    },
  },
});

export default reportTemplatesSlice.reducer;
// Actions
export const { setTextTemplates, setNotesTemplates } =
  reportTemplatesSlice.actions;
