import React from "react";

import "./ComponentPopupWithSearch.less";
import { ReactComponent as EditIcon } from "../../assets/svg-icons/editIcon.svg";
import ComponentInput from "../ComponentInput";


const PopupItem = ({ item, onSelect,showReportEdit, updateData }) => {
  const [inputEdit, setInputEdit] = React.useState(false);
  const [inputEditData, setInputEditData] = React.useState('');
  const [itemId, setItemId] = React.useState('');
  console.log("getting report edit value",item)
  return (
    <div
      className={"popup-item-container"}
    >
      {!inputEdit &&(
          <>
            <div style={{ flex: "1 1 auto" ,gap:'5px' }}  onClick={() => {
              onSelect(item);
            }}>{item?.name}</div>
            {showReportEdit && (
              <EditIcon onClick={()=>{
                setInputEdit(true);
                setInputEditData(item?.name);
                setItemId(item?.id)
              }}/>
            )}
          </>
        )
      }
      {inputEdit &&(
          <>
            <ComponentInput
              placeholder={item.name}
              value={inputEditData}
              onChange={(value) => {
                setInputEditData(value);
              }}
              onPressEnter={(value) =>{
                updateData(itemId, value, () => {
                  console.log("item value new",value,itemId)
                  setInputEdit(false);
                  setInputEditData(value);
                })
              }}
            />
          </>
        )
      }
      
      {item?.percentage && <div style={{marginLeft:'5px'}}>{item?.percentage}</div>}
    </div>
  );
};

export default PopupItem;
