import { Mark, mergeAttributes } from "@tiptap/core";

// export interface DoubleUnderlineOptions {
//   HTMLAttributes: Record<string, any>;
// }

// declare module "@tiptap/core" {
//   interface Commands<ReturnType> {
//     ["double-underline"]: {
//       /**
//        * Set an underline mark
//        */
//       setUnderline: () => ReturnType;
//       /**
//        * Toggle an underline mark
//        */
//       toggleUnderline: () => ReturnType;
//       /**
//        * Unset an underline mark
//        */
//       unsetUnderline: () => ReturnType;
//     };
//   }
// }

export const Underline = Mark.create({
  name: "underline",

  addOptions() {
    return {
      HTMLAttributes: {
        style: "border-bottom:1px solid;text-decoration:none",
        // style: {
        //   paddingBottom: "10px",
        //   borderBottom: "4px double",
        //   textDecoration: "none",
        // },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "u",
      },
      {
        style:
          "padding-bottom:10px;border-bottom:1px solid;text-decoration:none",
        consuming: false,
        getAttrs: (style) =>
          style.includes("double-underline")
            ? {
                paddingBottom: "10px",
                borderBottom: "1px solid",
                textDecoration: "none",
              }
            : false,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    console.log("HtmlAttributes", HTMLAttributes);
    return [
      "u",
      // {
      //   style: `padding-bottom:10px;border-bottom:4px double;text-decoration:none`,
      // },
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },

  addCommands() {
    return {
      setUnderline:
        () =>
        ({ commands }) => {
          return commands.setMark(this.name);
        },
      toggleUnderline:
        () =>
        ({ commands }) => {
          return commands.toggleMark(this.name);
        },
      unsetUnderline:
        () =>
        ({ commands }) => {
          return commands.unsetMark(this.name);
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      "Mod-u": () => this.editor.commands.toggleDoubleUnderline(),
      "Mod-U": () => this.editor.commands.toggleDoubleUnderline(),
    };
  },
});
