import React, { useContext, useEffect, useState } from "react";

import ReportSteps from "./ReportSteps";

import MainContainer from "../../sub-components/MainContainer";

import "./CreateOrEditReport.less";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import StepFive from "./StepFive";
// import { ConfigContext } from "../newIndex";
import {
  BalanceTableMay2022Mock,
  BalanceTableSept2021Mock,
  IncomeTableMay2022Mock,
  IncomeTableSept2021Mock,
} from "./table-balance.mock";
import {
  TBTableMay2022Mock,
  TBTableSept2021Mock,
  TBTableSept2020Mock,
} from "./table-common.mock";
import { useRouter } from "../../routes";
import { getDataSourceHook, getReportGenerators } from "../../api-hooks/report";
import { useDispatch } from "react-redux";
import {
  setFirstDataSourceId,
  setReportPeriod,
  setSecondDataSourceId,
  setSelectedReport,
} from "../../store/report";
import { sortReportSections } from "../../helper";

const CreateOrEditReport = ({
  boxHeight,
  duplicateId,
  setReportList,
  reportList,
}) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { query } = router;
  const queryStep = parseInt(query?.step);
  console.log("Query step", query?.step, query?.reportId);

  // const { state } = useContext(ConfigContext);
  const [currentStep, setCurrentStep] = useState(queryStep);
  const [selectedCurrentFile, setSelectedCurrentFile] = useState(null);
  const [selectedPreviousFile, setSelectedPreviousFile] = useState(null);
  const [balanceSheetData, setBalanceSheetData] = useState("");
  const [incomeSheetData, setIncomeSheetData] = useState("");
  const [isEnablePeriod, setIsEnablePeriod] = useState(false);
  const [selectedSheet, setSelectedSheet] = useState("TB");
  const [selectedFirstSheet, setSelectedFirstSheet] = useState();
  const [selectedSecondSheet, setSelectedSecondSheet] = useState();
  const [currentFileName, setCurrentFileName] = useState("");
  const [previousFileName, setPreviousFileName] = useState("");
  const [previewDataSource, setPreviewDataSource] = useState("");
  const [secondPreviewDataSource, setSecondPreviewDataSource] = useState("");
  const [allAccountsData, setAllAccountsData] = useState("");
  const [stepOneErrorMessage, setStepOneErrorMessage] = useState("");
  const [selectedColumns, setSelectedColumns] = useState({
    accountCol: {
      label: "Account name/code",
      id: "accountCol",
      col: -1,
    },
    debitCol: {
      label: "Debit",
      id: "debitCol",
      col: -1,
    },
    creditCol: {
      label: "Credit",
      id: "creditCol",
      col: -1,
    },
  });
  const [rowsToDisable, setRowsToDisable] = useState(4);
  const handleRowsChange = (newNum) => {
    dispatch(setFirstDataSourceId(""));

    dispatch(setSecondDataSourceId(""));

    setRowsToDisable(newNum);
  };

  useEffect(() => {
    const reportId = query?.reportId;
    getReportGenerators({ reportId }, (response) => {
      if (response) {
        console.log("Resport Generators", response);
        if (response?.length) {
          const selectedReportData = {
            sections: [],
          };
          response?.forEach((item) => {
            if (item?.type === "coverPage") {
              // setCoverPageData(item);
              selectedReportData.coverPage = item;
            } else if (item?.type === "pageHeader") {
              // setHeaderData(item);
              selectedReportData.pageHeader = item;
            } else if (item?.type === "pageFooter") {
              // setFooterData(item);
              selectedReportData.pageFooter = item;
            } else if (item?.type === "financialPositionEndDate") {
              selectedReportData.financialPositionEndDate = item;
            } else if (item?.type === "profitLossDate") {
              selectedReportData.profitLossDate = item;
            } else if (item?.type === "changesEquityDate") {
              selectedReportData.changesEquityDate = item;
            } else if (item?.type === "currency") {
              selectedReportData.currency = item;
            } else {
              selectedReportData.sections.push(item);
            }
          });

          const sortedSections = sortReportSections(
            selectedReportData.sections
          );

          selectedReportData.sections = sortedSections;

          console.log("Sorted Sections", sortedSections);
          dispatch(setSelectedReport(selectedReportData));
        }
      }
    });

    getDataSourceHook({ reportId }, (response) => {
      console.log("Data SOurce Data Data", response);

      let periodObj = {};
      if (response?.length) {
        response?.forEach((data) => {
          if (data.reportPeriod) {
            periodObj[data.reportPeriod] = data;
          }
        });
      }
      dispatch(setReportPeriod(periodObj));
    });
  }, [query?.reportId, currentStep]);

  return (
    <MainContainer currentStep={currentStep}>
      <div className="finacial-report-container ">
        <div className={"create-or-edit-report-container"}>
          <ReportSteps
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            setReportList={setReportList}
            duplicateId={duplicateId}
            reportList={reportList}
            setStepOneErrorMessage={setStepOneErrorMessage}
          />
          {currentStep === 1 && (
            <StepOne
              boxHeight={boxHeight}
              setBalanceSheetData={setBalanceSheetData}
              setIncomeSheetData={setIncomeSheetData}
              isEnablePeriod={isEnablePeriod}
              setIsEnablePeriod={setIsEnablePeriod}
              selectedSheet={selectedSheet}
              setSelectedSheet={setSelectedSheet}
              selectedFirstSheet={selectedFirstSheet}
              setSelectedFirstSheet={setSelectedFirstSheet}
              selectedSecondSheet={selectedSecondSheet}
              setSelectedSecondSheet={setSelectedSecondSheet}
              currentFileName={currentFileName}
              setCurrentFileName={setCurrentFileName}
              previousFileName={previousFileName}
              setPreviousFileName={setPreviousFileName}
              previewDataSource={previewDataSource}
              setPreviewDataSource={setPreviewDataSource}
              secondPreviewDataSource={secondPreviewDataSource}
              setSecondPreviewDataSource={setSecondPreviewDataSource}
              setSelectedCurrentFile={setSelectedCurrentFile}
              selectedCurrentFile={selectedCurrentFile}
              selectedPreviousFile={selectedPreviousFile}
              setSelectedPreviousFile={setSelectedPreviousFile}
              stepOneErrorMessage={stepOneErrorMessage}
              setStepOneErrorMessage={setStepOneErrorMessage}
              selectedColumns={selectedColumns}
              setSelectedColumns={setSelectedColumns}
              rowsToDisable={rowsToDisable}
              handleRowsChange={handleRowsChange}
            />
          )}
          {currentStep === 2 && (
            <StepTwo
              boxHeight={boxHeight}
              balanceSheetData={balanceSheetData}
              incomeSheetData={incomeSheetData}
              currentFileName={currentFileName}
              setBalanceSheetData={setBalanceSheetData}
              setIncomeSheetData={setIncomeSheetData}
              setAllAccountsData={setAllAccountsData}
              allAccountsData={allAccountsData}
              // selectedFirstSheet={selectedFirstSheet}
              // selectedSecondSheet={selectedSecondSheet}
            />
          )}
          {currentStep === 4 && <StepThree boxHeight={boxHeight} />}
          {currentStep === 3 && (
            <StepFour boxHeight={boxHeight} key={Math.random()} />
          )}
          {currentStep === 5 && <StepFive boxHeight={boxHeight} />}
        </div>
      </div>
    </MainContainer>
  );
};

export default CreateOrEditReport;
