import React from "react";
import { Upload, message } from "antd";
import { ReactComponent as ReportUploadIcon } from "../../assets/svg-icons/ReportUpload.svg";

const ComponentUpload = ({ reportId, fetchData }) => {
  const token = localStorage.getItem("authToken");

  const props = {
    name: "file",
    action: `${process.env.REACT_APP_API}/report-drive/upload`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      reportId,
    },
    accept: ".doc,.docx",
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        fetchData();
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <Upload {...props}>
      <ReportUploadIcon />
    </Upload>
  );
};

export default ComponentUpload;
