import ContentCard from "../../atoms/ContentCard";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import debounce from "lodash/debounce";
import LeftControl from "../../sub-components/LeftControl";
import RightControl from "../../sub-components/RightControl";
import Title from "../../atoms/Title";

// import RicText from "@/components/RicText/index";
import ComponentInput from "../../atoms/ComponentInput";

import "./CreateOrEditReport.less";
import Editor from "../../sub-components/Editor";
import {
  createReportGeneratorsHook,
  getReportGeneratorPreviewById,
  getReportGenerators,
  updateReportGeneratorsHook,
  getReportGeneratorEditById,
} from "../../api-hooks/report";
import { useRouter } from "../../routes";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedReport } from "../../store/report";
import PreviewPDF from "../../sub-components/PreviewPDF";
import { changePdfCollapsedState } from "../../store/user";
import { Tooltip } from "antd";
import { ReactComponent as PreviewPDFIcon } from "../../assets/svg-icons/previewIcon.svg";

const StepThree = ({ boxHeight }) => {
  const dispatch = useDispatch();
  const [coverPageValue, setCoverPageValue] = useState("");
  const [previewCoverPageValue, setPreviewCoverPageValue] = useState("");
  const [headerValue, setHeaderValue] = useState("");
  const [previewHeaderValue, setPreviewHeaderValue] = useState("");
  const [footerValue, setFooterValue] = useState("");
  const [previewFooterValue, setPreviewFooterValue] = useState("");
  const [refreshKey, setRefreshKey] = useState("");

  const router = useRouter();
  const { query } = router;

  const coverPageData = useSelector(
    (state) => state.report.selectedReport?.coverPage
  );

  const headerData = useSelector(
    (state) => state.report.selectedReport?.pageHeader
  );

  const footerData = useSelector(
    (state) => state.report.selectedReport?.pageFooter
  );

  const isCollapsed = useSelector((state) => state.user.isPdfCollapsed);

  const setIsCollapsed = (value) => {
    dispatch(changePdfCollapsedState(value));
  };

  useEffect(() => {
    getReportGenerators({ reportId: query?.reportId }, (response) => {
      if (response) {
        console.log("Resport Generators", response);
        if (response?.length) {
          const selectedReportData = {};
          response?.forEach((item) => {
            if (item?.type === "coverPage") {
              // getReportGeneratorEditById({ id: item?.id }, (response) => {
              //   if (response) {
              //     console.log("Cover Pafe Preview", response);
              //     setCoverPageValue(response?.html);
              //   }
              // });
              setCoverPageValue(item?.data?.html);
              // // setCoverPageData(item);
              selectedReportData.coverPage = item;
            } else if (item?.type === "pageHeader") {
              setHeaderValue(item?.data?.html);
              // setHeaderData(item);
              selectedReportData.pageHeader = item;
            } else if (item?.type === "pageFooter") {
              setFooterValue(item?.data?.html);
              // setFooterData(item);
              selectedReportData.pageFooter = item;
            }
          });
          dispatch(setSelectedReport(selectedReportData));
        }
        // setRefreshKey(Math.random());
        // getSrc();
      }
    });
  }, []);

  useEffect(() => {
    if (coverPageData?.id) {
      getReportGeneratorPreviewById({ id: coverPageData?.id }, (response) => {
        if (response) {
          console.log("Cover Pafe Preview", response);
          setPreviewCoverPageValue(response);
        }
      });
    }
  }, [coverPageData?.id]);

  useEffect(() => {
    if (headerData?.id) {
      getReportGeneratorPreviewById({ id: headerData?.id }, (response) => {
        if (response) {
          setPreviewHeaderValue(response);
        }
      });
    }
  }, [headerData?.id]);

  useEffect(() => {
    if (footerData?.id) {
      getReportGeneratorPreviewById({ id: footerData?.id }, (response) => {
        if (response) {
          setPreviewFooterValue(response);
        }
      });
    }
  }, [footerData?.id]);

  const debounceUpdate = useCallback(
    debounce(
      (type, data, dataId) =>
        updateReportGeneratorsHook(
          {
            reportId: query?.reportId,
            type: type,
            data: data,
            id: dataId,
          },
          (response) => {
            if (response) {
              console.log("Resport Generators", response);
              if (type === "coverPage") {
                getReportGeneratorPreviewById({ id: dataId }, (response) => {
                  if (response) {
                    console.log("Cover Pafe Preview", response);
                    setPreviewCoverPageValue(response);
                  }
                });
              } else if (type === "pageHeader") {
                getReportGeneratorPreviewById({ id: dataId }, (response) => {
                  if (response) {
                    setPreviewHeaderValue(response);
                  }
                });
              } else if (type === "pageFooter") {
                getReportGeneratorPreviewById({ id: dataId }, (response) => {
                  if (response) {
                    setPreviewFooterValue(response);
                  }
                });
              }

              setRefreshKey(Math.random());
            }
          }
        ),
      200
    ),
    [] // will be created only once initially
  );

  const onSave = (type, data) => {
    let isUpdate = false;
    let selectedData;
    if (type === "coverPage") {
      if (coverPageData?.id) {
        isUpdate = true;
        selectedData = coverPageData;
      }
    } else if (type === "pageHeader") {
      if (headerData?.id) {
        isUpdate = true;
        selectedData = headerData;
      }
    } else if (type === "pageFooter") {
      if (footerData?.id) {
        isUpdate = true;
        selectedData = footerData;
      }
    }
    console.log("footerData", footerData, headerData, coverPageData);
    debounceUpdate(type, data, selectedData?.id);
  };

  console.log("previewCoverPageValue", coverPageValue);

  return (
    <div style={{ display: "flex", flex: "1 1 auto" }}>
      <LeftControl
        boxHeight={boxHeight}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
      >
        <ContentCard
          style={{ padding: 32, minHeight: "unset" }}
          bodyStyle={{ padding: 0, display: "block" }}
          size="large"
        >
          <div>
            <Title
              title="Cover Page Settings"
              titleStyle={{
                fontSize: "18px",
                fontWeight: "800",
                lineHeight: "24px",
              }}
            />
            <div style={{ marginTop: "32px" }}>
              {coverPageData?.id && (
                <Editor
                  data={coverPageValue}
                  setData={setCoverPageValue}
                  type="coverPage"
                  onSave={onSave}
                  isSaveButtonEnabled
                  previewData={previewCoverPageValue?.html}
                />
              )}
            </div>
          </div>
        </ContentCard>
        <ContentCard
          style={{
            padding: 32,
            marginTop: 20,
            minHeight: "unset",
            display: "block",
          }}
          bodyStyle={{ padding: 0, display: "block" }}
          size="large"
        >
          <Title
            title="Page Header"
            titleStyle={{
              fontSize: "18px",
              fontWeight: "800",
              lineHeight: "24px",
            }}
          />
          <div style={{ marginTop: "26px" }}>
            {headerData?.id && (
              <Editor
                type="pageHeader"
                data={headerValue}
                setData={setHeaderValue}
                onSave={onSave}
                previewData={previewHeaderValue?.html}
                isSaveButtonEnabled
              />
            )}
          </div>
        </ContentCard>

        <ContentCard
          style={{
            padding: 32,
            marginTop: 20,
            minHeight: "unset",
            display: "block",
          }}
          bodyStyle={{ padding: 0, display: "block" }}
          size="large"
        >
          <Title
            title="Page Footer"
            titleStyle={{
              fontSize: "18px",
              fontWeight: "800",
              lineHeight: "24px",
            }}
          />
          <div style={{ marginTop: "26px" }}>
            {footerData?.id && (
              <Editor
                type="pageFooter"
                data={footerValue}
                setData={setFooterValue}
                onSave={onSave}
                previewData={previewFooterValue?.html}
                isSaveButtonEnabled
              />
            )}
          </div>
        </ContentCard>
      </LeftControl>
      <RightControl
        boxHeight={boxHeight}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
      >
        {" "}
        <ContentCard
          style={{ padding: "0px 32px 32px", height: "100%" }}
          bodyStyle={{ padding: 0, height: "100%" }}
          size="large"
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              height: "inherit",
            }}
          >
            <div className={"report-preview-heading"}>
              <div style={{ flex: "1 1 auto" }}>Preview</div>
              <Tooltip placement="left" title="Collpase Preview">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setIsCollapsed(!isCollapsed);
                  }}
                >
                  <PreviewPDFIcon style={{ width: "16px", height: "16px" }} />
                </div>
              </Tooltip>
            </div>

            <PreviewPDF refreshKey={refreshKey} type={"coverPage"} />

            {/* <div style={{ height: "inherit" }}>
              <iframe width="100%" height="1000px" title="new.pdf" />
            </div> */}
          </div>
        </ContentCard>
      </RightControl>
    </div>
  );
};

export default StepThree;
