import React from "react";
import { Switch } from "antd";

const ComponentSwitch = ({ onChange, checked, name, style }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", ...style }}>
      <Switch checked={checked} onChange={onChange} />
      <div style={{ marginLeft: "14px" }}>{name}</div>
    </div>
  );
};

export default ComponentSwitch;
