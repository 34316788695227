import ComponentModal from "../../atoms/ComponentModal";
import ComponentInput from "../../atoms/ComponentInput";
import { useEffect, useState } from "react";
import { updateReportDrive } from "../../api-hooks/report";
import { message } from "antd";

const EditReportModal = ({
  open,
  handleClose,
  selectedItem,
  fetchData,
  setSelectedReportItem,
}) => {
  const [reportName, setReportName] = useState("");
  const [selectedFileExt, setSelectedFileExt] = useState("");

  useEffect(() => {
    if (selectedItem && selectedItem?.name) {
      const split = selectedItem?.name.split(".");
      setSelectedFileExt(split[split?.length - 1]);
      delete split[split?.length - 1];
      let str = "";
      split.map((item) => {
        str += item;
      });
      setReportName(str);
    }
  }, [selectedItem]);

  const onUpdateReportDriveName = () => {
    updateReportDrive(
      { id: selectedItem?.id, name: `${reportName}.${selectedFileExt}` },
      (response) => {
        if (response) {
          handleClose();
          setSelectedReportItem({ ...selectedItem, name: response?.name });
          fetchData();
          message.success("Report drive name successfully updated!");
        }
      }
    );
  };

  return (
    <ComponentModal
      open={open}
      handleClose={handleClose}
      backText="Cancel"
      title="Edit Name"
      width={516}
      submitText="Save"
      handleOk={onUpdateReportDriveName}
      footer={true}
    >
      <div>
        <ComponentInput
          placeholder="Report Name"
          value={reportName}
          onChange={(value) => {
            setReportName(value);
          }}
        />
      </div>
    </ComponentModal>
  );
};

export default EditReportModal;
