import "./LeftControl.less";
const LeftControl = ({
  children,
  boxHeight,
  isCollapsed,
  setIsCollapsed,
  rightCollapsed,
}) => {
  return (
    <>
      {!isCollapsed && (
        <div
          id="left-1642-0224"
          className={rightCollapsed ? "left-part-collapsed" : "left-part"}
        >
          {children}
        </div>
      )}
      {isCollapsed && (
        <div id="left-1642-0224" className={"left-part-expanded"}>
          {children}
        </div>
      )}
    </>
  );
};

export default LeftControl;
