import React from "react";
import ComponentButton from "../../atoms/ComponentButton";
import MappingLoading from "../../sub-components/MappingLoading";
import { useAllAccount } from "./allAccountHook";
import "./balance.less";
// import newTemplateIcon from "../images/duplicate.png";
import { useIncome } from "./incomeHook";
import TableBalance from "./table-balance";

const fileAttributes = {
  fileType: "Income Sheet",
};

const AllAccountSheet = ({
  selectedStatement,
  balanceSheetData,
  incomeSheetData,
  checkedData,
  setIsBalanceMapped,
  setIsIncomeMapped,
  isLoading,
  setIsSheetPreviewEnabled,
  templateName,
  templateId,
  isBalaceMapped,
  isIncomeMapped,
  onCreateNewTemplate,
  accountListObj,
  removeMapping,
}) => {
  const { handleRowCheck, tableRowsData, tableHeaders, handleFSItemChange } =
    useAllAccount(selectedStatement, incomeSheetData, checkedData);

  console.log("Table Row Data", tableRowsData, tableHeaders);

  return (
    <section className={"right-comp2"}>
      <header className={"section-header"}>
        <h2>Map Items</h2>
      </header>
      <main className={"section-body"}>
        <div className={"file-attributes"}>
          <div className={"each-attribute"} style={{ fontSize: "10px" }}>
            `Statement: All Accounts
          </div>
          <div className={"each-attribute"} style={{ fontSize: "10px" }}>
            <span>Mapping template applied: {templateName || "NA"}</span>
            {templateId && (
              <span
                style={{
                  marginLeft: "5px",
                  textDecoration: "underline",
                  color: "#4BB354",
                  cursor: "pointer",
                }}
                onClick={removeMapping}
              >
                Remove
              </span>
            )}
          </div>
        </div>
        {isLoading && <MappingLoading />}
        {!isLoading && (
          <TableBalance
            tableHeaders={tableHeaders}
            tableRowsData={tableRowsData}
            handleRowCheck={handleRowCheck}
            handleFSItemChange={handleFSItemChange}
            type="all_account"
            setIsBalanceMapped={setIsBalanceMapped}
            setIsIncomeMapped={setIsIncomeMapped}
            accountListObj={accountListObj}
            // hideCheckbox
            isShowAllAccount
          />
        )}
      </main>
    </section>
  );
};

export default AllAccountSheet;
