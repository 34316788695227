import axios from "axios";
import api from "../api-intercepter";

export const loginHook = (values, callback) => {
  const data = JSON.stringify({
    email: values.email,
    password: values.password,
  });

  api
    .post("/auth/login/", data)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};

export const signupHook = (values, callback) => {
  const data = JSON.stringify({
    email: values?.email,
    firstName: values.firstName,
    lastName: values?.lastName,
    password: values.password,
  });

  api
    .post("/auth/register", data)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};

export const getUserInfoHook = (callback) => {
  const authToken = localStorage.getItem("authToken");
  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API}/auth/me`,
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };
  axios(config)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};
