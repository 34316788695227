import { ArrowLeftOutlined, LeftOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { useEffect, useState } from "react";
import { getReportAccountSummaryHook } from "../../api-hooks/account";

import { getReportByIdHook } from "../../api-hooks/report";
import { useRouter } from "../../routes";
import MakeAdjustmentModal from "../../sub-components/MakeAdjustmentModal";
import PreviewReportSummaryHoc from "../../sub-components/PreviewReportSummaryHoc";
import { useSelector } from "react-redux";

const PreviewSheet = ({ title, type, setIsSheetPreviewEnabled }) => {
  const router = useRouter();
  const { query } = router;

  const [previewData, setPreviewData] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isMakeAdjustmentDialogOpened, setIsMakeAdjustmentDialogOpened] =
    useState(false);
  const [currentYear, setCurrentYear] = useState("2022");
  const [previousYear, setPreviousYear] = useState("2021");

  const reportPeriodObj = useSelector((state) => state.report.reportPeriod);

  useEffect(() => {
    getReportAccountSummaryHook(
      {
        reportId: query?.reportId,
      },
      (response) => {
        console.log("Report Account Summary", response);
        // const data = {};
        Object.keys(response).map((key) => {
          const itemList = response[key];
          if (itemList?.[0]?.type === type) {
            previewData[key] = itemList;
            // setPreviewData(itemList);
          }
        });
        setPreviewData({ ...previewData });
      }
    );
  }, [type, isMakeAdjustmentDialogOpened]);

  useEffect(() => {
    getReportByIdHook(query?.reportId, (response) => {
      console.log("Report By id", response);
      if (response?.fiscalYear?.name) {
        const yearList = response?.fiscalYear?.name?.split("/");
        setCurrentYear(yearList[1]);

        setPreviousYear(yearList[0]);
      }
    });
  }, [query?.reportId]);

  const openMakeAdjustmentDialog = (category) => {
    setSelectedCategory(category);
    setIsMakeAdjustmentDialogOpened(true);
  };
  console.log("typeinfo", type);
  const closeMakeAdjustmentDialog = () => {
    setSelectedCategory("");
    setIsMakeAdjustmentDialogOpened(false);
  };
  return (
    <section className={"right-comp2"}>
      <header className={"section-header"}>
        {!(type === "equation_item") && (
          <div
            className="preview-sheet-back-container"
            onClick={() => {
              setIsSheetPreviewEnabled(false);
            }}
          >
            <LeftOutlined />
            <span style={{ marginLeft: "11.5px" }}>Back to Mapping</span>
          </div>
        )}
        <div className={"header-right"}>
          <div className="preview-sheet-title-container">{title}</div>
        </div>
      </header>
      <main className={"section-body"}>
        <div className="preview-sheet-data-table-header">
          <div className="preview-sheet-header-title"></div>
          <div className="preview-sheet-data-period preview-sheet-header-period">
            {currentYear}
          </div>
          {reportPeriodObj["previous"] && (
            <div className="preview-sheet-data-period preview-sheet-header-period">
              {previousYear}
            </div>
          )}
        </div>
        {Object.keys(previewData).map((key, index) => {
          const itemList = previewData[key];
          return (
            <>
              <div className="preview-sheet-data-item-container">
                <div className="preview-sheet-data-header">{key}</div>
              </div>
              {itemList.map((item, index) => {
                return (
                  <div className="preview-sheet-data-item-container">
                    <div className="preview-sheet-data-title">{item?.item}</div>
                    <div className="preview-sheet-data-period">
                      <Tooltip
                        title={
                          <div
                            onClick={() => {
                              openMakeAdjustmentDialog(item?.item);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            Make Adjustment
                          </div>
                        }
                      >
                        <span> {item?.currentPeriod}</span>
                      </Tooltip>
                    </div>
                    {reportPeriodObj["previous"] && (
                      <div className="preview-sheet-data-period">
                        {/* <Tooltip
                        title={
                          <div
                            onClick={() => {
                              openMakeAdjustmentDialog(item?.item);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            Make Adjustment
                          </div>
                        }       
                      > */}
                        <span> {item?.previousPeriod}</span>
                        {/* </Tooltip> */}
                      </div>
                    )}
                  </div>
                );
              })}
            </>
          );
        })}
      </main>
      {isMakeAdjustmentDialogOpened && (
        <MakeAdjustmentModal
          open={isMakeAdjustmentDialogOpened}
          handleClose={closeMakeAdjustmentDialog}
          type={type}
          categoryName={selectedCategory}
        />
      )}
    </section>
  );
};

export default PreviewSheet;
