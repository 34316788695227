import React, { useState } from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import NoteExpandableItems from "./NoteExpandableItems";
import { Position } from "devextreme-react/autocomplete";
const NoteExpandable = ({
  item,
  index,
  setViewSubSectionTitle,
  viewNoteItemTemplate,
  showNoteTemplateSubId,
  noteTemplateData,
  setNoteTemplateData,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="expanded-item" style={{ width: "100%" }}>
      <div
        className={"expandable-icon"}
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
        style={{
          cursor: "pointer",
          width: "max-content",
          marginLeft: "auto",
          position: "absolute",
          top: "0px",
          right: "0px",
        }}
      >
        {/* <div className={"saved-expandable-group-title"}>{item.name}</div> */}
        {!isExpanded && <UpOutlined style={{ width: "10px", height: "6px" }} />}
        {isExpanded && (
          <DownOutlined style={{ width: "10px", height: "6px" }} />
        )}
      </div>
      {!isExpanded && (
        <NoteExpandableItems
          item={item}
          index={index}
          noteTemplateData={noteTemplateData}
          setNoteTemplateData={setNoteTemplateData}
          setViewSubSectionTitle={setViewSubSectionTitle}
          viewNoteItemTemplate={viewNoteItemTemplate}
          showNoteTemplateSubId={showNoteTemplateSubId}
        />
      )}
    </div>
  );
};

export default NoteExpandable;
