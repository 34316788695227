import ComponentModal from "../../atoms/ComponentModal";

import "./SaveDiscard.less";

const SaveDiscardModal = ({ open, onSave, handleClose }) => {
  return (
    <ComponentModal
      open={open}
      handleClose={handleClose}
      backText="Discard"
      submitText="Save"
      handleOk={onSave}
    >
      <div className={"saved-discard-report-text-title"}>
        Please save, otherwise changes will be discarded
      </div>
    </ComponentModal>
  );
};

export default SaveDiscardModal;
