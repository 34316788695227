// import less
import "./ComponentSubTitle.less";

const ComponentSubTitle = ({ title, style }) => {
  return (
    <div className="component-sub-title-container" style={style}>
      {title}
    </div>
  );
};

export default ComponentSubTitle;
