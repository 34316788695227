import React from "react";

// import styles from "./PopupItem.less";

import "./PopupItem.less";
const PopupItem = ({
  Icon,
  name,
  isDelete,
  onClick,
  isDisabled,
  isSelected,
}) => {
  const getClass = () => {
    if (isDelete) {
      return "popup-delete-item-container";
    }
    if (isDisabled) {
      return "popup-disabled-item-container";
    }
    if (isSelected) {
      return "popup-item-selected-container";
    }
    return "popup-item-container";
  };
  return (
    <div onClick={!isDisabled ? onClick : () => {}} className={getClass()}>
      {Icon && <Icon />}

      <div style={{ marginLeft: Icon ? "16px" : "" }}>{name}</div>
    </div>
  );
};

export default PopupItem;
