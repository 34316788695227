import { useEffect, useMemo, useState } from "react";
import { tableBalanceMock, tableIncomeMock } from "./table-balance.mock";

export const useAllAccount = (
  selectedStatement,
  incomeSheetData,
  checkedData
) => {
  console.log("selectedStatement", selectedStatement);
  const [checkedRows, setCheckedRows] = useState(checkedData);
  let [mockData, setMockData] = useState(incomeSheetData);

  const handleRowCheck = (rowIndex) => {
    if (checkedRows[rowIndex]) {
      setCheckedRows({ ...checkedRows, [rowIndex]: false });
    } else {
      setCheckedRows({ ...checkedRows, [rowIndex]: true });
    }
  };

  const handleFSItemChange = (rowIndex, colIndex, value) => {
    mockData.dataItems[rowIndex].colItems[colIndex] = value;
    console.log("Col Index", colIndex);
    if (colIndex === 2) {
      mockData.dataItems[rowIndex].colItems[3] = null;
    }
    setMockData({ ...mockData });
  };

  // let mockTableData = incomeSheetData;
  //   if (selectedStatement === "Income Statement") {
  //     mockTableData = incomeSheetData;
  //   }

  const tableRowsData = useMemo(
    () =>
      mockData?.dataItems?.map((eachRowData, rowInx) => {
        const rowData = { ...eachRowData };

        // rowData.colItems[0].isChecked = true
        return rowData;
      }),
    [checkedRows, selectedStatement, mockData]
  );

  //   useEffect(() => {
  //     setCheckedRows(checkedData);
  //   }, [selectedStatement, checkedData]);

  return {
    handleRowCheck,
    tableHeaders: mockData.headers,
    tableRowsData,
    handleFSItemChange,
  };
};
