import { LoadingOutlined } from "@ant-design/icons";
// import less
import "./MappingLoading.less";

const MappingLoading = () => {
  return (
    <div className="mapping-loading-container">
      <LoadingOutlined style={{ fontSize: "14px" }} />
      <div style={{ marginTop: "12px" }}>Mapping.. please wait</div>
    </div>
  );
};

export default MappingLoading;
