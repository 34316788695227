import React from "react";
import { Input } from "antd";

const { TextArea } = Input;

const ComponentTextArea = ({ value, onChange, style, ...props }) => {
  return (
    <TextArea
      value={value}
      style={{ border: "1px solid #D8DAE5", borderRadius: "4px", ...style }}
      {...props}
      onChange={(e) => {
        let _val = e.target.value;
        if (_val !== value && onChange) {
          onChange(_val);
        }
      }}
    />
  );
};

export default ComponentTextArea;
