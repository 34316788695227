import ComponentModal from "../../atoms/ComponentModal";
import ComponentInput from "../../atoms/ComponentInput";
import { ReactComponent as TrashIcon } from "../../assets/svg-icons/trashIcon.svg";

const AddVersionModal = ({ open, handleClose,setSelectedVersion,selectedVersion,handleOk,selectedVersionList,setDeleteAddVersionModal}) => {
  console.log("selectedVersionList delete",selectedVersionList)
  return (
    <ComponentModal
      open={open}
      handleClose={handleClose}
      backText="Cancel"
      title={ selectedVersion?.id ? 'Edit Version' :'Create New Version'}
      width={592}
      submitText={selectedVersion?.id ? 'Submit' : 'Create' }
      footer={true}
      handleOk ={ handleOk }
    >
      <ComponentInput
          value={selectedVersion?.name}
          style={{ width: "100%" }}
          placeholder="Enter"
          onChange={(value) => {
            console.log("asdascfd", value);
            selectedVersion.name = value;
            setSelectedVersion({ ...selectedVersion });
          }}
        />
        {selectedVersion?.id && (
          <>
          {(selectedVersionList.length > 1) && (
            <div onClick={()=>{setDeleteAddVersionModal(true)}} className="trash-icon-pos" style={{display:'flex',alignItems:'center',gap:'5px',position:'absolute',bottom:'15px',color:"#D14343",cursor:'pointer',left:'24px'}}><TrashIcon/>Delete</div>
          )}
          </>
        )}
    </ComponentModal>
    
  );
};

export default AddVersionModal;
