import { PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { fetchComapnyWithReportsHook } from "../../api-hooks/company";
import { createFinancialYearHook, fetchYearsHook } from "../../api-hooks/year";
import ComponentButton from "../../atoms/ComponentButton";
import ComponentDropdown from "../../atoms/ComponentDropdown";
import ComponentDropdownPopup from "../../atoms/ComponentDropdownPopup";
import ComponentSearch from "../../atoms/ComponentSearch";
import ContentCard from "../../atoms/ContentCard";

import { CREATE_FINANCIAL_REPORT_URL, useRouter } from "../../routes";
import { setCompaniesWithReports } from "../../store/company";
import { setYears } from "../../store/year";
import LeftControl from "../../sub-components/LeftControl";
import MainContainer from "../../sub-components/MainContainer";
import PreviousReportModal from "../../sub-components/PreviousReportModal";
import RightControl from "../../sub-components/RightControl";
import SaveReportModal from "../../sub-components/SaveReportModal";
import ExpandableGroup from "./ExpandableGroup";

import PreviewPDF from "../../sub-components/PreviewPDF";
import "./FinancialReport.less";
import { duplicateReportHook } from "../../api-hooks/report";
import { ReactComponent as PreviewPDFIcon } from "../../assets/svg-icons/previewIcon.svg";
import { Tooltip } from "antd";
import { changePdfCollapsedState } from "../../store/user";
import { updateFinancialYearHook } from "../../api-hooks/year";

const FinancialReport = ({}) => {
  const [selectedReport, setSelectedReport] = useState("");
  const dispatch = useDispatch();
  const router = useRouter();
  const navigate = useNavigate();
  const [refreshKey, setRefreshKey] = useState("");
  const [isPreviousDialogOpened, setIsPreviousDialogOpened] = useState(false);
  const [isSaveReportModalOpened, setIsSaveReportModalOpened] = useState(false);
  // const [isCollapsed, setIsCollapsed] = useState(false);

  const isCollapsed = useSelector((state) => state.user.isPdfCollapsed);
  const [selectedYear, setSelectedYear] = useState(null);
  const [showReportEdit, setShowReportEdit] = useState(true);

  const years = useSelector((state) => state.year.years);

  const companiesWithReports = useSelector(
    (state) => state.company.companiesReports
  );

  const reports = useSelector((state) => state.report.reports);

  const onDuplicate = (reportId, name) => {
    console.log("Duplicate Report Id", reportId);
    duplicateReportHook({ reportId, name }, (response) => {
      console.log("Duplicate Report Response", response);
      navigate(`/financial-report/create?reportId=${response?.id}&step=1`);
    });

    // router.push(`/templateConfig?create=true&duplicateId=${reportName}`, {
    //   shallow: true,
    // });
  };

  const onAddFinancialYear = (financialYear) => {
    if (financialYear && financialYear.trim() !== "") {
      createFinancialYearHook(financialYear, (response) => {
        const newYearList = [...years];
        newYearList.push(response);
        dispatch(setYears(newYearList));
      });
    }
  };

  useEffect(() => {
    fetchComapnyWithReportsHook((response) => {
      if (response) {
        dispatch(setCompaniesWithReports(response));
      }
    });
  }, []);

  useEffect(() => {
    if (years?.length && !selectedYear) {
      setSelectedYear(years[0]);
    }
  }, [years?.length]);

  const setIsCollapsed = (value) => {
    dispatch(changePdfCollapsedState(value));
  };

  const updateFiscalyear = (id, value, callback) => {
    // call patch function
    updateFinancialYearHook(
      {
        name: value,
        id: id,
      },
      (response) => {
        console.log("Equation Updated created", response);
        fetchYearsHook((allYearResponse) => {
          dispatch(setYears([...allYearResponse]));

          console.log("Response Year", response);
          if (selectedYear?.id === response?.id) {
            setSelectedYear(response);
          }
          callback();
        });
      }
    );
  };

  console.log("Years", years);

  return (
    <MainContainer>
      <div className="finacial-report-container financial-height-setter">
        <LeftControl
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
          className="financial-design"
        >
          <ContentCard
            style={{ padding: 32, height: "100%" }}
            bodyStyle={{ padding: 0 }}
            size="large"
          >
            <div className={"saved-cards-heading"}>Saved Reports</div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              {/* <Link to={CREATE_FINANCIAL_REPORT_URL}> */}
              <ComponentButton
                title="New Reports"
                icon={<PlusOutlined />}
                onClick={() => {
                  setIsSaveReportModalOpened(true);
                }}
                // onClick={() => {
                //   router.push("/templateConfig?create=true", { shallow: true });
                // }}
              />
              {/* </Link> */}

              <ComponentButton
                title="Use Previous Reports"
                style={{ marginLeft: "24px" }}
                onClick={() => {
                  setIsPreviousDialogOpened(true);
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "29px",
              }}
            >
              <ComponentDropdownPopup
                title="Select financial year"
                onSelect={(item) => {
                  setSelectedYear(item);
                }}
                selectedItem={selectedYear}
                list={years}
                addText="Add as new financial year"
                onAdd={onAddFinancialYear}
                showReportEdit={showReportEdit}
                updateData={updateFiscalyear}
              />

              <ComponentSearch
                placeholder="Search company or report name"
                style={{ width: "100%", marginLeft: "15px" }}
              />
            </div>

            <div
              style={{
                marginTop: "45px",
                overflow: "auto",
                height: "calc(100vh - 325px)",
              }}
            >
              {companiesWithReports?.map((item, index) => {
                const onSelect = (reportId, name) => {
                  setSelectedReport(name);
                  setRefreshKey(Math.random());
                  navigate(`${router?.pathname}?reportId=${reportId}`);
                };
                return (
                  <ExpandableGroup
                    item={item}
                    key={index}
                    onSelect={onSelect}
                  />
                );
              })}
            </div>
          </ContentCard>
          {isPreviousDialogOpened && (
            <PreviousReportModal
              open={isPreviousDialogOpened}
              onDuplicate={onDuplicate}
              reportList={reports}
              handleClose={() => {
                setIsPreviousDialogOpened(false);
              }}
            />
          )}
          {isSaveReportModalOpened && (
            <SaveReportModal
              open={isSaveReportModalOpened}
              handleClose={() => {
                setIsSaveReportModalOpened(false);
              }}
              showReportEdit={showReportEdit}
              // duplicateId={duplicateId}
              // setReportList={setReportList}
              // reportList={reportList}
            />
          )}

          {/* <Card bordered={false} size="small" style={{ padding: 12, height: }}></Card> */}
        </LeftControl>
        <RightControl
          isPreview
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
        >
          <ContentCard
            style={{ padding: "0px 32px 32px", height: "100%" }}
            bodyStyle={{
              padding: 0,
              height: "inherit",
              display: "flex",
              flexDirection: "column",
            }}
            size="large"
          >
            {selectedReport && (
              <div className={"report-preview-heading"}>
                <div style={{ flex: "1 1 auto" }}>{selectedReport}</div>
                <Tooltip placement="left" title="Collpase Preview">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setIsCollapsed(!isCollapsed);
                    }}
                  >
                    <PreviewPDFIcon style={{ width: "16px", height: "16px" }} />
                  </div>
                </Tooltip>
              </div>
            )}
            {selectedReport && (
              <div style={{ height: "inherit" }}>
                <PreviewPDF refreshKey={refreshKey} />
              </div>
            )}
            {!selectedReport && (
              <div
                style={{
                  height: "inherit",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Select a report to preview
              </div>
            )}
          </ContentCard>
        </RightControl>
      </div>
    </MainContainer>
  );
};

export default FinancialReport;
