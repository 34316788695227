import React, { useEffect, useState } from "react";
import { Checkbox } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ComponentDropdown from "../../atoms/ComponentDropdown";
import ComponentModal from "../../atoms/ComponentModal";
import ComponentSearch from "../../atoms/ComponentSearch";
import ComponentDropdownPopup from "../../atoms/ComponentDropdownPopup";
import "./PreviousReportModal.less";
import DuplicateReportModal from "../DuplicateReportModal";
import year, { setYears } from "../../store/year";
import { createFinancialYearHook, fetchYearsHook } from "../../api-hooks/year";
import { updateFinancialYearHook } from "../../api-hooks/year";
import { Tooltip } from "antd";

const PreviousReportModal = ({
  open,
  handleClose,
  onDuplicate,
  reportList,
}) => {
  // const reportList = getReportListHook();
  const [selectedReport, setSelectedReport] = useState(true);
  const [selectedYear, setSelectedYear] = useState(null);
  const years = useSelector((state) => state.year.years);
  const [name, setName] = useState("");
  const dispatch = useDispatch();
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [filterReport, setFilterReport] = useState(reportList);
  const [showReportEdit, setShowReportEdit] = useState(true);
  const [searchValue, setSearchValue] = React.useState("");

  const [isReportNameDialogOpened, setIsReportNameDialogOpened] =
    useState(false);
  const onAddFinancialYear = (financialYear) => {
    createFinancialYearHook(financialYear, (response) => {
      const newYearList = [...years];
      newYearList.push(response);
      dispatch(setYears(newYearList));
    });
  };
  const closeReportNameDialog = () => {
    setIsReportNameDialogOpened(false);
  };

  const updateFiscalyear = (id, value, callback) => {
    // call patch function
    updateFinancialYearHook(
      {
        name: value,
        id: id,
      },
      (response) => {
        console.log("Equation Updated created", response);
        fetchYearsHook((allYearResponse) => {
          dispatch(setYears([...allYearResponse]));

          console.log("Response Year", response);
          if (selectedYear?.id === response?.id) {
            setSelectedYear(response);
          }
          callback();
        });
      }
    );
  };
  const openReportNameDialog = () => {
    setIsReportNameDialogOpened(true);
  };

  useEffect(() => {
    let reportName;

    if (years?.length && !selectedYear) {
      setSelectedYear(years[0]);
      if (reportName) {
        reportName = `${reportName} ${years[0]?.name}`;
      }
    }
    if (reportName) {
      setName(reportName);
    }
  }, [years?.length]);
  useEffect(() => {
    if (selectedYear) {
      let reportFinalList = [];
      reportList.map((item, itemId) => {
        if (selectedYear.id === item.fiscalYear.id) {
          reportFinalList.push(item);
          console.log("final filtered data", reportFinalList);
        }
        console.log("report final list complete", reportFinalList);
      });
      setFilterReport(reportFinalList);
    }
  }, [selectedYear?.id]);

  useEffect(() => {
    if (searchValue) {
      let reportFinalList = [];
      reportList.map((item, itemId) => {
        if (
          item?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()) ||
          item?.company?.name
            ?.toLowerCase()
            ?.includes(searchValue?.toLowerCase())
        ) {
          reportFinalList.push(item);
          console.log("final filtered data", reportFinalList);
        }
        console.log("report final list complete", reportFinalList);
      });
      setFilterReport(reportFinalList);
    }
  }, [searchValue]);

  return (
    <>
      <ComponentModal
        open={open}
        handleClose={handleClose}
        backText="Back"
        submitText="Duplicate"
        handleOk={() => {
          openReportNameDialog();
          // onDuplicate(selectedReport);
          // handleClose();
        }}
        title="Select report to duplicate"
      >
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <ComponentDropdownPopup
            style={{ width: "134px", minWidth: "134px" }}
            title="Select financial year"
            onSelect={(item) => {
              setSelectedYear(item);
              // updateReportName(null, item);
            }}
            selectedItem={selectedYear}
            updateData={updateFiscalyear}
            list={years}
            addText="Add as new financial year"
            onAdd={onAddFinancialYear}
            showReportEdit={showReportEdit}
          />
          <ComponentSearch
            placeholder="Search report"
            style={{ width: "100%" }}
            onChange={(e) => {
              console.log("On Change", e.target.value);
              setSearchValue(e.target.value);
            }}
          />
        </div>
        <div
          style={{ marginTop: "32px", maxHeight: "200px", overflowY: "auto" }}
        >
          {filterReport.map((item) => {
            return (
              <>
                <div className={"saved-previous-report-item-conatiner"}>
                  <Checkbox
                    onChange={(value) => {
                      setSelectedReport(item);
                    }}
                  >
                    <Tooltip
                      placement="topLeft"
                      title={
                        <div>
                          {item?.name} - {item.company.name}
                        </div>
                      }
                    >
                      <div className="ellipsis-text">
                        {item?.name} - {item.company.name}
                      </div>
                    </Tooltip>
                  </Checkbox>
                </div>
              </>
            );
          })}
        </div>
      </ComponentModal>
      {isReportNameDialogOpened && (
        <DuplicateReportModal
          open={isReportNameDialogOpened}
          handleClose={closeReportNameDialog}
          duplicateReportId={selectedReport?.id}
          duplicateName={`Copy of ${selectedReport?.name}`}
          onDuplicate={onDuplicate}
          closePreviousModal={handleClose}
        />
      )}
    </>
  );
};

export default PreviousReportModal;
