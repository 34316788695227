import { useMemo, useState } from "react";
import { tableCommonMock, TBTableCommonMock } from "./table-common.mock";

// const tbFile = "/Preview.xlsx";

export const useCamCam = (
  selectedSheet,
  previewDataSource,
  selectedFirstSheet,
  selectedSecondSheet,
  secondPreviewDataSource,
  rowsToDisable
) => {
  console.log("Type", selectedSheet);
  // const [rowsToDisable, setRowsToDisable] = useState(4);
  //   const handleRowsChange = (newNum) => {
  //     setRowsToDisable(newNum);
  //   };
  const [selectedColumns, setSelectedColumns] = useState({});

  const availableRecords = 100;
  const totalRecords = 320;
  // const mockTableData =
  //   selectedSheet === "TB" ? TBTableCommonMock : tableCommonMock;
  const mockTableData = selectedFirstSheet
    ? previewDataSource
    : secondPreviewDataSource;

  console.log("mockTableData", mockTableData);

  const [tableRowsData, totalColumns] = useMemo(() => {
    let totCols = 0;
    const tableRowsDataUpdated = mockTableData.map((eachRowData, rowInx) => {
      const rowData = { ...eachRowData };
      if (rowInx < rowsToDisable) {
        rowData.isDisabled = true;
      }
      // if (mockTableData.metadata.isSerialNum) {
      rowData.colItems = [{ label: `${rowInx + 1}` }, ...rowData.colItems];
      totCols = Math.max(totCols, rowData.colItems.length);
      // }
      return rowData;
    });
    return [tableRowsDataUpdated, totCols];
  }, [
    rowsToDisable,
    selectedSheet,
    selectedFirstSheet,
    selectedSecondSheet,
    mockTableData,
  ]);

  console.log("tableRowsData", totalColumns);

  return {
    rowsToDisable,
    // handleRowsChange,
    availableRecords: tableRowsData?.length,
    totalRecords: tableRowsData?.length,
    tableHeaders: mockTableData.headers,
    tableRowsData,
    tableMetadata: mockTableData.metadata,
    totalColumns,
    selectedColumns,
    setSelectedColumns,
  };
};
