// import less
import ComponentDatePicker from "../../atoms/ComponentDatePicker";
import "./StartEndDatePicker.less";
const StartEndDatePicker = ({
  startDate,
  endDate,
  onChangeStartDate,
  onChangeEndDate,
}) => {
  return (
    <div className="start-end-date-picker-container">
      <div style={{ flex: "1 1", marginRight: "7px" }}>
        <div className="start-end-title">Start Date</div>
        <ComponentDatePicker
          value={startDate}
          placeholder="DD/MM/YYYY"
          onChange={onChangeStartDate}
        />
      </div>
      <div style={{ flex: "1 1", marginLeft: "7px" }}>
        <div className="start-end-title">End Date</div>
        <ComponentDatePicker
          value={endDate}
          placeholder="DD/MM/YYYY"
          onChange={onChangeEndDate}
        />
      </div>
    </div>
  );
};

export default StartEndDatePicker;
