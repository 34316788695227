import { DownOutlined } from "@ant-design/icons";
import { Alert, Checkbox } from "antd";
import React, { useState } from "react";
import { removeMappingHook } from "../../api-hooks/account";
import { getAccountByType } from "../../api-hooks/report";
import { useRouter } from "../../routes";
// import ComponentPopupWithSearch from "../common/ComponentPopupWithSearch";
import "./balance.less";

// import { suggestionsList } from "../hooks/index";
import TableBalanceItem from "./TableBalanceItem";
const TableBalance = ({
  tableHeaders,
  tableRowsData,
  handleRowCheck,
  handleFSItemChange,
  type,
  setIsBalanceMapped,
  setIsIncomeMapped,
  accountListObj,

  isShowAllAccount,
  setIsMappingChanged,
  isMappingChanged,
  templateId,
}) => {
  const router = useRouter();
  const { query } = router;
  const getBackgroundColor = (colInx) => {
    return colInx < 2 ? "rgba(250, 251, 255, 1)" : "unset";
  };
  return (
    <>
      {isMappingChanged && !templateId && (
        <Alert
          message="Save mapping logic as a template to reuse it on other reports"
          type="info"
          showIcon
          closable
          style={{ margin: "10px 0" }}
        />
      )}
      {isMappingChanged && templateId && (
        <Alert
          message="Save template to update mapping logic or save as a new template to reuse it on other reports"
          type="info"
          showIcon
          closable
          style={{ margin: "10px 0" }}
        />
      )}
      <div className={"table-balance"}>
        <header className={"table-header"}>
          {tableHeaders?.map(({ label, width = "40px", flex }, colInx) => (
            <div
              className={"header-cell"}
              style={{
                padding: "10px",
                width,
                minWidth: width,
                flex,
                backgroundColor: getBackgroundColor(colInx),
              }}
              key={label}
            >
              {label}
            </div>
          ))}
        </header>
        <main className={"table-body"}>
          {tableRowsData?.map(({ colItems }, inx) => {
            return (
              <div className={`table-each-row`} key={`${inx}`}>
                {colItems.map((cellVal, colInx) => {
                  const getDataClass = () => {
                    if (!colItems[0].isChecked && !isShowAllAccount) {
                      return "disabled-value";
                    } else if (
                      cellVal?.confidence >= 80 &&
                      cellVal?.confidence
                    ) {
                      return "more-accurate-value";
                    } else if (
                      cellVal?.confidence < 80 &&
                      cellVal?.confidence
                    ) {
                      return "less-accurate-value";
                    } else if (!cellVal?.confidence) {
                      return "user-defined-value";
                    }
                  };
                  let child = "NA";
                  if (colInx === 0) {
                    if (isShowAllAccount) {
                      child = cellVal.itemType;
                    } else {
                      child = (
                        <Checkbox
                          checked={cellVal.isChecked}
                          onChange={() => {
                            if (cellVal.isChecked) {
                              handleRowCheck(inx);
                              removeMappingHook(
                                {
                                  accountId: colItems[1]?.accountId,
                                },
                                (response) => {
                                  console.log("removeMappingHook", response);
                                  setIsMappingChanged(true);
                                  getAccountByType(
                                    {
                                      reportId: query?.reportId,
                                      type: "balance_item",
                                    },
                                    (response) => {
                                      if (response?.length) {
                                        setIsBalanceMapped(true);
                                      } else {
                                        setIsBalanceMapped(false);
                                      }
                                    }
                                  );
                                  getAccountByType(
                                    {
                                      reportId: query?.reportId,
                                      type: "income_item",
                                    },
                                    (response) => {
                                      if (response?.length) {
                                        setIsIncomeMapped(true);
                                      } else {
                                        setIsIncomeMapped(false);
                                      }
                                    }
                                  );
                                }
                              );
                            } else {
                              handleRowCheck(inx);
                              handleFSItemChange(inx, 2, {
                                id: "",
                                name: "",
                                confidence: "",
                                children: "",
                              });
                              handleFSItemChange(inx, 3, {
                                id: "",
                                name: "",
                                confidence: "",
                                children: "",
                              });
                            }
                          }}
                        />
                        // <input
                        //   type={"checkbox"}
                        //   value={cellVal.isChecked}
                        //   onChange={() => handleRowCheck(inx)}
                        //   defaultValue={cellVal.isChecked}
                        // />
                      );
                    }
                  } else if (colInx === 1) {
                    child = cellVal?.label;
                  } else {
                    child = (
                      <TableBalanceItem
                        colItems={colItems}
                        cellVal={cellVal}
                        getDataClass={getDataClass}
                        colInx={colInx}
                        rowIndex={inx}
                        handleFSItemChange={handleFSItemChange}
                        type={type}
                        handleRowCheck={handleRowCheck}
                        setIsBalanceMapped={setIsBalanceMapped}
                        setIsIncomeMapped={setIsIncomeMapped}
                        accountListObj={accountListObj}
                        isShowAllAccount={isShowAllAccount}
                        setIsMappingChanged={setIsMappingChanged}
                      />
                    );
                  }

                  return (
                    <div
                      key={`${colInx}-${cellVal?.label}`}
                      className={`data-cell`}
                      style={{
                        width: tableHeaders[colInx].width,
                        minWidth: tableHeaders[colInx].width,
                        flex: tableHeaders[colInx].flex,
                        backgroundColor: getBackgroundColor(colInx),
                      }}
                    >
                      {child}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </main>
      </div>
    </>
  );
};

export default TableBalance;
