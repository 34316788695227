import { useEffect, useMemo, useState } from "react";
import { tableBalanceMock, tableIncomeMock } from "./table-balance.mock";

export const useBalance = (
  selectedStatement,
  balanceSheetData,
  checkedData
) => {
  console.log(
    "selectedStatement",
    selectedStatement,
    balanceSheetData,
    checkedData
  );
  const [checkedRows, setCheckedRows] = useState(checkedData);
  let [mockData, setMockData] = useState(balanceSheetData);

  useEffect(() => {
    setCheckedRows(checkedData);
  }, [checkedData]);

  useEffect(() => {
    setMockData(balanceSheetData);
  }, [balanceSheetData?.length, JSON.stringify(balanceSheetData)]);

  const handleRowCheck = (rowIndex) => {
    console.log("Checked`", rowIndex, checkedRows);
    if (checkedRows[rowIndex]) {
      setCheckedRows({ ...checkedRows, [rowIndex]: false });
    } else {
      setCheckedRows({ ...checkedRows, [rowIndex]: true });
    }
  };

  const handleFSItemChange = (rowIndex, colIndex, value) => {
    mockData.dataItems[rowIndex].colItems[colIndex] = value;
    console.log("Col Index", colIndex);
    if (colIndex === 2) {
      if (value?.children?.length) {
        mockData.dataItems[rowIndex].colItems[3] = value?.children[0];
      } else {
        mockData.dataItems[rowIndex].colItems[3] = {
          id: "",
          name: "",
          confidence: 0,
          value: 0,
        };
      }
    }
    setMockData({ ...mockData });
  };

  // let mockTableData = balanceSheetData;
  // if (selectedStatement === "Income Statement") {
  //   mockTableData = incomeSheetData;
  // }

  // console.log("Balance Mock Data", mockData);

  const tableRowsData = useMemo(
    () =>
      mockData?.dataItems?.map((eachRowData, rowInx) => {
        const rowData = { ...eachRowData };

        // console.log("checkedRows[rowInx]", checkedRows, checkedRows[rowInx]);
        rowData.colItems[0].isChecked = !!checkedRows[rowInx];
        return rowData;
      }),
    [
      Object.keys(checkedRows)?.length,
      JSON.stringify(checkedRows),
      selectedStatement,
      JSON.stringify(mockData),
    ]
  );

  // useEffect(() => {
  //   setCheckedRows(checkedData);
  // }, [selectedStatement, checkedData]);
  console.log("Table Rows Data", tableRowsData);
  return {
    handleRowCheck,
    tableHeaders: mockData.headers,
    tableRowsData,
    handleFSItemChange,
  };
};
