import { createSlice } from "@reduxjs/toolkit";
// Slice
const userSlice = createSlice({
  name: "user",
  initialState: {},
  reducers: {
    loginSuccess: (state, action) => {
      state.userInfo = action.payload;
    },
    logoutSuccess: (state, action) => {
      state.userInfo = null;
    },
    updateUser: (state, action) => {
      state.userInfo = action.payload;
    },

    changeCollapsedState: (state, action) => {
      state.isCollapsed = action.payload;
    },

    changePdfCollapsedState: (state, action) => {
      state.isPdfCollapsed = action.payload;
    },
  },
});

export default userSlice.reducer;
// Actions
export const {
  loginSuccess,
  logoutSuccess,
  updateUser,
  changeCollapsedState,
  changePdfCollapsedState,
} = userSlice.actions;
// export const login =
//   ({ username, password }) =>
//   async (dispatch) => {
//     try {
//       // const res = await api.post('/api/auth/login/', { username, password })
//       dispatch(loginSuccess({ username }));
//     } catch (e) {
//       return console.error(e.message);
//     }
//   };
// export const logout = () => async (dispatch) => {
//   try {
//     // const res = await api.post('/api/auth/logout/')
//     return dispatch(logoutSuccess());
//   } catch (e) {
//     return console.error(e.message);
//   }
// };
