import { useEffect, useMemo, useState } from "react";
import { tableBalanceMock, tableIncomeMock } from "./table-balance.mock";

export const useIncome = (selectedStatement, incomeSheetData, checkedData) => {
  console.log("selectedStatement", selectedStatement);
  const [checkedRows, setCheckedRows] = useState(checkedData);
  let [mockData, setMockData] = useState(incomeSheetData);

  const handleRowCheck = (rowIndex) => {
    if (checkedRows[rowIndex]) {
      setCheckedRows({ ...checkedRows, [rowIndex]: false });
    } else {
      setCheckedRows({ ...checkedRows, [rowIndex]: true });
    }
  };

  const handleFSItemChange = (rowIndex, colIndex, value) => {
    mockData.dataItems[rowIndex].colItems[colIndex] = value;
    console.log("Col Index", colIndex);
    if (colIndex === 2) {
      if (value?.children?.length) {
        mockData.dataItems[rowIndex].colItems[3] = value?.children[0];
      } else {
        mockData.dataItems[rowIndex].colItems[3] = {
          id: "",
          name: "",
          confidence: 0,
          value: 0,
        };
      }
    }
    setMockData({ ...mockData });
  };

  // let mockTableData = incomeSheetData;
  //   if (selectedStatement === "Income Statement") {
  //     mockTableData = incomeSheetData;
  //   }

  const tableRowsData = useMemo(
    () =>
      mockData?.dataItems?.map((eachRowData, rowInx) => {
        const rowData = { ...eachRowData };

        rowData.colItems[0].isChecked = !!checkedRows[rowInx];
        return rowData;
      }),
    [checkedRows, selectedStatement, mockData]
  );

  //   useEffect(() => {
  //     setCheckedRows(checkedData);
  //   }, [selectedStatement, checkedData]);

  return {
    handleRowCheck,
    tableHeaders: mockData.headers,
    tableRowsData,
    handleFSItemChange,
  };
};
