//import less
import { DownOutlined } from "@ant-design/icons";
import { useState } from "react";
import ComponentPopupWithSearch from "../ComponentPopupWithSearch";
import "./ComponentDropdownPopup.less";

const ComponentDropdownPopup = ({
  list,
  selectedItem,
  title,
  onSelect,
  addText,
  onAdd,
  showReportEdit,
  updateData
}) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <ComponentPopupWithSearch
      trigger={
        <div
          className="component-dropdown-popup"
          onClick={() => {
            setOpen(true);
          }}
        >
          <div style={{ flex: "1 1 auto" }}> {selectedItem?.name}</div>
          <DownOutlined />
        </div>
      }
      open={open}
      handleClose={handleClose}
      title={title}
      list={list}
      onSelect={(item) => {
        onSelect(item);
        handleClose();
      }}
      addText={addText}
      onAdd={onAdd}
      showReportEdit={showReportEdit}
      updateData={updateData}
    />
  );
};

export default ComponentDropdownPopup;
