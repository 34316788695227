import ContentCard from "../../atoms/ContentCard";
import React, { useContext, useEffect, useMemo, useState } from "react";
import moment from "moment";
import Title from "../../atoms/Title";
import LeftControl from "../../sub-components/LeftControl";
import RightControl from "../../sub-components/RightControl";
// import DateRangeConfig from "../leftControl/GlobalConfig/DateRangeConfig/indexDateRange";
import ComponentDropdown from "../../atoms/ComponentDropdown";

import "./CreateOrEditReport.less";

import ComponentSubTitle from "../../atoms/ComponentSubTitle";
import ComponentDatePicker from "../../atoms/ComponentDatePicker";
import StartEndDatePicker from "../../sub-components/StartEndDatePicker";
import { useDispatch, useSelector } from "react-redux";
import {
  createOrUpdateChangesEquityDateHook,
  createOrUpdateCurrencyHook,
  createOrUpdateFinancialPositionDateHook,
  createOrUpdateProfitAndLossDateHook,
} from "../../api-hooks/report";
import { useRouter } from "../../routes";
import { setSelectedReport } from "../../store/report";
import PreviewPDF from "../../sub-components/PreviewPDF";

import { changePdfCollapsedState } from "../../store/user";
import { Tooltip } from "antd";
import { ReactComponent as PreviewPDFIcon } from "../../assets/svg-icons/previewIcon.svg";

const StepFour = ({ boxHeight }) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { query } = router;
  const [refreshKey, setRefreshKey] = useState(Math.random());

  const selectReportData = useSelector((state) => state.report.selectedReport);
  const financialPositionDateData = useSelector(
    (state) => state.report.selectedReport?.financialPositionEndDate
  );

  const profitLossDateData = useSelector(
    (state) => state.report.selectedReport?.profitLossDate
  );

  const changesEquityDateData = useSelector(
    (state) => state.report.selectedReport?.changesEquityDate
  );

  const currencyData = useSelector(
    (state) => state.report.selectedReport?.currency
  );

  console.log("financialPositionDateData", financialPositionDateData);

  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [financialPositionCurrentEndDate, setFinancialPositionCurrentEndDate] =
    useState("");
  const [
    financialPositionPreviousEndDate,
    setFinancialPositionPreviousEndDate,
  ] = useState("");

  const [profitLossCurrentStartDate, setProfitLossCurrentStartDate] =
    useState("");

  const [profitLossCurrentEndDate, setProfitLossCurrentEndDate] = useState("");

  const [profitLossPreviousStartDate, setProfitLossPreviousStartDate] =
    useState("");
  const [profitLossPreviousEndDate, setProfitLossPreviousEndDate] =
    useState("");

  const [changesEquityStartDate, setChangesEquityStartDate] = useState("");

  const [changesEquityEndDate, setChangesEquityEndDate] = useState("");

  useEffect(() => {
    if (financialPositionDateData?.data) {
      console.log("Financial Position Date Data", financialPositionDateData);
      setFinancialPositionCurrentEndDate(
        financialPositionDateData?.data?.current
      );
      setFinancialPositionPreviousEndDate(
        financialPositionDateData?.data?.previous
      );
    }
  }, [financialPositionDateData?.id]);

  useEffect(() => {
    if (profitLossDateData?.data) {
      setProfitLossCurrentStartDate(
        profitLossDateData?.data?.currentPeriod?.startDate
      );
      setProfitLossCurrentEndDate(
        profitLossDateData?.data?.currentPeriod?.endDate
      );
      setProfitLossPreviousStartDate(
        profitLossDateData?.data?.previousPeriod?.startDate
      );
      setProfitLossPreviousEndDate(
        profitLossDateData?.data?.previousPeriod?.endDate
      );
    }
  }, [profitLossDateData?.id]);

  useEffect(() => {
    if (changesEquityDateData?.data) {
      setChangesEquityStartDate(changesEquityDateData?.data?.startDate);
      setChangesEquityEndDate(changesEquityDateData?.data?.endDate);
    }
  }, [changesEquityDateData?.id]);

  useEffect(() => {
    if (currencyData?.data?.text) {
      setSelectedCurrency(currencyData?.data?.text);
    }
  }, [currencyData?.id]);

  const onChangeFinancialPositionCurrentEndDate = (value) => {
    if (value) {
      setFinancialPositionCurrentEndDate(moment(value).format("DD/MM/YYYY"));
      if (financialPositionPreviousEndDate) {
        saveFinancialPositionDate(
          moment(value).format("DD/MM/YYYY"),
          financialPositionPreviousEndDate
        );
      }
    } else {
      setFinancialPositionCurrentEndDate("");
    }
  };

  const saveFinancialPositionDate = (current, previous) => {
    createOrUpdateFinancialPositionDateHook(
      {
        reportId: query?.reportId,
        current: current,
        previous: previous,
        id: financialPositionDateData?.id,
      },
      (response) => {
        console.log("Save Financial Position Date", response);
        setRefreshKey(Math.random());
        if (!financialPositionDateData?.id) {
          dispatch(
            setSelectedReport({
              ...selectReportData,
              financialPositionEndDate: response,
            })
          );
        }
      }
    );
  };

  const onChangeFinancialPositionPreviousEndDate = (value) => {
    if (value) {
      setFinancialPositionPreviousEndDate(moment(value).format("DD/MM/YYYY"));
      if (financialPositionCurrentEndDate) {
        saveFinancialPositionDate(
          financialPositionCurrentEndDate,
          moment(value).format("DD/MM/YYYY")
        );
      }
    } else {
      setFinancialPositionPreviousEndDate("");
    }
  };

  const saveProfitLossDate = (
    currentPeriodStartDate,
    currentPeriodEndDate,
    previousPeriodStartDate,
    previousPeriodEndDate
  ) => {
    createOrUpdateProfitAndLossDateHook(
      {
        reportId: query?.reportId,
        currentPeriodStartDate: currentPeriodStartDate,
        currentPeriodEndDate: currentPeriodEndDate,
        previousPeriodStartDate: previousPeriodStartDate,
        previousPeriodEndDate: previousPeriodEndDate,
        id: profitLossDateData?.id,
      },
      (response) => {
        console.log("Save Financial Position Date", response);
        setRefreshKey(Math.random());
        if (!profitLossDateData?.id) {
          dispatch(
            setSelectedReport({
              ...selectReportData,
              profitLossDate: response,
            })
          );
        }
      }
    );
  };

  const onChangeProfitLossCurrentStartDate = (value) => {
    if (value) {
      setProfitLossCurrentStartDate(moment(value).format("DD/MM/YYYY"));
      if (
        profitLossCurrentEndDate &&
        profitLossPreviousStartDate &&
        profitLossPreviousEndDate
      ) {
        saveProfitLossDate(
          moment(value).format("DD/MM/YYYY"),
          profitLossCurrentEndDate,
          profitLossPreviousStartDate,
          profitLossPreviousEndDate
        );
      }
    } else {
      setProfitLossCurrentStartDate("");
    }
  };

  const onChangeProfitLossCurrentEndDate = (value) => {
    if (value) {
      setProfitLossCurrentEndDate(moment(value).format("DD/MM/YYYY"));
      if (
        profitLossCurrentStartDate &&
        profitLossPreviousStartDate &&
        profitLossPreviousEndDate
      ) {
        saveProfitLossDate(
          profitLossCurrentStartDate,
          moment(value).format("DD/MM/YYYY"),
          profitLossPreviousStartDate,
          profitLossPreviousEndDate
        );
      }
    } else {
      setProfitLossCurrentEndDate("");
    }
  };

  const onChangeProfitLossPreviousStartDate = (value) => {
    if (value) {
      setProfitLossPreviousStartDate(moment(value).format("DD/MM/YYYY"));
      if (
        profitLossCurrentStartDate &&
        profitLossCurrentEndDate &&
        profitLossPreviousEndDate
      ) {
        saveProfitLossDate(
          profitLossCurrentStartDate,
          profitLossCurrentEndDate,
          moment(value).format("DD/MM/YYYY"),
          profitLossPreviousEndDate
        );
      }
    } else {
      setProfitLossPreviousStartDate("");
    }
  };

  const onChangeProfitLossPreviousEndDate = (value) => {
    if (value) {
      setProfitLossPreviousEndDate(moment(value).format("DD/MM/YYYY"));

      if (
        profitLossCurrentStartDate &&
        profitLossCurrentEndDate &&
        profitLossPreviousStartDate
      ) {
        saveProfitLossDate(
          profitLossCurrentStartDate,
          profitLossCurrentEndDate,
          profitLossPreviousStartDate,
          moment(value).format("DD/MM/YYYY")
        );
      }
    } else {
      setProfitLossPreviousEndDate("");
    }
  };

  const saveChangesInEquityDate = (startDate, endDate) => {
    createOrUpdateChangesEquityDateHook(
      {
        reportId: query?.reportId,
        startDate: startDate,
        endDate: endDate,
        id: changesEquityDateData?.id,
      },
      (response) => {
        console.log("Save Financial Position Date", response);
        setRefreshKey(Math.random());
        if (!changesEquityDateData?.id) {
          dispatch(
            setSelectedReport({
              ...selectReportData,
              changesEquityDate: response,
            })
          );
        }
      }
    );
  };

  const onChangeChangesEquityStartDate = (value) => {
    if (value) {
      setChangesEquityStartDate(moment(value).format("DD/MM/YYYY"));
      if (changesEquityEndDate) {
        saveChangesInEquityDate(
          moment(value).format("DD/MM/YYYY"),
          changesEquityEndDate
        );
      }
    } else {
      setChangesEquityStartDate("");
    }
  };

  const onChangeChangesEquityEndDate = (value) => {
    if (value) {
      setChangesEquityEndDate(moment(value).format("DD/MM/YYYY"));
      if (changesEquityStartDate) {
        saveChangesInEquityDate(
          changesEquityStartDate,
          moment(value).format("DD/MM/YYYY")
        );
      }
    } else {
      setChangesEquityEndDate("");
    }
  };

  const onChangeCurrency = (value) => {
    setSelectedCurrency(value);
    createOrUpdateCurrencyHook(
      {
        reportId: query?.reportId,
        currency: value,
        id: currencyData?.id,
      },
      (response) => {
        console.log("Save Currency", response);
        setRefreshKey(Math.random());
        if (!currencyData?.id) {
          dispatch(
            setSelectedReport({
              ...selectReportData,
              currency: response,
            })
          );
        }
      }
    );
  };

  const isCollapsed = useSelector((state) => state.user.isPdfCollapsed);

  const setIsCollapsed = (value) => {
    dispatch(changePdfCollapsedState(value));
  };

  const reportPeriodObj = useSelector((state) => state.report.reportPeriod);

  return (
    <div style={{ display: "flex", flex: "1 1 auto" }}>
      <LeftControl
        boxHeight={boxHeight}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
      >
        <ContentCard
          style={{ padding: 32, minHeight: "unset" }}
          bodyStyle={{ padding: 0, display: "block" }}
          size="large"
        >
          <Title
            title="Date"
            titleStyle={{
              fontSize: "18px",
              fontWeight: "800",
              lineHeight: "24px",
            }}
          />

          <div style={{ marginTop: "20px" }}>
            <ComponentSubTitle title="Statement of Financial Position" />
            <div style={{ marginTop: "12px", paddingLeft: "20px" }}>
              <div className="step-four-current-period-date-container">
                Current Period End Date
              </div>
              <ComponentDatePicker
                onChange={onChangeFinancialPositionCurrentEndDate}
                value={
                  financialPositionCurrentEndDate
                    ? moment(financialPositionCurrentEndDate, "DD/MM/YYYY")
                    : ""
                }
                placeholder="DD/MM/YYYY"
              />
            </div>
            {reportPeriodObj["previous"] && (
              <div style={{ marginTop: "12px", paddingLeft: "20px" }}>
                <div className="step-four-current-period-date-container">
                  Previous Period End Date
                </div>
                <ComponentDatePicker
                  onChange={onChangeFinancialPositionPreviousEndDate}
                  value={
                    financialPositionPreviousEndDate
                      ? moment(financialPositionPreviousEndDate, "DD/MM/YYYY")
                      : ""
                  }
                  placeholder="DD/MM/YYYY"
                />
              </div>
            )}
          </div>
          <div style={{ marginTop: "35px" }}>
            <ComponentSubTitle title="Statement of Profit or Loss" />
            <div style={{ paddingLeft: "20px" }}>
              <div className="step-four-period-title-container">
                Current Period
              </div>
              <StartEndDatePicker
                startDate={
                  profitLossCurrentStartDate
                    ? moment(profitLossCurrentStartDate, "DD/MM/YYYY")
                    : ""
                }
                endDate={
                  profitLossCurrentEndDate
                    ? moment(profitLossCurrentEndDate, "DD/MM/YYYY")
                    : ""
                }
                onChangeStartDate={onChangeProfitLossCurrentStartDate}
                onChangeEndDate={onChangeProfitLossCurrentEndDate}
              />
            </div>
            {reportPeriodObj["previous"] && (
              <div style={{ paddingLeft: "20px" }}>
                <div
                  className="step-four-period-title-container"
                  style={{ marginTop: "14px" }}
                >
                  Previous Period
                </div>
                <StartEndDatePicker
                  startDate={
                    profitLossPreviousStartDate
                      ? moment(profitLossPreviousStartDate, "DD/MM/YYYY")
                      : ""
                  }
                  endDate={
                    profitLossPreviousEndDate
                      ? moment(profitLossPreviousEndDate, "DD/MM/YYYY")
                      : ""
                  }
                  onChangeStartDate={onChangeProfitLossPreviousStartDate}
                  onChangeEndDate={onChangeProfitLossPreviousEndDate}
                />
              </div>
            )}
          </div>
          {/* <div style={{ marginTop: "35px" }}>
            <ComponentSubTitle title="Statement of Changes in Equity" />
            <div style={{ marginTop: "16px", paddingLeft: "20px" }}>
              <StartEndDatePicker
                startDate={
                  changesEquityStartDate
                    ? moment(changesEquityStartDate, "DD/MM/YYYY")
                    : ""
                }
                endDate={
                  changesEquityEndDate
                    ? moment(changesEquityEndDate, "DD/MM/YYYY")
                    : ""
                }
                onChangeStartDate={onChangeChangesEquityStartDate}
                onChangeEndDate={onChangeChangesEquityEndDate}
              />
            </div>
          </div> */}
        </ContentCard>
        <ContentCard
          style={{ padding: 32, minHeight: "unset", marginTop: "16px" }}
          bodyStyle={{ padding: 0, display: "block" }}
          size="large"
        >
          <Title
            title="Currency"
            titleStyle={{
              fontSize: "18px",
              fontWeight: "800",
              lineHeight: "24px",
            }}
          />
          <div
            style={{
              marginTop: "20px",
              fontSize: "12px",
              color: "#4A4A4A",
              marginBottom: "4px",
            }}
          >
            Select Document Currency
          </div>
          <ComponentDropdown
            style={{ width: "100%" }}
            optionList={[
              {
                label: "HK$",
                value: "HK$",
              },
              {
                label: "RMB",
                value: "RMB",
              },
              {
                label: "US$",
                value: "US$",
              },
            ]}
            value={selectedCurrency}
            handleChange={(value) => {
              onChangeCurrency(value);
            }}
          />

          {/* <div
            style={{
              marginTop: "16px",
              fontSize: "12px",
              color: "#4A4A4A",
              marginBottom: "4px",
            }}
          >
            Present Dollars in
          </div>
          <ComponentDropdown
            style={{ width: "100%" }}
            optionList={[
              {
                label: "000's",
                value: "000's",
              },
            ]}
            value="000's"
          /> */}
        </ContentCard>
      </LeftControl>
      <RightControl
        boxHeight={boxHeight}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
      >
        <ContentCard
          style={{ padding: "0px 32px 32px", height: "100%" }}
          bodyStyle={{ padding: 0, height: "100%" }}
          size="large"
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              height: "inherit",
            }}
          >
            <div className={"report-preview-heading"}>
              <div style={{ flex: "1 1 auto" }}>Preview</div>
              <Tooltip placement="left" title="Collpase Preview">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setIsCollapsed(!isCollapsed);
                  }}
                >
                  <PreviewPDFIcon style={{ width: "16px", height: "16px" }} />
                </div>
              </Tooltip>
            </div>

            <PreviewPDF refreshKey={refreshKey} />
          </div>
        </ContentCard>
      </RightControl>
    </div>
  );
};

export default StepFour;
