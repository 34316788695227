import React from "react";
import { Select } from "antd";

const ComponentDropdown = ({
  placeholder,
  style,
  optionList,
  handleChange,
  allowClear,
  ...props
}) => {
  return (
    <Select
      placeholder={placeholder}
      style={{
        border: "1px solid #D8DAE5",
        borderRadius: "4px",
        ...style,
      }}
      allowClear={allowClear}
      onChange={handleChange}
      {...props}
      //   style={{ width: "100%" }}
      //   value={companyScid}
      //   onChange={(value, _option) => {
      //     //1.
      //     let _scid = value || ""; //scid
      //     let _cid = _option?.item?.cid || ""; //cid
      //     let _name = _option?.children || ""; //子公司的名称
      //     //2.
      //     let _data = {
      //       cid: _cid,
      //       scid: _scid,
      //       companyName: _name,
      //     };
      //     updateParentCompanyInfo(_data);
      //   }}
    >
      {/* <Select.Option value=""> */}
      {/* {formatMessage({ id: "PleaseChoose", defaultMessage: "请选择" })} */}
      {/* </Select.Option> */}
      {optionList?.map((item) => (
        <Select.Option key={`${item.value}`} value={item.value}>
          {item?.label}
        </Select.Option>
      ))}
    </Select>
  );
};

export default ComponentDropdown;
