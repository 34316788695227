import React, { useState } from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import "./ReportDrive.less";
import DraftItem from "./DraftItem";
const ExpandableGroup = ({ item, onSelect, setReportDisplay, fetchData }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div>
      <div
        className={"report-drive-expandable"}
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
      >
        <div className={"saved-expandable-group-title"}>{item.name}</div>
        {isExpanded && <UpOutlined style={{ width: "10px", height: "6px" }} />}
        {!isExpanded && (
          <DownOutlined style={{ width: "10px", height: "6px" }} />
        )}
      </div>
      {isExpanded && (
        <>
          {item?.reports?.map((draftItem, index) => {
            return (
              <DraftItem
                fetchData={fetchData}
                key={index}
                draftItem={draftItem}
                onSelect={onSelect}
                setReportDisplay={setReportDisplay}
              />
            );
          })}
        </>
      )}
    </div>
  );
};

export default ExpandableGroup;
