// import less
import "./MakeAdjustmentModal.less";

import ComponentModal from "../../atoms/ComponentModal";
import ComponentDatePicker from "../../atoms/ComponentDatePicker";
import { useEffect, useState } from "react";
import {
  DeleteOutlined,
  PlusOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import ComponentDropdown from "../../atoms/ComponentDropdown";
import ComponentInput from "../../atoms/ComponentInput";
import ComponentButton from "../../atoms/ComponentButton";
import { getAccountByType } from "../../api-hooks/report";
import { useRouter } from "../../routes";
import { createAdjustmentsHook } from "../../api-hooks/adjustments";

const MakeAdjustmentModal = ({ open, handleClose, type, categoryName }) => {
  const [entries, setEntries] = useState([]);
  const router = useRouter();
  const { query } = router;

  const [accountDropdownList, setAccountDropdownList] = useState([]);
  const [accountObj, setAccountObj] = useState({});

  useEffect(() => {
    getAccountByType(
      {
        reportId: query?.reportId,
        type: type,
      },
      (response) => {
        console.log("All Accounts", response);
        if (response?.length) {
          let dropDownList = [];
          response?.forEach((item) => {
            if (categoryName === item?.category?.name) {
              dropDownList.push({
                label: item?.name,
                value: item?.id,
              });
              accountObj[item?.id] = item;
            }
          });
          setAccountDropdownList([...dropDownList]);
          setAccountObj({ ...accountObj });
        }
      }
    );
  }, [type]);

  const onAddEntries = () => {
    const data = {
      entryType: "",
      amount: 0,
      account: {
        id: "",
      },
    };

    entries.push(data);
    setEntries([...entries]);
  };

  console.log("accountDropdownList", accountDropdownList);
  console.log("accountObj", accountObj);

  const updateEntryData = (index, updatedData) => {
    console.log("Make Adjustment Updated Data", updatedData);
    entries.splice(index, 1, updatedData);
    setEntries([...entries]);
  };

  const onAddAdjustments = () => {
    console.log("On Add Adjustments", entries);
    createAdjustmentsHook(
      {
        reportId: query?.reportId,
        ledgerEntries: entries,
      },
      (response) => {
        console.log("Add Ajustmemts", response);
        handleClose();
      }
    );
  };

  return (
    <ComponentModal
      open={open}
      handleClose={handleClose}
      backText="Cancel"
      submitText="Add Adjustment"
      title="Add Journal Entries"
      width={832}
      handleOk={onAddAdjustments}
    >
      <div style={{ width: "340px" }}>
        <div className="make-adjustment-journal-date-container">
          Journal Date
        </div>
        <ComponentDatePicker />
      </div>

      <div style={{ marginTop: "24px" }}>
        <div className={"table-balance"}>
          <header className={"table-header"}>
            <div
              className={"header-cell"}
              style={{
                width: "80px",
                // flex,
                backgroundColor: "rgba(250, 251, 255, 1)",
                // minWidth,
                // maxWidth,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "40px",
                }}
              ></div>
            </div>

            <div
              className={"header-cell"}
              style={{
                // width,
                width: "284px",
                backgroundColor: "rgba(250, 251, 255, 1)",
                // minWidth,
                // maxWidth,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "40px",
                }}
              >
                Account Name/Code
              </div>
            </div>

            <div
              className={"header-cell"}
              style={{
                width: "200px",
                // flex,
                backgroundColor: "rgba(250, 251, 255, 1)",
                // minWidth,
                // maxWidth,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "40px",
                }}
              >
                Description (optional)
              </div>
            </div>

            <div
              className={"header-cell"}
              style={{
                width: "150px",
                // flex,
                backgroundColor: "rgba(250, 251, 255, 1)",
                // minWidth,
                // maxWidth,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "40px",
                }}
              >
                Debit
              </div>
            </div>

            <div
              className={"header-cell"}
              style={{
                width: "150px",
                // flex,
                backgroundColor: "rgba(250, 251, 255, 1)",
                // minWidth,
                // maxWidth,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "40px",
                }}
              >
                Credit
              </div>
            </div>
          </header>
          <main className={"table-body"}>
            {entries?.map?.((row, inx) => {
              console.log("Row", row);
              return (
                <div className={`table-each-row`} key={`${inx}`}>
                  <div
                    className={`data-cell`}
                    style={{
                      width: "284px",
                      //   width: equityTable?.headers?.[0]?.width,
                      //   flex: equityTable?.headers?.[0]?.flex,
                      //   minWidth: equityTable?.headers?.[0].minWidth,
                      //   maxWidth: equityTable?.headers?.[0].maxWidth,
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <DeleteOutlined
                        style={{ marginRight: "9.5px" }}
                        onClick={() => {
                          //   onDeleteRow(row.id);
                        }}
                      />
                      <ComponentDropdown
                        style={{ width: "244px" }}
                        optionList={accountDropdownList}
                        value={row?.account?.id}
                        handleChange={(value) => {
                          const accountData = accountObj[value];
                          const updatedData = {
                            entryType:
                              accountData?.ledgerEntries?.[0]?.entryType,
                            amount: accountData?.ledgerEntries?.[0]?.amount,
                            account: {
                              id: value,
                            },
                          };
                          updateEntryData(inx, updatedData);
                        }}
                      />
                    </div>
                  </div>

                  <div
                    className={`data-cell`}
                    style={{
                      width: "200px",
                    }}
                  >
                    <ComponentInput value="Adjustment" />
                  </div>
                  <div
                    className={`data-cell`}
                    style={{
                      width: "150px",
                    }}
                  >
                    <ComponentInput
                      value={row?.entryType === "debit" ? row?.amount : ""}
                      onChange={(value) => {
                        updateEntryData(inx, {
                          ...row,
                          entryType: "debit",
                          amount: value,
                        });
                      }}
                    />
                  </div>

                  <div
                    className={`data-cell`}
                    style={{
                      width: "150px",
                    }}
                  >
                    <ComponentInput
                      value={row?.entryType === "credit" ? row?.amount : ""}
                      onChange={(value) => {
                        updateEntryData(inx, {
                          ...row,
                          entryType: "credit",
                          amount: value,
                        });
                      }}
                    />
                  </div>
                </div>
              );
            })}
            <div
              style={{
                marginTop: "15px",
                paddingBottom: "8px",
                // borderBottom: "1px solid #D8DAE5",
                marginBottom: "10px",
                color: "#4BB354",
                width: "80px",
                paddingLeft: "16px",
                cursor: "pointer",
              }}
              onClick={() => {
                onAddEntries();
              }}
            >
              <PlusSquareOutlined />
              {/* <ComponentButton
                title=""
                icon={<PlusOutlined />}
                style={{
                  color: "#4BB354",
                  backgroundColor: "#F5FBF8",
                  height: "36px",
                  //   width: "100%",
                  border: "none",
                }}
                // onClick={onSaveNewRow}
              /> */}
            </div>
          </main>
        </div>
      </div>
    </ComponentModal>
  );
};

export default MakeAdjustmentModal;
