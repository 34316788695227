import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { createCompanyHook } from "../../api-hooks/company";
import { createReportHook } from "../../api-hooks/report";
import { createFinancialYearHook, fetchYearsHook } from "../../api-hooks/year";
import ComponentDropdown from "../../atoms/ComponentDropdown";
import ComponentDropdownPopup from "../../atoms/ComponentDropdownPopup";
import ComponentInput from "../../atoms/ComponentInput";
import ComponentModal from "../../atoms/ComponentModal";
import { CREATE_FINANCIAL_REPORT_URL } from "../../routes";
import { setCompanies } from "../../store/company";
import year, { setYears } from "../../store/year";
import { updateFinancialYearHook } from "../../api-hooks/year";
import { updateCompanyHook } from "../../api-hooks/company";
import { fetchCompaniesHook } from "../../api-hooks/company";

import "./SaveReportModal.less";
const SaveReportModal = ({
  open,
  handleClose,
  duplicateId,
  setReportList,
  reportList,
  showReportEdit,
  updateData,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const years = useSelector((state) => state.year.years);
  const companies = useSelector((state) => state.company.companies);
  const [name, setName] = useState("");
  const [draftVersion, setDraftVersion] = useState("");
  const [selectedYear, setSelectedYear] = useState(null);

  const [selectedCompany, setSelectedCompany] = useState(null);

  const onAddFinancialYear = (financialYear) => {
    createFinancialYearHook(financialYear, (response) => {
      const newYearList = [...years];
      newYearList.unshift(response);
      setSelectedYear(response);
      updateReportName(null, response);
      dispatch(setYears(newYearList));
    });
  };

  const onAddCompany = (company) => {
    createCompanyHook(company, (response) => {
      const newCompanyList = [...companies];
      newCompanyList.unshift(response);
      setSelectedCompany(response);
      updateReportName(response);
      dispatch(setCompanies(newCompanyList));
    });
  };

  useEffect(() => {
    let reportName;

    if (companies?.length && !selectedCompany) {
      setSelectedCompany(companies[0]);
      reportName = companies[0].name;
    }
    if (years?.length && !selectedYear) {
      setSelectedYear(years[0]);
      if (reportName) {
        reportName = `${reportName} ${years[0]?.name}`;
      }
    }
    if (reportName) {
      setName(reportName);
    }
  }, [years?.length, companies?.length]);
  const updateFiscalyear = (id, value, callback) => {
    // call patch function
    updateFinancialYearHook(
      {
        name: value,
        id: id,
      },
      (response) => {
        console.log("Equation Updated created", response);
        fetchYearsHook((allYearResponse) => {
          dispatch(setYears([...allYearResponse]));

          console.log("Response Year", response);
          if (selectedYear?.id === response?.id) {
            setSelectedYear(response);
          }
          callback();
        });
      }
    );
  };
  const updateCompany = (id, value, callback) => {
    // call patch function
    updateCompanyHook(
      {
        name: value,
        id: id,
      },
      (response) => {
        console.log("company Updated successfully", response);
        fetchCompaniesHook((allCompanyResponse) => {
          dispatch(setCompanies([...allCompanyResponse]));

          console.log("Response company", response);
          if (selectedCompany?.id === response?.id) {
            setSelectedCompany(response);
          }
          callback();
        });
      }
    );
  };
  const updateReportName = (company, year) => {
    setTimeout(() => {
      let companyName = company?.name ? company?.name : selectedCompany?.name;
      let yearName = year?.name ? year?.name : selectedYear?.name;
      let reportName = `${companyName} ${yearName}`;

      console.log("report Name", reportName);
      setName(reportName);
    }, 300);
  };
  // const onAddFinancialYear = (financialYear) => {
  //   createFinancialYearHook(financialYear, (response) => {
  //     const newYearList = [...years];
  //     newYearList.push(response);
  //     dispatch(setYears(newYearList));
  //   });
  // };
  return (
    <ComponentModal
      open={open}
      handleClose={handleClose}
      backText="Back"
      submitText="Confirm"
      title="Save Report"
      handleOk={() => {
        createReportHook(
          {
            name,
            companyId: selectedCompany?.id,
            financialYearId: selectedYear?.id,
            companyName: selectedCompany?.name,
          },
          (response) => {
            navigate(
              `${CREATE_FINANCIAL_REPORT_URL}?reportId=${response.id}&step=1`
            );
          }
        );
      }}
    >
      <div className={"save-reports-heading-container"}>
        <div className={"save-reports-heading-title"}>Company</div>
        <ComponentDropdownPopup
          title="Select Company"
          onSelect={(item) => {
            setSelectedCompany(item);
            updateReportName(item);
          }}
          selectedItem={selectedCompany}
          list={companies}
          addText="Add as new company"
          onAdd={onAddCompany}
          showReportEdit={showReportEdit}
          updateData={updateCompany}
        />
      </div>

      <div>
        <div className={"save-reports-heading-container"}>
          <div className={"save-reports-heading-title"}>Financial Year</div>
          <ComponentDropdownPopup
            title="Select financial year"
            onSelect={(item) => {
              setSelectedYear(item);
              updateReportName(null, item);
            }}
            selectedItem={selectedYear}
            updateData={updateFiscalyear}
            list={years}
            addText="Add as new financial year"
            onAdd={onAddFinancialYear}
            showReportEdit={showReportEdit}
          />
        </div>
      </div>

      <div className={"save-reports-heading-container"}>
        <div className={"save-reports-heading-title"}>Report Name</div>
        <ComponentInput
          value={name}
          onChange={(value) => {
            setName(value);
          }}
        />
      </div>
    </ComponentModal>
  );
};

export default SaveReportModal;
