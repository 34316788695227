import { createSlice } from "@reduxjs/toolkit";
// Slice
const companySlice = createSlice({
  name: "company",
  initialState: {
    companies: [],
    companiesReports: [],
  },
  reducers: {
    setCompanies: (state, action) => {
      state.companies = action.payload;
    },
    setCompaniesWithReports: (state, action) => {
      state.companiesReports = action.payload;
    },
  },
});

export default companySlice.reducer;
// Actions
export const { setCompanies, setCompaniesWithReports } = companySlice.actions;
