import { SuggestionKeyDownProps, SuggestionProps } from "@tiptap/suggestion";
import axios from "axios";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";
import { MentionItem } from "./MentionItem";
// import type { Person } from "./types";
import { useIsMounted } from "./useIsMounted";

import ComponentModal from "../../atoms/ComponentModal";
import {
  getAccountEquationHook,
  getEquationPreviewHook,
} from "../../api-hooks/account-equation";
import { useRouter } from "../../routes";

import ComponentDropdown from "../../atoms/ComponentDropdown";
import { Button, Col, Row } from "antd";
import ComponentButton from "../../atoms/ComponentButton";
import {
  numberWithCommas,
  removeBrackets,
  removeCommasFromNumbers,
} from "../../helper";
import { getReportAccountSummaryHook } from "../../api-hooks/account";
import { groupBy } from "lodash";

import EquationItem from "../CreateEquationModal/EquationItem";

// interface MentionListProps extends SuggestionProps {}

// interface MentionListActions {
//   onKeyDown: (props: SuggestionKeyDownProps) => void;
// }
const style = {
  padding: "8px 0",
  textAlign: "center",
  fontWeight: "500",
};

export const MentionList = forwardRef(({ clientRect, command }, ref) => {
  const [open, setOpen] = useState(true);
  const router = useRouter();
  const { query } = router;
  const referenceEl = useMemo(
    () => (clientRect ? { getBoundingClientRect: clientRect } : null),
    [clientRect]
  );

  const isMounted = useIsMounted();
  const [people, setPeople] = useState([]);
  // const [equations, setEquations] = useState([]);

  const [equations, setEquations] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [yearObj, setYearObj] = useState({});
  const [currencyList, setCurrencyList] = useState([]);
  const [currencyObj, setCurrencyObj] = useState({});
  const [financialDateList, setFinancialDateList] = useState([]);
  const [financialDateObj, setFinancialDateObj] = useState({});
  const [selectedEquation, setSelectedEquation] = useState(null);
  const [itemAmountObj, setItemAmountObj] = useState({});
  const [previewObj, setPreviewObj] = useState({});
  const [yearDropdownList, setYearDropdownList] = useState([]);

  console.log("I am getting called!!", open);

  useEffect(() => {
    const yearString =
      "current:{{current|financialPositionEndDate|year}}::previous:{{previous|financialPositionEndDate|year}}";
    getEquationPreviewHook(
      { reportId: query?.reportId, content: yearString },
      (response) => {
        console.log("Equation Preview Hook", response);
        const list = response?.split("::");
        let yearList = [];
        let yearDropdownList = [];
        list?.forEach((yearData) => {
          const splittedData = yearData?.split?.(":");
          if (splittedData?.[1]) {
            const year = splittedData?.[1];
            yearList.push({
              label: `${splittedData?.[0]} year - ${year}`,
              dataValue: year,
              value: `{{${splittedData?.[0]}|financialPositionEndDate|year}}`,
              id: `${splittedData?.[0]} year`,
            });
            yearDropdownList.push({
              label: splittedData?.[0],
              value: splittedData?.[0],
            });
            yearObj[`{{${splittedData?.[0]}|financialPositionEndDate|year}}`] =
              {
                label: `${splittedData?.[0]} year - ${year}`,
                dataValue: year,
                value: `{{${splittedData?.[0]}|financialPositionEndDate|year}}`,
                id: `${splittedData?.[0]} year`,
              };
          }
        });
        setYearDropdownList(yearDropdownList);
        setYearObj(yearObj);
        setYearList(yearList);
      }
    );
    const currencyString = "currency:{{currency}}";
    getEquationPreviewHook(
      { reportId: query?.reportId, content: currencyString },
      (response) => {
        let currencyList = [];
        console.log("Equation Preview Hook", response);
        const splittedData = response?.split?.(":");
        if (splittedData?.[1]) {
          const currency = splittedData?.[1];
          currencyList.push({
            label: `Currency - ${currency}`,
            dataValue: currency,
            value: "{{currency}}",
            id: `currency`,
          });
          currencyObj["{{currency}}"] = {
            label: `Currency - ${currency}`,
            dataValue: currency,
            value: "{{currency}}",
            id: `currency`,
          };
        }
        setCurrencyObj(currencyObj);
        setCurrencyList(currencyList);
      }
    );
    const financialDateString =
      "current:{{current|financialPositionEndDate}}::previous:{{previous|financialPositionEndDate}}";
    getEquationPreviewHook(
      { reportId: query?.reportId, content: financialDateString },
      (response) => {
        console.log("Equation Preview Hook", response);
        const list = response?.split("::");
        let financialDateList = [];
        list?.forEach((yearData) => {
          const splittedData = yearData?.split?.(":");
          if (splittedData?.[1]) {
            const year = splittedData?.[1];
            financialDateList.push({
              label: `${splittedData?.[0]} Financial date - ${year}`,
              dataValue: year,
              value: `{{${splittedData?.[0]}|financialPositionEndDate}}`,
              id: `${splittedData?.[0]} financial date`,
            });
            financialDateObj[
              `{{${splittedData?.[0]}|financialPositionEndDate}}`
            ] = {
              label: `${splittedData?.[0]} Financial date - ${year}`,
              dataValue: year,
              value: `{{${splittedData?.[0]}|financialPositionEndDate}}`,
              id: `${splittedData?.[0]} financial date`,
            };
          }
        });
        setFinancialDateObj(financialDateObj);
        setFinancialDateList(financialDateList);
      }
    );
    getAccountEquationHook(
      {
        reportId: query?.reportId,
      },
      (response) => {
        console.log("Report Equation Summary", response);
        let str = "";
        // response.map(() => {
        const itemList = response;

        itemList?.forEach((item) => {
          str =
            str +
            `{{current|equation|${item.name}}}#{{previous|equation|${item.name}}}::`;
        });
        getEquationPreviewHook(
          { reportId: query?.reportId, content: str },
          (response) => {
            console.log("Equation Preview Hook", response);
            const list = response?.split("::");
            let previewList = [];
            let alllPreviewList = [];
            itemList?.forEach((item, index) => {
              const splittedData = list[index].split("#");
              previewList.push({
                ...item,
                current: splittedData[0],
                previous: splittedData[1],
                type: "equation",
                label: item?.name,
                value: item?.name,
              });
              console.log(
                " splittedData[0]?.[0]",
                splittedData[0]?.[0],
                splittedData[0]?.[0] === "("
                  ? removeBrackets(splittedData[0])
                  : ""
              );
              alllPreviewList.push({
                ...item,
                current:
                  splittedData[0]?.[0] === "("
                    ? removeCommasFromNumbers(removeBrackets(splittedData[0]))
                    : removeCommasFromNumbers(splittedData[0]),
                previous:
                  splittedData[1]?.[0] === "("
                    ? removeCommasFromNumbers(removeBrackets(splittedData[1]))
                    : removeCommasFromNumbers(splittedData[1]),
                type: "equation",
                label: item?.name,
                value: item?.name,
              });
            });
            getReportAccountSummaryHook(
              {
                reportId: query?.reportId,
              },
              (response) => {
                console.log("Account Summary", response);
                if (response) {
                  Object.keys(response)?.map((itemKey) => {
                    if (itemKey !== "null") {
                      console.log("Item Key", itemKey);
                      const childAccounts = response[itemKey];
                      const parentAccountType =
                        childAccounts[0]?.type === "income_item"
                          ? "income_header"
                          : "balance_header";
                      let previousPeriodTotal = 0;
                      let currentPeriodTotal = 0;
                      childAccounts?.forEach((childAccount) => {
                        alllPreviewList.push({
                          ...childAccount,
                          name: childAccount?.item,
                          label: childAccount?.item,
                          value: childAccount?.item,
                          current: childAccount?.currentPeriod
                            ? parseFloat(childAccount?.currentPeriod)
                            : 0,
                          previous: childAccount?.previousPeriod
                            ? parseFloat(childAccount?.previousPeriod)
                            : 0,
                        });
                        previousPeriodTotal += parseFloat(
                          childAccount?.previousPeriod
                        );
                        currentPeriodTotal += parseFloat(
                          childAccount?.currentPeriod
                        );
                      });
                      alllPreviewList.push({
                        name: itemKey,
                        type: parentAccountType,
                        current: currentPeriodTotal,
                        previous: previousPeriodTotal,
                        label: itemKey,
                        value: itemKey,
                      });
                    }
                  });
                }

                let itemAmountObj = {};
                const previewObj = groupBy(alllPreviewList, "type");
                console.log("Equation Obj", previewObj);
                const equationList = previewObj["equation"];
                itemAmountObj = getItemAcountObj(itemAmountObj, equationList);
                const incomeHeaderList = previewObj["income_header"];
                itemAmountObj = getItemAcountObj(
                  itemAmountObj,
                  incomeHeaderList
                );
                const balanceHeaderList = previewObj["balance_header"];
                itemAmountObj = getItemAcountObj(
                  itemAmountObj,
                  balanceHeaderList
                );
                const incomeItemList = previewObj["income_item"];
                itemAmountObj = getItemAcountObj(itemAmountObj, incomeItemList);
                const balanceItemList = previewObj["balance_item"];
                itemAmountObj = getItemAcountObj(
                  itemAmountObj,
                  balanceItemList
                );
                console.log("alllPreviewList", alllPreviewList);
                setPreviewObj({ ...previewObj });
                setItemAmountObj({ ...itemAmountObj });
                // setPreviewData(previewList);
              }
            );
          }
        );
      }
    );
    // getAccountEquationHook({ reportId: query?.reportId }, (response) => {
    //   console.log("All Equations", response);
    //   let content =
    //     "currency#currency:{{currency}}::currentfinancialDate#current|financialPositionEndDate:{{current|financialPositionEndDate}}::previousFinancialDate#previous|financialPositionEndDate:{{previous|financialPositionEndDate}}::currentYear#current|financialPositionEndDate|year:{{current|financialPositionEndDate|year}}::previousYear#previous|financialPositionEndDate|year:{{previous|financialPositionEndDate|year}}::";
    //   response?.forEach((data) => {
    //     content =
    //       content +
    //       `Current ${data?.name}#current|equation|${data?.name}:{{current|equation|${data?.name}}}::Previous ${data?.name}#previous|equation|${data?.name}:{{previous|equation|${data?.name}}}::`;
    //   });
    //   getEquationPreviewHook(
    //     { reportId: query?.reportId, content: content },
    //     (response) => {
    //       console.log("Equation Preview Response", response);
    //       let equations = [];
    //       let list = [];
    //       list = response?.split("::");
    //       console.log("List", list);
    //       list?.forEach((item, index) => {
    //         const spliitedText = item?.split(":");
    //         const nameAndEquation = spliitedText?.[0]?.split("#");
    //         if (index !== list?.length - 1)
    //           equations.push({
    //             id: nameAndEquation[0],
    //             title: nameAndEquation[0],
    //             equation: `{{${nameAndEquation[1]}}}`,
    //             value: spliitedText[1],
    //           });
    //       });

    //       console.log("Final Equations", equations);
    //       setEquations([...equations]);
    //     }
    //   );
    // });
  }, [isMounted]);
  console.log("Equations 123", equations);

  const getItemAcountObj = (amountObj, list) => {
    list?.forEach((item) => {
      amountObj[item?.name] = {
        current: item?.current,
        previous: item.previous,
      };
    });
    return amountObj;
  };

  const handleCommand = (index) => {
    // const selectedEquation = equations[index];
    console.log("Selected Equation Index", selectedEquation);
    if (selectedEquationType === "equation") {
      let equation = "";
      if (
        selectedEquation?.year &&
        selectedEquation?.equationType &&
        selectedEquation?.value
      ) {
        equation = `{{${selectedEquation?.year}|${selectedEquation?.equationType}|${selectedEquation?.value}}}`;
      } else if (selectedEquation?.equationType && selectedEquation?.value) {
        equation = `{{${selectedEquation?.equationType}|${selectedEquation?.value}}}`;
      }
      if (equation) {
        command({
          id: selectedEquation?.value,
          label: numberWithCommas(
            itemAmountObj[selectedEquation?.value][
              selectedEquation?.year || "current"
            ]
          ),
          value: equation,
        });
      }
    } else {
      command({
        id: selectedEquation.id,
        label: selectedEquation.value,
        value: selectedEquation.equation,
      });
    }

    setOpen(false);
  };

  const [hoverIndex, setHoverIndex] = useState(0);
  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      const { key } = event;

      if (key === "ArrowUp") {
        setHoverIndex((prev) => {
          const beforeIndex = prev - 1;
          return beforeIndex >= 0 ? beforeIndex : 0;
        });
        return true;
      }

      if (key === "ArrowDown") {
        setHoverIndex((prev) => {
          const afterIndex = prev + 1;
          const peopleCount = people.length - 1 ?? 0;
          return afterIndex < peopleCount ? afterIndex : peopleCount;
        });
        return true;
      }

      if (key === "Enter") {
        handleCommand(hoverIndex);
        return true;
      }

      return false;
    },
  }));

  const [el, setEl] = useState(null);
  // const { styles, attributes } = usePopper(referenceEl, el, {
  //   placement: "bottom-start",
  // });

  const [selectedEquationType, setSelectedEquationType] = useState("");
  console.log("selectedEquationType", selectedEquationType);

  const onUpdateEquationItemValue = (index, key, value) => {
    // if (!selectedEquation) {
    //   selectedEquation = {};
    // }
    selectedEquation[key] = value;
    console.log("Selected Equation ****", selectedEquation);
    setSelectedEquation({ ...selectedEquation });
  };
  return (
    <ComponentModal
      open={open}
      handleClose={() => {
        setOpen(false);
      }}
      title="Select Equation"
      width={832}
      footer={null}
      // handleOk={onAddEquation}
    >
      <div>
        <div
          style={{ marginBottom: "5px", fontSize: "12px", fontWeight: "500" }}
        >
          Equation Type
        </div>
        <ComponentDropdown
          optionList={[
            {
              label: "Currency",
              value: "currency",
            },
            {
              label: "Financial date",
              value: "financial_date",
            },
            {
              label: "Year",
              value: "year",
            },
            {
              label: "Equation",
              value: "equation",
            },
          ]}
          style={{ minWidth: "200px" }}
          handleChange={(value) => {
            setSelectedEquationType(value);
            let selectedEquation = {};
            if (value === "currency") {
              selectedEquation = {
                id: currencyList[0]?.id,
                equation: currencyList[0]?.value,
                title: currencyList[0]?.title,
                value: currencyList[0]?.dataValue,
              };
            } else if (value === "financial_date") {
              selectedEquation = {
                id: financialDateList[0]?.id,
                equation: financialDateList[0]?.value,
                title: financialDateList[0]?.title,
                value: financialDateList[0]?.dataValue,
              };
            } else if (value === "year") {
              selectedEquation = {
                id: yearList[0]?.id,
                equation: yearList[0]?.value,
                title: yearList[0]?.title,
                value: yearList[0]?.dataValue,
              };
            } else if (value === "equation") {
            }
            setSelectedEquation(selectedEquation);
          }}
          value={selectedEquationType}
        />
        <div style={{ maxHeight: "150px", minHeight: "150px" }}>
          {selectedEquationType === "currency" && (
            <>
              <div
                style={{
                  backgroundColor: "#FAFBFF",
                  borderBottom: "1.5px solid #E6E8F0",
                  marginTop: "20px",
                  marginBottom: "10px",
                  padding: "0px 20px",
                }}
              >
                <Row gutter={16}>
                  <Col className="gutter-row" span={6}>
                    <div style={style}>Currency</div>
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <div style={style}></div>
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <div style={style}></div>
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <div style={style}></div>
                  </Col>
                </Row>
              </div>
              <div>
                <Row gutter={16}>
                  <Col className="gutter-row" span={6}>
                    <div style={{ ...style, display: "flex" }}>
                      <ComponentDropdown
                        style={{ width: "100%", fontSize: "12px" }}
                        optionList={currencyList}
                        value={selectedEquation?.equation}
                        handleChange={(value) => {
                          const selectedEquation = {
                            id: currencyObj[value]?.id,
                            equation: value,
                            title: currencyObj[value]?.title,
                            value: currencyObj[value]?.dataValue,
                          };
                          setSelectedEquation(selectedEquation);
                        }}
                      />
                    </div>
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <div style={style}> </div>
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <div style={style}></div>
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <div
                      style={{
                        ...style,
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      {selectedEquation?.value}
                      {/* <div style={{ flex: "1 1 auto", textAlign: "center" }}>
                      {item?.value &&
                        itemAmountObj?.[item?.value]?.[item?.year || "current"]}
                    </div>
                    <TrashIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        onDeleteEquationItem(indexValue);
                      }}
                    /> */}
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          )}
          {selectedEquationType === "financial_date" && (
            <>
              <div
                style={{
                  backgroundColor: "#FAFBFF",
                  borderBottom: "1.5px solid #E6E8F0",
                  marginTop: "20px",
                  marginBottom: "10px",
                  padding: "0px 20px",
                }}
              >
                <Row gutter={16}>
                  <Col className="gutter-row" span={6}>
                    <div style={style}>Date</div>
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <div style={style}></div>
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <div style={style}></div>
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <div style={style}></div>
                  </Col>
                </Row>
              </div>
              <div>
                <Row gutter={16}>
                  <Col className="gutter-row" span={6}>
                    <div style={{ ...style, display: "flex" }}>
                      <ComponentDropdown
                        style={{ width: "100%", fontSize: "12px" }}
                        optionList={financialDateList}
                        value={selectedEquation?.equation}
                        handleChange={(value) => {
                          const selectedEquation = {
                            id: financialDateObj[value]?.id,
                            equation: value,
                            title: financialDateObj[value]?.title,
                            value: financialDateObj[value]?.dataValue,
                          };
                          setSelectedEquation(selectedEquation);
                        }}
                      />
                    </div>
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <div style={style}> </div>
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <div style={style}></div>
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <div
                      style={{
                        ...style,
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      {selectedEquation?.value}
                      {/* <div style={{ flex: "1 1 auto", textAlign: "center" }}>
                     {item?.value &&
                       itemAmountObj?.[item?.value]?.[item?.year || "current"]}
                   </div>
                   <TrashIcon
                     style={{ cursor: "pointer" }}
                     onClick={() => {
                       onDeleteEquationItem(indexValue);
                     }}
                   /> */}
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          )}
          {selectedEquationType === "year" && (
            <>
              <div
                style={{
                  backgroundColor: "#FAFBFF",
                  borderBottom: "1.5px solid #E6E8F0",
                  marginTop: "20px",
                  marginBottom: "10px",
                  padding: "0px 20px",
                }}
              >
                <Row gutter={16}>
                  <Col className="gutter-row" span={6}>
                    <div style={style}>Year</div>
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <div style={style}></div>
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <div style={style}></div>
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <div style={style}></div>
                  </Col>
                </Row>
              </div>
              <div>
                <Row gutter={16}>
                  <Col className="gutter-row" span={6}>
                    <div style={{ ...style, display: "flex" }}>
                      <ComponentDropdown
                        style={{ width: "100%", fontSize: "12px" }}
                        optionList={yearList}
                        value={selectedEquation?.equation}
                        handleChange={(value) => {
                          const selectedEquation = {
                            id: yearObj[value]?.id,
                            equation: value,
                            title: yearObj[value]?.title,
                            value: yearObj[value]?.dataValue,
                          };
                          setSelectedEquation(selectedEquation);
                        }}
                      />
                    </div>
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <div style={style}> </div>
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <div style={style}></div>
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <div
                      style={{
                        ...style,
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      {selectedEquation?.value}
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          )}
          {selectedEquationType === "equation" && (
            <>
              <div
                style={{
                  backgroundColor: "#FAFBFF",
                  borderBottom: "1.5px solid #E6E8F0",
                  marginTop: "20px",
                  marginBottom: "10px",
                  padding: "0px 20px",
                }}
              >
                <Row gutter={16}>
                  <Col className="gutter-row" span={6}>
                    <div style={style}>Year</div>
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <div style={style}>Type</div>
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <div style={style}>Name/Code</div>
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <div style={style}>Amount</div>
                  </Col>
                </Row>
              </div>
              <div>
                <EquationItem
                  yearList={yearDropdownList}
                  previewObj={previewObj}
                  itemAmountObj={itemAmountObj}
                  hideTrash
                  hideDrag
                  // onDeleteEquationItem={onDeleteEquationItem}
                  // indexValue={indexValue}
                  onUpdateEquationItemValue={onUpdateEquationItemValue}
                  item={selectedEquation}
                />
              </div>
            </>
          )}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            marginTop: "20px",
          }}
        >
          <ComponentButton title="Insert" onClick={handleCommand} />
          <Button title="Back" style={{ marginRight: "15px" }}>
            Back
          </Button>
        </div>
        {equations.map((equation, index) => (
          <MentionItem
            key={equation.id}
            isActive={index === hoverIndex}
            onMouseEnter={() => setHoverIndex(index)}
            onClick={() => handleCommand(index)}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "60px",
                padding: "15px",
                backgroundColor: "#f7f7f7",
                borderRadius: "7px",
                marginBottom: "8px",
                cursor: "pointer",
              }}
            >
              <div
                style={{ flex: "1 1", minWidth: "250px", maxWidth: "250px" }}
              >
                {equation.title}
              </div>
              <div
                style={{ flex: "1 1", minWidth: "300px", maxWidth: "300px" }}
              >
                {equation.equation}
              </div>
              <div style={{ flex: "1 1", marginLeft: "20px" }}>
                {equation.value}
              </div>
            </div>
          </MentionItem>
        ))}
      </div>
    </ComponentModal>
  );

  // return createPortal(
  //   <div
  //     ref={setEl}
  //     className="mentionsContainer"
  //     style={styles.popper}
  //     {...attributes.popper}
  //   >
  //     {people.map((person, index) => (
  //       <MentionItem
  //         key={person.url}
  //         isActive={index === hoverIndex}
  //         onMouseEnter={() => setHoverIndex(index)}
  //         onClick={() => handleCommand(index)}
  //       >
  //         {person.name}
  //       </MentionItem>
  //     ))}
  //   </div>,
  //   document.body
  // );
});
