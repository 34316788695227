import React from "react";

import { Popover } from "antd";

import "./ComponentPopup.css";

const ComponentPopup = ({
  open,
  handleClose,
  trigger,
  children,
  title,
  className,
  on,
  arrowPointAtCenter,
  placement,
}) => {
  return (
    <Popover
      content={children}
      title={title}
      trigger={on || "click"}
      visible={open}
      placement={placement || "bottom"}
      overlayClassName={className}
      arrowPointAtCenter={arrowPointAtCenter}
      onVisibleChange={(visible) => {
        if (!visible) {
          handleClose();
        }
      }}
    >
      {trigger}
    </Popover>
  );
};

export default ComponentPopup;
