import React from "react";
import ComponentButton from "../../atoms/ComponentButton";
import MappingLoading from "../../sub-components/MappingLoading";
import "./balance.less";
// import newTemplateIcon from "../images/duplicate.png";
import { useIncome } from "./incomeHook";
import TableBalance from "./table-balance";

const fileAttributes = {
  fileType: "Income Sheet",
};

const IncomeSheet = ({
  selectedStatement,
  balanceSheetData,
  incomeSheetData,
  checkedData,
  setIsBalanceMapped,
  setIsIncomeMapped,
  isLoading,
  setIsSheetPreviewEnabled,
  templateName,
  templateId,
  isBalaceMapped,
  isIncomeMapped,
  onCreateNewTemplate,
  accountListObj,
  removeMapping,
  setIsMappingChanged,
  isMappingChanged,
  saveAccountTemplate,
  updateAllData,
  setShowCategoryDataModal,
}) => {
  const { handleRowCheck, tableRowsData, tableHeaders, handleFSItemChange } =
    useIncome(selectedStatement, incomeSheetData, checkedData);

  console.log("Table Row Data", tableRowsData);

  return (
    <section className={"right-comp2"}>
      <header className={"section-header"}>
        <h2>Map Items</h2>
        <div className={"header-right"}>
          <button
            style={{ opacity: isMappingChanged ? "1" : "0.5" }}
            onClick={() => {
              if (isMappingChanged) {
                updateAllData((response) => {
                  if(response?.length){
                    setShowCategoryDataModal(true);
                    console.log("Mapping is Incompleted")
                  } else { onCreateNewTemplate();
                  }
                })
              }
              
            }}
          >
            <span>{"Save as New Template"}</span>
          </button>
          {templateId && (
            <button
              style={{ opacity: isMappingChanged && templateId ? "1" : "0.5" }}
              onClick={() => {
                if (isMappingChanged && templateId) {
                  saveAccountTemplate();
                }
              }}
            >
              <span>{"Save Template"}</span>
            </button>
          )}

          <ComponentButton
            title="Preview"
            onClick={() => {
              setIsSheetPreviewEnabled(true);
            }}
          ></ComponentButton>
        </div>
      </header>
      <main className={"section-body"}>
        <div className={"file-attributes"}>
          <div
            className={"each-attribute"}
            style={{ fontSize: "10px" }}
          >{`Statement: ${fileAttributes.fileType}`}</div>
          <div className={"each-attribute"} style={{ fontSize: "10px" }}>
            <span>Mapping template applied: {templateName || "NA"}</span>
            {templateId && (
              <span
                style={{
                  marginLeft: "5px",
                  textDecoration: "underline",
                  color: "#4BB354",
                  cursor: "pointer",
                }}
                onClick={removeMapping}
              >
                Remove
              </span>
            )}
          </div>
        </div>
        {isLoading && <MappingLoading />}
        {!isLoading && (
          <TableBalance
            tableHeaders={tableHeaders}
            tableRowsData={tableRowsData}
            handleRowCheck={handleRowCheck}
            handleFSItemChange={handleFSItemChange}
            type="income_header"
            setIsBalanceMapped={setIsBalanceMapped}
            setIsIncomeMapped={setIsIncomeMapped}
            accountListObj={accountListObj}
            setIsMappingChanged={setIsMappingChanged}
            isMappingChanged={isMappingChanged}
            templateId={templateId}
          />
        )}
      </main>
    </section>
  );
};

export default IncomeSheet;
