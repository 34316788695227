import React, { useEffect, useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import ComponentPopupWithSearch from "../../atoms/ComponentPopupWithSearch";

//  import { suggestionsList, categorySuggestionList } from "../../dataHelper";
import {
  fetchCategoriesHook,
  fetchItemsHook,
  saveCategoriesHook,
  saveCategoryItemHook,
  updateCategoriesHook,
  updateCategoryItemHook ,
} from "../../api-hooks/categories";
import { useRouter } from "../../routes";
import { createAccountMappingHook } from "../../api-hooks/account";
import { getAccountByType } from "../../api-hooks/report";
const TableBalanceItem = ({
  colItems,
  cellVal,
  getDataClass,
  handleFSItemChange,
  rowIndex,
  colInx,
  type,
  handleRowCheck,
  setIsBalanceMapped,
  setIsIncomeMapped,
  accountListObj,
  isShowAllAccount,
  setIsMappingChanged,
}) => {
  const router = useRouter();
  const { query } = router;

  const [isPopupOpened, setIsPopupOpened] = useState(false);
  const [showReportEdit,setShowReportEdit] = useState(true);
  const [suggestionList, setSuggestionList] = useState([]);

  const [categorySuggestionList, setCategorySuggestionList] = useState([]);
  let itemType = "balance_item";
  if (type === "income_header") {
    itemType = "income_item";
  }
  const getColumnCondition = (colInxValue) => {
    return colInx === colInxValue;
  };
  console.log("categorySuggestionList",cellVal);
  const showUpdatedData = (itemId, value, callback) =>{

    console.log("show updated data", itemId, value, getColumnCondition(2), getColumnCondition(3));
    if(getColumnCondition(2)){
      updateCategoriesHook(
        {
          name:value,
          id:itemId,
        },
        (response)=>{
          console.log("response category data",response);
          let updatedIndex = 0;
          categorySuggestionList.map((item,itemIndex)=>{
            if(item.id === itemId){
              updatedIndex = itemIndex
            }
          })
          categorySuggestionList[updatedIndex].name = value;
          setCategorySuggestionList([...categorySuggestionList]);
          cellVal.name = value;
          callback();
        }
      )
    }
    if(getColumnCondition(3)){
      updateCategoryItemHook (
        {
          name:value,
          id:itemId,
        },
        (response)=>{
          console.log("response category data",response);
          let updatedIndex = 0;
          suggestionList.map((item,itemIndex)=>{
            if(item.id === itemId){
              updatedIndex = itemIndex
            }
          })
          suggestionList[updatedIndex].name = value;
          setSuggestionList([...suggestionList]);
          callback();
        }
      )
    }

  }
  useEffect(() => {
    if (isPopupOpened) {
      if (getColumnCondition(2)) {
        fetchCategoriesHook(query?.reportId, type, (response) => {
          console.log("Category List", response);
          if (response?.length) {
            let categoryList = [];

            response?.forEach((item) => {
              categoryList.push({
                id: item?.id,
                name: item?.name,
                percentage: "0%",
                confidence: 0,
                children: item?.children,
              });
            });
            setCategorySuggestionList(categoryList);
          }
        });
      }
    }
  }, [colInx, type, rowIndex, isPopupOpened]);

  useEffect(() => {
    if (isPopupOpened && getColumnCondition(3)) {
      // if (colItems[2]?.children?.length) {
      //   colItems[2]?.children?.map((item) => {
      //     suggestionList.push({
      //       id: item?.id,
      //       name: item?.name,
      //       percentage: "0%",
      //       confidence: 0,
      //     });
      //   });
      //   setSuggestionList([...suggestionList]);
      // } else if (colItems[2]?.id) {
      fetchItemsHook(query?.reportId, itemType, colItems[2]?.id, (response) => {
        if (response?.length) {
          let updatedSuggestionList = [];
          response?.map((item) => {
            updatedSuggestionList.push({
              id: item?.id,
              name: item?.name,
              percentage: "0%",
              confidence: 0,
            });
          });
          setSuggestionList([...updatedSuggestionList]);
        }
      });
      // }
    }
  }, [rowIndex, colInx, isPopupOpened]);

  const createMapping = (categoryId) => {
    createAccountMappingHook(
      {
        categoryId: categoryId,
        accountId: colItems[1].accountId,
      },
      (response) => {
        console.log("Save Mapping response", response);
        getAccountByType(
          {
            reportId: query?.reportId,
            type: "balance_item",
          },
          (response) => {
            if (response?.length) {
              setIsBalanceMapped(true);
            } else {
              setIsBalanceMapped(false);
            }
          }
        );
        getAccountByType(
          {
            reportId: query?.reportId,
            type: "income_item",
          },
          (response) => {
            if (response?.length) {
              setIsIncomeMapped(true);
            } else {
              setIsIncomeMapped(false);
            }
          }
        );
      }
    );
    // second Item
    createAccountMappingHook(
      {
        categoryId: categoryId,
        accountId: accountListObj[colItems[1].accountId],
      },
      (response) => {
        console.log("Save Mapping response", response);
        setIsMappingChanged(true);
        getAccountByType(
          {
            reportId: query?.reportId,
            type: "balance_item",
          },
          (response) => {
            if (response?.length) {
              setIsBalanceMapped(true);
            } else {
              setIsBalanceMapped(false);
            }
          }
        );
        getAccountByType(
          {
            reportId: query?.reportId,
            type: "income_item",
          },
          (response) => {
            if (response?.length) {
              setIsIncomeMapped(true);
            } else {
              setIsIncomeMapped(false);
            }
          }
        );
      }
    );
  };

  return (
    <ComponentPopupWithSearch
      updateData={showUpdatedData}
      showReportEdit={showReportEdit}
      trigger={
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={() => {
            if (!isShowAllAccount) {
              setIsPopupOpened(true);
            }
          }}
        >
          <div className={getDataClass()} style={{ flex: "1 1 auto" }}>
            <span
              style={{
                whiteSpace: "noWrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {!isShowAllAccount && (
                <>{colItems[0].isChecked ? cellVal.name : "NA"}</>
              )}
              {isShowAllAccount && cellVal.name}
            </span>
          </div>
          <DownOutlined
            style={{
              marginLeft: "10px",
              color: "#8F95B2",
              fontSize: "12px",
            }}
          />
        </div>
      }
      handleClose={() => {
        console.log("On Handle close");
        setIsPopupOpened(false);
      }}
      onSelect={(value) => {
        console.log("Category Value", value);

        if (getColumnCondition(2)) {
          console.log("colItems***", colItems);
          if (!colItems[0]?.isChecked) {
            handleRowCheck(rowIndex);
          }
        }
        if (getColumnCondition(3)) {
          createMapping(value.id);
        }

        handleFSItemChange(rowIndex, colInx, {
          id: value.id,
          name: value.name,
          confidence: value.confidence,
          children: value?.children,
        });
        if (getColumnCondition(2) && value?.children?.length) {
          createMapping(value?.children?.[0]?.id);
        }

        setIsPopupOpened(false);
      }}
      onAdd={(value) => {
        if (getColumnCondition(2)) {
          saveCategoriesHook(
            {
              type: type,
              index: rowIndex,
              name: value,
              reportId: query?.reportId,
            },
            (response) => {
              console.log("SAved Category", response);
              const newCategory = {
                id: response?.id,
                name: value,
                percentage: 0,
                confidence: 0,
                children: [],
              };
              categorySuggestionList.push(newCategory);
              console.log("Final Category List", categorySuggestionList);
              setCategorySuggestionList([...categorySuggestionList]);
              // handleRowCheck(rowIndex);
              handleFSItemChange(rowIndex, colInx, {
                id: response?.id,
                name: value,
                confidence: 0,
                children: [],
              });
              setIsPopupOpened(false);
            }
          );
        } else if (getColumnCondition(3)) {
          console.log("Selected CategoryId", colItems[2]);
          saveCategoryItemHook(
            {
              type: itemType,
              index: rowIndex,
              name: value,
              reportId: query?.reportId,
              parentId: colItems[2]?.id,
            },
            (response) => {
              console.log("Save Category Item Response", response);
              const newItem = {
                id: response?.id,
                name: value,
                percentage: 0,
                confidence: 0,
              };
              suggestionList.push(newItem);
              console.log("Final Suggestion List", suggestionList);
              setSuggestionList([...suggestionList]);

              handleFSItemChange(rowIndex, colInx, {
                id: response?.id,
                name: value,
                confidence: 0,
              });

              createMapping(response?.id);

              // createAccountMappingHook(
              //   {
              //     categoryId: response?.id,
              //     accountId: colItems[1].accountId,
              //   },
              //   (response) => {
              //     console.log("Save Mapping response", response);
              //   }
              // );
              setIsPopupOpened(false);
            }
          );
        }
      }}
      open={isPopupOpened}
      list={getColumnCondition(2) ? categorySuggestionList : suggestionList}
      addText="Add as New Item"
    />
  );
};

export default TableBalanceItem;
