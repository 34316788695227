import "./Sidebar.less";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import expandIcon from "../../assets/svg-icons/expandSidebar.svg";
import ComponentSearch from "../../atoms/ComponentSearch";
import ComponentInput from "../../atoms/ComponentInput";

import accountingIcon from "../../assets/svg-icons/accounting.svg";
import { ReactComponent as FinancialReportIcon } from "../../assets/svg-icons/financialReport.svg";
import { ReactComponent as ReportDashboardIcon } from "../../assets/svg-icons/reportDashboard.svg";
import { ReactComponent as ReportDriveIcon } from "../../assets/svg-icons/folder.svg";
import { FileOutlined } from "@ant-design/icons";

import { SearchOutlined } from "@ant-design/icons";
import {
  FINANCIAL_REPORT_URL,
  NOTES_TEMPLATE_URL,
  REPORT_DASHBOARD_URL,
  REPORT_DRIVE_URL,
  useRouter,
} from "../../routes";
import { useMemo, useState } from "react";
import ComponentPopup from "../../atoms/ComponentPopup";
import PopupItem from "../../atoms/ComponentPopup/PopupItem";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { changeCollapsedState } from "../../store/user";

const { SubMenu } = Menu;

const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const router = useRouter();

  const { pathname } = router;
  // const [isCollapsed, setIsCollapsed] = useState(false);

  const isCollapsed = useSelector((state) => state.user.isCollapsed);

  const containerStyle = useMemo(() => {
    return isCollapsed
      ? {
          width: "100px",
          minWidth: "100px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }
      : { width: "300px", minWidth: "300px" };
  }, [isCollapsed]);

  const expandIconStyle = useMemo(() => {
    return isCollapsed ? { transform: "rotate(180deg)" } : {};
  }, [isCollapsed]);

  return (
    <div className="sidebar-container" style={containerStyle}>
      <img
        src={expandIcon}
        alt="expand"
        style={{ cursor: "pointer", ...expandIconStyle }}
        onClick={() => {
          dispatch(changeCollapsedState(!isCollapsed));
          // setIsCollapsed(!isCollapsed);
        }}
      />
      {isCollapsed && (
        <div className="sidebar-search-container">
          <div>
            <SearchOutlined
              color="#DADADA"
              style={{
                color: "#8F95B2",
                width: "20px",
                height: "20px",
                fontSize: "20px",
              }}
            />
          </div>

          <ComponentPopup
            trigger={
              <div style={{ marginTop: "35px", cursor: "pointer" }}>
                <img src={accountingIcon} alt="accountingIcon" />{" "}
              </div>
            }
            on="hover"
            arrowPointAtCenter={true}
            placemen="bottomRight"
          >
            <div>
              {/* <Link to={REPORT_DASHBOARD_URL}> */}
              <PopupItem
                name="Report Dashboard"
                Icon={ReportDashboardIcon}
                onClick={() => {
                  navigate(REPORT_DASHBOARD_URL);
                }}
                isSelected={pathname === REPORT_DASHBOARD_URL}
              />
              {/* </Link> */}
              {/* <Link to={FINANCIAL_REPORT_URL}> */}
              <PopupItem
                name="Financial Report Design"
                Icon={FinancialReportIcon}
                onClick={() => {
                  navigate(FINANCIAL_REPORT_URL);
                }}
                isSelected={
                  pathname === FINANCIAL_REPORT_URL ||
                  pathname === FINANCIAL_REPORT_URL + "/create" ||
                  pathname === "/"
                }
              />
              {/* </Link> */}
            </div>
          </ComponentPopup>
        </div>
      )}
      {!isCollapsed && (
        <div className="sidebar-search-container">
          <ComponentInput
            placeholder="Search"
            prefix={
              <SearchOutlined
                color="#DADADA"
                style={{ marginRight: "10px", color: "#8F95B2" }}
              />
            }
            size="large"
          />

          <Menu
            // onClick={onClick}
            // defaultSelectedKeys={["1"]}
            defaultOpenKeys={["accounting"]}
            mode="inline"
            style={{ marginTop: "35px" }}
            // items={items}
          >
            <SubMenu
              style={{
                color: "#4A4A4A",
                fontSize: 16,
                paddingLeft: "0px",
              }}
              key="accounting"
              title="Accounting"
              icon={<img src={accountingIcon} alt="accountingIcon" />}
              //   style={{ paddingLeft: "0px" }}
            >
              {/* <Link to={REPORT_DASHBOARD_URL}> */}
              {/* <Menu.Item
                icon={<ReportDashboardIcon />}
                style={{
                  paddingLeft: "20px",
                  color: pathname === REPORT_DASHBOARD_URL ? "#4bb354" : "",
                }}
                onClick={() => {
                  navigate(REPORT_DASHBOARD_URL);
                }}
                className={
                  pathname === REPORT_DASHBOARD_URL
                    ? "sidebar-menu-item-selected"
                    : ""
                }
              >
                Report Dashboard
              </Menu.Item> */}
              {/* </Link> */}
              {/* <Link to={FINANCIAL_REPORT_URL}> */}
              <Menu.Item
                icon={<FinancialReportIcon />}
                style={{
                  paddingLeft: "20px",
                  color:
                    pathname === FINANCIAL_REPORT_URL ||
                    pathname === FINANCIAL_REPORT_URL + "/create" ||
                    pathname === "/"
                      ? "#4bb354"
                      : "",
                }}
                onClick={() => {
                  navigate(FINANCIAL_REPORT_URL);
                }}
                className={
                  pathname === FINANCIAL_REPORT_URL ||
                  pathname === FINANCIAL_REPORT_URL + "/create" ||
                  pathname === "/"
                    ? "sidebar-menu-item-selected"
                    : ""
                }
              >
                Financial Report Design
              </Menu.Item>
              {/* </Link> */}
              {/* Note template */}
              <Menu.Item
                icon={
                  <FileOutlined style={{ width: "16px", height: "16px" }} />
                }
                style={{
                  paddingLeft: "20px",
                  color: pathname === NOTES_TEMPLATE_URL ? "#4bb354" : "",
                }}
                onClick={() => {
                  navigate(NOTES_TEMPLATE_URL);
                }}
                className={
                  pathname === NOTES_TEMPLATE_URL
                    ? "sidebar-menu-item-selected"
                    : ""
                }
              >
                Note Template
              </Menu.Item>
              {/* Note template Ends */}
              {/* Report Drive Starts */}
              <Menu.Item
                icon={
                  <ReportDriveIcon style={{ width: "16px", height: "16px" }} />
                }
                style={{
                  paddingLeft: "20px",
                  color: pathname === REPORT_DRIVE_URL ? "#4bb354" : "",
                }}
                onClick={() => {
                  navigate(REPORT_DRIVE_URL);
                }}
                className={
                  pathname === REPORT_DRIVE_URL
                    ? "sidebar-menu-item-selected"
                    : ""
                }
              >
                Report Drive
              </Menu.Item>
              {/* Report Drive Ends */}
            </SubMenu>
          </Menu>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
