import ContentCard from "../../atoms/ContentCard";
import LeftControl from "../../sub-components/LeftControl";
import MainContainer from "../../sub-components/MainContainer";
import RightControl from "../../sub-components/RightControl";
import { useEffect, useState } from "react";
import { fetchComapnyWithReportsHook } from "../../api-hooks/company";
import { useDispatch, useSelector } from "react-redux";
import { setCompaniesWithReports } from "../../store/company";
import ExpandableGroup from "./ExpandableGroup";
import { ReactComponent as UploadDocFile } from "../../assets/svg-icons/UploadDocFile.svg";
import { ReactComponent as DownloadIcon } from "../../assets/svg-icons/DownloadIcon.svg";
import { ReactComponent as TrashIcon } from "../../assets/svg-icons/trashIcon.svg";
import { ReactComponent as EditIcon } from "../../assets/svg-icons/editIcon.svg";
import EditReportModal from "../../sub-components/EditReportModal";
import DeleteReportFileModal from "../../sub-components/DeleteReportFileModal";
import "./ReportDrive.less";
import { getReportFile } from "../../api-hooks/report";

// import "./NoteTemplate.less";
const ReportDrive = ({}) => {
  const dispatch = useDispatch();
  const [refreshKey, setRefreshKey] = useState("");
  const [reportDisplay, setReportDisplay] = useState(false);
  const [selectReportItem, setSelectedReportItem] = useState({});
  const [editReportItemName, setEditReportItemName] = useState([]);
  const [editReportModal, setEditReportModal] = useState(false);
  const [deleteReportModal, setDeleteReportModal] = useState(false);
  // const navigate = useNavigate();
  const companiesWithReports = useSelector(
    (state) => state.company.companiesReports
  );
  const closeEditDialogue = () => {
    setEditReportModal(false);
  };
  const closeReportFileDialogue = () => {
    setDeleteReportModal(false);
  };
  const fetchData = () => {
    fetchComapnyWithReportsHook((response) => {
      if (response) {
        dispatch(setCompaniesWithReports(response));
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, [dispatch]);

  const onDownload = () => {
    if (selectReportItem && selectReportItem?.id) {
      getReportFile(selectReportItem?.id, (response) => {
        if (response) {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(new Blob([response.data]));
          link.setAttribute("download", `${selectReportItem?.id}.doc`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      });
    }
  };

  return (
    <MainContainer>
      <div className="noteTemplateBreadrumb">
        <p>Home</p>
        <p>/</p>
        <p>Accounting</p>
        <p>/</p>
        <p>
          <b>Report Drive</b>
        </p>
      </div>
      <div className="finacial-report-container report-drive-height-setter">
        <LeftControl>
          <ContentCard
            style={{ padding: 32, height: "100%", background: "none" }}
            bodyStyle={{ padding: 0 }}
            size="large"
          >
            <div className={"saved-cards-heading"}>Report Drive</div>
            <div>
              <div
                style={{
                  marginTop: "45px",
                  overflow: "auto",
                  height: "calc(100vh - 234px)",
                }}
              >
                {companiesWithReports?.map((item, index) => {
                  const onSelect = (report) => {
                    console.log("reportId", report);
                    setSelectedReportItem(report);
                    setRefreshKey(Math.random());
                  };
                  return (
                    <ExpandableGroup
                      fetchData={fetchData}
                      item={item}
                      key={index}
                      onSelect={onSelect}
                      setReportDisplay={setReportDisplay}
                    />
                  );
                })}
              </div>
            </div>
          </ContentCard>
        </LeftControl>
        <RightControl isPreview>
          <ContentCard
            style={{ padding: "32px", height: "100%" }}
            bodyStyle={{
              padding: 0,
              height: "inherit",
              display: "flex",
              flexDirection: "column",
            }}
            size="large"
          >
            {reportDisplay && (
              <div className="after-upload-view">
                <div className={"saved-cards-heading"}>File Details</div>
                <>
                  <div>
                    <div className="report-title">Name</div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "12px",
                      }}
                    >
                      <div>{selectReportItem?.name}</div>
                      <EditIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setEditReportModal(true);
                        }}
                      />
                    </div>
                    <div className="report-title">Type</div>
                    <div>{selectReportItem?.type}</div>
                    <div className="report-title">Size</div>
                    <div>{(selectReportItem?.size / 1000).toFixed(2)}KB</div>
                    <div className="report-title">Created</div>
                    <div>{selectReportItem?.created}</div>
                    <div className="report-download-btn" onClick={onDownload}>
                      <DownloadIcon />
                      Download
                    </div>
                    <div
                      className="report-delete-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setDeleteReportModal(true);
                      }}
                    >
                      <TrashIcon />
                      Delete
                    </div>
                  </div>
                </>
              </div>
            )}
            {!reportDisplay && (
              <div className="before-upload-view">
                <UploadDocFile />
                <span>Upload Doc</span>
              </div>
            )}
          </ContentCard>
        </RightControl>
        {editReportModal && (
          <EditReportModal
            open={editReportModal}
            fetchData={fetchData}
            setSelectedReportItem={setSelectedReportItem}
            selectedItem={selectReportItem}
            handleClose={closeEditDialogue}
            setEditReportItemName={setEditReportItemName}
            editReportItemName={editReportItemName}
          />
        )}
        {deleteReportModal && (
          <DeleteReportFileModal
            fetchData={fetchData}
            selectedId={selectReportItem?.id}
            open={deleteReportModal}
            handleClose={closeReportFileDialogue}
          />
        )}
      </div>
    </MainContainer>
  );
};

export default ReportDrive;
