import React from "react";
import { Card } from "antd";

const ContentCard = ({ children, style, ...props }) => (
  <Card
    style={{
      // minHeight: "calc(100vh - 315px)",
      ...style,
    }}
    bordered={false}
    {...props}
  >
    {children}
  </Card>
);

export default ContentCard;
