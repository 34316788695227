import ComponentModal from "../../atoms/ComponentModal";
import ComponentInput from "../../atoms/ComponentInput";

import "./SaveTemplateModal.less";
import {
  deleteReportHook,
  getAccountDataHook,
  updateReportAccountingTemplateHook,
} from "../../api-hooks/report";
import { notification } from "antd";
import { fetchComapnyWithReportsHook } from "../../api-hooks/company";
import { setCompaniesWithReports } from "../../store/company";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useRouter } from "../../routes";
import {
  createAccountTemplatesHook,
  getAccountTemplatesHook,
} from "../../api-hooks/account-templates";
import { setAccountTemplates } from "../../store/account-templates";

const SaveNewTemplateModal = ({
  open,
  handleClose,
  reportId,
  setSelectedTemplateObj,
  setIsMappingChanged,
}) => {
  const [name, setName] = useState("");
  const dispatch = useDispatch();
  const router = useRouter();
  const { query } = router;
  const onCreateTemplate = () => {
    if (name) {
      console.log("Create template getting called");
      let accountObj = {};
      getAccountDataHook({ reportId: query?.reportId }, (response) => {
        console.log("Get Account Data Inside Template", response);
        response?.forEach((data) => {
          accountObj[data?.name] = data;
        });
        const templateAccountList = [];
        Object.keys(accountObj).forEach((key) => {
          const item = accountObj[key];
          // {
          //     "name": "Furniture and Fixtures",
          //     "category": {
          //         "type": "balance_item",
          //         "name": "Property, plant and equipment",
          //         "parent": {
          //             "type": "balance_header",
          //             "name": "NON-CURRENT ASSET"
          //         }
          //     }
          // },
          templateAccountList.push({
            name: key,
            category: {
              type: item?.category?.type,
              name: item?.category?.name,
              parent: {
                type: item?.category?.parent?.type,
                name: item?.category?.parent?.name,
              },
            },
          });
        });
        console.log("Final Account Obj", templateAccountList);
        createAccountTemplatesHook(
          {
            name: name,
            accounts: templateAccountList,
          },
          (response) => {
            console.log("Template Created Successfully", response);
            getAccountTemplatesHook((accountTemplatesReponse) => {
              dispatch(setAccountTemplates(accountTemplatesReponse));
              updateReportAccountingTemplateHook(
                {
                  id: query?.reportId,
                  templateId: response?.id,
                },
                (reportResponse) => {
                  console.log("Update Report Accounting ", reportResponse);
                  setSelectedTemplateObj({
                    templateId: response?.id,
                    templateName: response?.name,
                  });
                }
              );
              setIsMappingChanged(false);
              handleClose();
            });
          }
        );
      });
    }
  };
  return (
    <ComponentModal
      open={open}
      handleClose={handleClose}
      backText="Cancel"
      submitText="Create"
      title="New Template"
      handleOk={onCreateTemplate}
    >
      <div className={"save-reports-heading-container"}>
        <div
          className={"save-reports-heading-title"}
          style={{ marginBottom: "10px" }}
        >
          New Template
        </div>
        <ComponentInput
          value={name}
          onChange={(value) => {
            setName(value);
          }}
        />
      </div>
    </ComponentModal>
  );
};

export default SaveNewTemplateModal;
