import { configureStore, combineReducers } from "@reduxjs/toolkit";

import user from "./user";
import year from "./year";
import company from "./company";
import report from "./report";
import reportTemplates from "./report-templates";
import accountTemplates from "./account-templates";
const reducer = combineReducers({
  user,
  year,
  company,
  report,
  reportTemplates,
  accountTemplates,
});

export const store = configureStore({
  reducer,
});
