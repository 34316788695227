import React from "react";
import ComponentUpload from "../../atoms/ComponentUpload";
import "./ReportDrive.less";

const DraftItem = ({ draftItem, onSelect, setReportDisplay, fetchData }) => {
  return (
    <div
      className={"report-drive-draftitem"}
      // onClick={() => {
      //   onSelect(draftItem?.id, draftItem?.name);
      // }}
    >
      <div className="report-drive-title-btn">
        <div style={{ marginLeft: "9.5px", flex: "1 1 auto" }}>
          {draftItem?.name}
        </div>
        <div className={"upload-draft-item"}>
          <ComponentUpload fetchData={fetchData} reportId={draftItem?.id} />
        </div>
      </div>
      {draftItem?.reportDrive &&
        draftItem?.reportDrive?.length > 0 &&
        draftItem?.reportDrive?.map((driveItem, index) => {
          return (
            <div
              key={index}
              className="uploaded-file-document"
              onClick={() => {
                onSelect(driveItem);
                setReportDisplay(true);
              }}
            >
              {driveItem?.name}
            </div>
          );
        })}
    </div>
  );
};
export default DraftItem;
