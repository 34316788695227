import { useEffect, useState } from "react";
import { useRouter } from "../../routes";
import Editor from "../../sub-components/Editor";
import dayjs from "dayjs";

import {
  getReportGeneratorHtmlHook,
  updateReportGeneratorHtmlHook,
  getPDFPreviewHook,
  convertWordHook,
} from "../../api-hooks/report";

import { saveAs } from "file-saver";
import { message } from "antd";
import debounce from "lodash/debounce";

const PreviewHtml = ({
  refreshKey,
  preview,
  content,
  setContent,
  previewContent,
  setPreviewContent,
}) => {
  const router = useRouter();
  const { query } = router;
  // const [content, setContent] = useState("");
  // const [previewContent, setPreviewContent] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  const getReportGeneratorHtml = (type) => {
    getReportGeneratorHtmlHook({ reportId: query?.reportId }, (response) => {
      if (response) {
        type === "init" && setContent(response.content);
        setPreviewContent(response.previewContent);
        setName(response.name);
        console.log(response);
      }
    });
  };

  useEffect(() => {
    getReportGeneratorHtml("init");
  }, [refreshKey]);
  return (
    <Editor
      preview={preview}
      setData={setContent}
      isSaveButtonEnabled
      onPdf={(disablingPDF, onWord) => {
        if (loading) return;
        if (!disablingPDF) {
          setLoading(true);
          message.loading({
            content: "Downloading PDF..",
            duration: 0,
            key: "loadingKey",
          });
        }

        getPDFPreviewHook({ reportId: query?.reportId }, (response) => {
          if (!disablingPDF) {
            setLoading(false);
            saveAs(response, `${name}-${dayjs().format("YYYYMMDDHHmmss")}.pdf`);
            message.destroy("loadingKey");
            message.success("Loading finished", 2.5);
          } else {
            onWord();
          }
        });
      }}
      onWord={() => {
        if (loading) return;
        setLoading(true);
        message.loading({
          content: "Downloading Word..",
          duration: 0,
          key: "loadingKey",
        });
        convertWordHook({ reportId: query?.reportId }, (response) => {
          setLoading(false);
          saveAs(response, `${name}-${dayjs().format("YYYYMMDDHHmmss")}.docx`);
          message.destroy("loadingKey");
          message.success("Loading finished", 2.5);
        });
      }}
      type="content"
      onSwitch={() => {
        getReportGeneratorHtml();
      }}
      onSave={debounce((type, data) => {
        updateReportGeneratorHtmlHook(
          {
            reportId: query?.reportId,
            content: data,
          },
          (response) => {
            getReportGeneratorHtml("init");
          }
        );
      }, 200)}
      data={content}
      previewData={previewContent}
    />
  );
};

export default PreviewHtml;
