import "./Editor.less";

import { Extension, mergeAttributes, Node } from "@tiptap/core";
import {
  EditorContent,
  useEditor,
  ReactRenderer,
  BubbleMenu,
} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import TextAlign from "@tiptap/extension-text-align";
import Highlight from "@tiptap/extension-highlight";
// import Underline from "@tiptap/extension-underline";
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableCell from "@tiptap/extension-table-cell";
import BubbleExtension from "@tiptap/extension-bubble-menu";
import TableHeader from "@tiptap/extension-table-header";

import DoubleUnderline from "./extensions/double-underline";
import Underline from "./extensions/underline";
import Equation from "./extensions/equation";
import PageBreak from "./extensions/page-break";
// import Paragraph from '@tiptap/extension-paragraph';

import React, { useEffect, useState } from "react";

import doubleUnderlineIcon from "../../assets/svg-icons/double-underline.svg";

import { MentionList } from "./MentionList";

import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  BoldOutlined,
  HighlightOutlined,
  ItalicOutlined,
  RedoOutlined,
  StrikethroughOutlined,
  UnderlineOutlined,
  UndoOutlined,
  FilePdfOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
  FileWordOutlined,
} from "@ant-design/icons";

import { ReactComponent as CreateTableIcon } from "../../assets/svg-icons/createTable.svg";
import { ReactComponent as AfterColumnIcon } from "../../assets/svg-icons/afterColumn.svg";
import { ReactComponent as BeforeColumnIcon } from "../../assets/svg-icons/beforeColumn.svg";
import { ReactComponent as DeleteColumnIcon } from "../../assets/svg-icons/deleteColumn.svg";
import { ReactComponent as AfterRowIcon } from "../../assets/svg-icons/afterRow.svg";
import { ReactComponent as BeforeRowIcon } from "../../assets/svg-icons/beforeRow.svg";
import { ReactComponent as DeleteRowIcon } from "../../assets/svg-icons/deleteRow.svg";
import { ReactComponent as DeleteTableIcon } from "../../assets/svg-icons/deleteTable.svg";
import { ReactComponent as EquationIcon } from "../../assets/svg-icons/equationIcon.svg";
import ComponentButton from "../../atoms/ComponentButton";
import { Switch, Tooltip } from "antd";
import { CustomTableHeader } from "./extensions/CustomTableHeader";
import {
  getAccountEquationHook,
  getEquationPreviewHook,
} from "../../api-hooks/account-equation";
import { useRouter } from "../../routes";
import SaveDiscardModal from "../SaveDiscard";
import { ShowEquationData } from "./ShowEquationData";
// import html2pdf from 'html2pdf.js'

// import htmlDocx from 'html-docx-js/dist/html-docx';
// import { saveAs } from 'file-saver'

// const CustomTableCell = TableCell.extend({
//   addAttributes() {
//     return {};
//   },
// });

const CustomDiv = Node.create({
  name: "div",
  content: "block*",
  group: "block",
  defining: true,
  parseHTML() {
    return [
      {
        tag: "div",
      },
    ];
  },
  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute("id"),
        renderHTML: (attributes) => {
          if (!attributes.id) {
            return {};
          }
          return {
            id: attributes.id,
          };
        },
      },
      // style: {
      //   default: null,
      //   parseHTML: (element) => element.getAttribute("style"),
      //   renderHTML: (attributes) => {
      //     if (!attributes.style) {
      //       return {};
      //     }
      //     return {
      //       style: attributes.style,
      //     };
      //   },
      // },
    };
  },
  renderHTML({ HTMLAttributes }) {
    return [
      "div",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
});

const CustomParagraph = Node.create({
  name: "cp",
  content: "block*",
  group: "block",
  defining: true,
  parseHTML() {
    return [
      {
        tag: "cp",
      },
    ];
  },
  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute("id"),
        renderHTML: (attributes) => {
          if (!attributes.id) {
            return {};
          }
          return {
            id: attributes.id,
          };
        },
      },
      style: {
        default: null,
        parseHTML: (element) => element.getAttribute("style"),
        renderHTML: (attributes) => {
          if (!attributes.style) {
            return {};
          }
          return {
            style: attributes.style,
          };
        },
      },
      class: {
        default: null,
        parseHTML: (element) => element.getAttribute("class"),
        renderHTML: (attributes) => {
          if (!attributes.class) {
            return {};
          }
          return {
            class: attributes.class,
          };
        },
      },
    };
  },
  renderHTML({ HTMLAttributes }) {
    return [
      "cp",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
});

const CustomAttrbutes = Extension.create({
  addGlobalAttributes() {
    return [
      {
        // Extend the following extensions
        types: [
          "div",

          // "heading",
          "span",
          "cp",
          "table",
          "tableRow",
          "tableCell",
        ],
        // … with those attributes
        attributes: {
          id: {
            default: null,
            parseHTML: (element) => element.getAttribute("id"),
            renderHTML: (attributes) => {
              if (!attributes.id) {
                return {};
              }
              return {
                id: attributes.id,
              };
            },
          },
          style: {
            default: null,
            parseHTML: (element) => element.getAttribute("style"),
            renderHTML: (attributes) => {
              if (!attributes.style) {
                return {};
              }
              return {
                style: attributes.style,
              };
            },
          },
          class: {
            default: null,
            parseHTML: (element) => element.getAttribute("class"),
            renderHTML: (attributes) => {
              if (!attributes.class) {
                return {};
              }
              return {
                class: attributes.class,
              };
            },
          },
        },
      },
    ];
  },
});

const SectionExtension = Node.create({
  name: "report-generator-section",
  content: "block*",
  group: "block",
  defining: true,
  parseHTML() {
    return [
      {
        tag: "report-generator-section",
      },
    ];
  },
  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute("id"),
        renderHTML: (attributes) => {
          if (!attributes.id) {
            return {};
          }
          return {
            id: attributes.id,
          };
        },
      },
      style: {
        default: null,
        parseHTML: (element) => element.getAttribute("style"),
        renderHTML: (attributes) => {
          if (!attributes.style) {
            return {};
          }
          return {
            style: attributes.style,
          };
        },
      },
      class: {
        default: null,
        parseHTML: (element) => element.getAttribute("class"),
        renderHTML: (attributes) => {
          if (!attributes.class) {
            return {};
          }
          return {
            class: attributes.class,
          };
        },
      },
    };
  },
  renderHTML({ HTMLAttributes }) {
    return [
      "report-generator-section",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
  addKeyboardShortcuts() {
    return {
      Enter: () =>
        !this.editor.isActive("bulletList") &&
        !this.editor.isActive("orderedList")
          ? this.editor.commands.setHardBreak()
          : this.editor.commands.newlineInCode(),
      "Shift-Enter": () => this.editor.commands.setHardBreak(),
      Backspace: () => {
        this.editor.commands.deleteRange();
      },
    };
  },
});

const MenuBar = ({
  editor,
  onChange,
  isEditMode,
  onPdf,
  onWord,
  preview,
  isFull,
  onChangeFull,
  hideEquation,
  isSaveButtonEnabled,
  onSave,
  data,
  type,
  hideSwitchBtn,
}) => {
  if (!editor) {
    return null;
  }

  return (
    <div className="editor-menu-container">
      {!preview ? (
        <>
          <button
            onClick={() => editor.chain().focus().toggleBold().run()}
            className={
              editor.isActive("bold")
                ? "is-active editor-menu-item-container"
                : "editor-menu-item-container"
            }
          >
            <Tooltip title="Bold">
              <BoldOutlined />
            </Tooltip>
          </button>
          <button
            onClick={() => editor.chain().focus().toggleItalic().run()}
            className={
              editor.isActive("italic")
                ? "is-active editor-menu-item-container"
                : "editor-menu-item-container"
            }
          >
            <Tooltip title="Italic">
              <ItalicOutlined />
            </Tooltip>
          </button>
          <button
            onClick={() => editor.chain().focus().toggleStrike().run()}
            className={
              editor.isActive("strike")
                ? "is-active editor-menu-item-container"
                : "editor-menu-item-container"
            }
          >
            <Tooltip title="Strike Through">
              {" "}
              <StrikethroughOutlined />
            </Tooltip>
          </button>

          <button
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            className={
              editor.isActive("underline")
                ? "is-active editor-menu-item-container"
                : "editor-menu-item-container"
            }
          >
            <Tooltip title="Underline">
              <UnderlineOutlined />
            </Tooltip>
          </button>
          <button
            onClick={() => editor.chain().focus().toggleDoubleUnderline().run()}
            className={
              editor.isActive("double-underline")
                ? "is-active editor-menu-item-container"
                : "editor-menu-item-container"
            }
          >
            <Tooltip title="Double Underline">
              <img
                src={doubleUnderlineIcon}
                alt="double-underline"
                style={{ width: "19px", height: "19px", marginBottom: "3px" }}
              />
            </Tooltip>
          </button>

          <button
            onClick={() => editor.chain().focus().setParagraph().run()}
            className={
              editor.isActive("paragraph")
                ? "is-active editor-menu-item-container"
                : "editor-menu-item-container"
            }
          >
            <Tooltip title="Paragraph">P</Tooltip>
          </button>
          <button
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 1 }).run()
            }
            className={
              editor.isActive("heading", { level: 1 })
                ? "is-active editor-menu-item-container"
                : "editor-menu-item-container"
            }
          >
            <Tooltip title="Heading One">
              H<sup>1</sup>
            </Tooltip>
          </button>
          <button
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 2 }).run()
            }
            className={
              editor.isActive("heading", { level: 2 })
                ? "is-active editor-menu-item-container"
                : "editor-menu-item-container"
            }
          >
            <Tooltip title="Heading Two">
              H<sup>2</sup>
            </Tooltip>
          </button>
          <button
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 3 }).run()
            }
            className={
              editor.isActive("heading", { level: 3 })
                ? "is-active editor-menu-item-container"
                : "editor-menu-item-container"
            }
          >
            <Tooltip title="Heading Three">
              H<sup>3</sup>
            </Tooltip>
          </button>
          <button
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 4 }).run()
            }
            className={
              editor.isActive("heading", { level: 4 })
                ? "is-active editor-menu-item-container"
                : "editor-menu-item-container"
            }
          >
            <Tooltip title="Heading Four">
              H<sup>4</sup>
            </Tooltip>
          </button>

          <button
            onClick={() => editor.chain().focus().toggleHighlight().run()}
            className={
              editor.isActive("highlight")
                ? "is-active editor-menu-item-container"
                : "editor-menu-item-container"
            }
          >
            <Tooltip title="Marker">
              <HighlightOutlined />
            </Tooltip>
          </button>

          <button
            onClick={() => {
              editor.chain().focus().setTextAlign("left").run();
            }}
            className={
              editor.isActive({ textAlign: "left" })
                ? "is-active editor-menu-item-container"
                : "editor-menu-item-container"
            }
          >
            <Tooltip title="Left Align">
              <AlignLeftOutlined />
            </Tooltip>
          </button>

          <button
            onClick={() => {
              editor.chain().focus().unsetTextAlign().run();
              editor.chain().focus().setTextAlign("right").run();
            }}
            className={
              editor.isActive({ textAlign: "right" })
                ? "is-active editor-menu-item-container"
                : "editor-menu-item-container"
            }
          >
            <Tooltip title="Right Align">
              <AlignRightOutlined />
            </Tooltip>
          </button>

          <button
            onClick={() => {
              editor.chain().focus().unsetTextAlign().run();
              editor.chain().focus().setTextAlign("center").run();
            }}
            className={
              editor.isActive({ textAlign: "center" })
                ? "is-active editor-menu-item-container"
                : "editor-menu-item-container"
            }
          >
            <Tooltip title="Center Align">
              <AlignCenterOutlined />
            </Tooltip>
          </button>

          <button
            onClick={() => editor.chain().focus().toggleBlockquote().run()}
            className={
              editor.isActive("blockquote")
                ? "is-active editor-menu-item-container"
                : "editor-menu-item-container"
            }
          >
            <Tooltip title="Quotes">"</Tooltip>
          </button>
          {/* <button onClick={() => editor.chain().focus().setHorizontalRule().run()}>
        horizontal rule
      </button>*/}
          {/* <button onClick={() => editor.chain().focus().setPageBreak().run()}>
            Page Break
          </button> */}
          <button
            onClick={() => editor.chain().focus().undo().run()}
            className="editor-menu-item-container"
            disabled={!editor.can().undo()}
          >
            <Tooltip title="Undo">
              <UndoOutlined />
            </Tooltip>
          </button>
          {/* <button
            onClick={() => editor.chain().focus().redo().run()}
            className="editor-menu-item-container"
            disabled={!editor.can().redo()}
          >
            <Tooltip title="Redo">
              <RedoOutlined />
            </Tooltip>
          </button> */}
          <button onClick={onChangeFull} className="editor-menu-item-container">
            {isFull ? (
              <Tooltip title="Exit Full Screen">
                <FullscreenExitOutlined />
              </Tooltip>
            ) : (
              <Tooltip title="Full Screen">
                <FullscreenOutlined />
              </Tooltip>
            )}
          </button>
          <button
            onClick={() => {
              editor
                .chain()
                .focus()
                .insertTable({ rows: 3, cols: 3, withHeadersRow: false })
                .run();
            }}
            className="editor-menu-item-container"
          >
            <Tooltip title="Add Table">
              <CreateTableIcon style={{ width: "16px", height: "16px" }} />
            </Tooltip>
          </button>
          <button
            onClick={() => editor.chain().focus().addColumnBefore().run()}
            className="editor-menu-item-container"
          >
            <Tooltip title="Add Column Before">
              <BeforeColumnIcon style={{ width: "16px", height: "16px" }} />
            </Tooltip>
          </button>
          <button
            onClick={() => editor.chain().focus().addColumnAfter().run()}
            className="editor-menu-item-container"
          >
            <Tooltip title="Add Column After">
              <AfterColumnIcon style={{ width: "16px", height: "16px" }} />
            </Tooltip>
          </button>
          <button
            onClick={() => editor.chain().focus().deleteColumn().run()}
            className="editor-menu-item-container"
          >
            <Tooltip title="Delete Column">
              <DeleteColumnIcon style={{ width: "16px", height: "16px" }} />
            </Tooltip>
          </button>
          <button
            onClick={() => editor.chain().focus().addRowBefore().run()}
            className="editor-menu-item-container"
          >
            <Tooltip title="Add Row Before">
              <BeforeRowIcon style={{ width: "16px", height: "16px" }} />
            </Tooltip>
          </button>
          <button
            onClick={() => editor.chain().focus().addRowAfter().run()}
            className="editor-menu-item-container"
          >
            <Tooltip title="Add Row After">
              <AfterRowIcon style={{ width: "16px", height: "16px" }} />
            </Tooltip>
          </button>
          <button
            onClick={() => editor.chain().focus().deleteRow().run()}
            className="editor-menu-item-container"
          >
            <Tooltip title="Delete Row">
              <DeleteRowIcon style={{ width: "16px", height: "16px" }} />
            </Tooltip>
          </button>
          <button
            onClick={() => editor.chain().focus().deleteTable().run()}
            className="editor-menu-item-container"
          >
            <Tooltip title="Delete Table">
              <DeleteTableIcon style={{ width: "16px", height: "16px" }} />
            </Tooltip>
          </button>
          {/* <button
            onClick={() =>
              // editor.commands.
              //   editor.commands.insertContentAt(12, "<p>Hello world</p>", {
              //     updateSelection: true,
              //     parseOptions: {
              //       preserveWhitespace: "full",
              //     },
              //   })
              editor.chain().focus().setEquation({ equationId: "test" }).run()
            }
            className="editor-menu-item-container"
          >
            <EquationIcon style={{ width: "16px", height: "16px" }} />
          </button> */}
          {/* <button onClick={() => editor.chain().focus().mergeCells().run()}>
            mergeCells
          </button>
          <button onClick={() => editor.chain().focus().splitCell().run()}>
            splitCell
          </button>
          <button
            onClick={() => editor.chain().focus().toggleHeaderColumn().run()}
          >
            toggleHeaderColumn
          </button>
          <button
            onClick={() => editor.chain().focus().toggleHeaderRow().run()}
          >
            toggleHeaderRow
          </button>
          <button
            onClick={() => editor.chain().focus().toggleHeaderCell().run()}
          >
            toggleHeaderCell
          </button>
          <button onClick={() => editor.chain().focus().mergeOrSplit().run()}>
            mergeOrSplit
          </button>
          <button
            onClick={() =>
              editor.chain().focus().setCellAttribute("colwidth", 60).run()
            }
          >
            setCellAttribute
          </button>
          <button onClick={() => editor.chain().focus().fixTables().run()}>
            fixTables
          </button>
          <button onClick={() => editor.chain().focus().goToNextCell().run()}>
            goToNextCell
          </button>
          <button
            onClick={() => editor.chain().focus().goToPreviousCell().run()}
          >
            goToPreviousCell
          </button> */}
        </>
      ) : null}
      {onPdf ? (
        <button
          onClick={async () => {
            onPdf();
            // html2pdf().set({
            //   margin:       [2, 1, 2, 1],
            //   image:        { type: 'jpeg', quality: 0.98 },
            //   html2canvas:  { scale: 2 },
            //   jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' },
            //   pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
            // }).from(data, 'string').toPdf().get('pdf').then((pdf) => {
            //   console.log(pdf)
            //   const totalPages = pdf.internal.getNumberOfPages();
            //   console.log(totalPages)
            //   for (let i = 1; i <= totalPages; i++) {
            //     pdf.setPage(i);
            //     pdf.setFontSize(10);
            //     pdf.setTextColor(150);
            //     //divided by 2 to go center
            //     console.log(totalPages, pdf.internal.pageSize.getWidth())
            //     pdf.text('Page ' + i + ' of ' + totalPages, -1, -1);
            //   }
            // }).save('pdf.pdf');
          }}
          className="editor-menu-item-container"
        >
          <Tooltip title="Download PDF">
            <FilePdfOutlined style={{ position: "relative", bottom: "2px" }} />
          </Tooltip>
        </button>
      ) : null}
      {onWord ? (
        <button
          onClick={async () => {
            // const converted = htmlDocx.asBlob(data, {orientation: 'landscape', margins: {top: 720}})
            // saveAs(converted, 'doc.docx')
            onPdf(true, onWord);
            // onWord();
          }}
          className="editor-menu-item-container"
        >
          <Tooltip title="Download Word">
            <FileWordOutlined style={{ position: "relative", bottom: "2px" }} />
          </Tooltip>
        </button>
      ) : null}
      {!preview ? (
        <div
          style={{
            flex: "1 1 auto",
            display: "flex",
            // flexDirection: "row-reverse",
            marginTop: "1em",
            alignItems: "center",
          }}
        >
          <div style={{ flex: "1 1 ", color: "rgb(105, 111, 140)" }}>
            {!hideEquation && (
              <span>
                Type <b>#</b> to insert equations
              </span>
            )}
          </div>
          {isSaveButtonEnabled && isEditMode && (
            <ComponentButton
              title="Save"
              onClick={() => {
                onSave(type, data);
                onChange("save");
              }}
              style={{ marginRight: "12px" }}
            />
          )}
          {!hideSwitchBtn && (
            <Switch
              checked={isEditMode}
              onChange={(e) => onChange("switch", e)}
              size="small"
              // checkedChildren="Edit"
              unCheckedChildren="Edit"
            />
          )}
        </div>
      ) : null}
    </div>
  );
};

const Editor = ({
  data,
  setData,
  type,
  onSwitch,
  onSave,
  isSaveButtonEnabled,
  previewData,
  onPdf,
  onWord,
  preview,
  hideEquation,
  defaultEnable,
  hideSwitchBtn,
}) => {
  const [isEditMode, setIsEditMode] = useState(defaultEnable);
  const [isFull, setIsFull] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [openDiscard, setOpenDiscard] = useState(false);

  const router = useRouter();
  const { query } = router;

  const [open, setOpen] = useState(false);

  // const readOnlyEditor = useEditor(
  //   {
  //     extensions: [
  //       StarterKit,
  //       TextAlign.configure({
  //         types: ["heading", "paragraph"],
  //       }),
  //       Highlight,
  //       Underline.configure({
  //         style: "border-bottom:1px solid;text-decoration:none",
  //       }),
  //       Table.configure({
  //         resizable: true,
  //       }),

  //       TableRow,
  //       CustomTableHeader,
  //       // TableHeader,
  //       TableCell,
  //       SectionExtension,
  //       CustomAttrbutes,
  //       CustomDiv,
  //       // PageBreak,
  //       // BubbleExtension.configure({
  //       //   shouldShow: ({ editor, view, state, oldState, from, to }) => {
  //       //     // only show the bubble menu for images and links
  //       //     return editor.isActive("equation");
  //       //   },
  //       // }),
  //       Equation.configure({
  //         HTMLAttributes: {
  //           class: "equationNode",
  //           isReadOnly: true,
  //           // style: "background-color:#d5eee0",
  //         },
  //       }),
  //       DoubleUnderline.configure({
  //         style:
  //           "padding-bottom:10px;border-bottom:4px double;text-decoration:none",
  //       }),
  //     ],
  //     editable: false,
  //   },
  //   [isEditMode]
  // );
  const editor = useEditor({
    // useBuiltInExtension: false,
    extensions: [
      StarterKit,
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),

      Highlight,
      Underline.configure({
        style: "border-bottom:1px solid;text-decoration:none",
      }),
      Table.configure({
        resizable: true,

        // HTMLAttributes: {
        //   class: "my-custom-class",
        // },
      }),
      TableRow,
      // TableHeader,
      CustomTableHeader,
      TableCell,
      SectionExtension,
      CustomAttrbutes,
      CustomDiv,
      CustomParagraph,
      // BubbleExtension.configure({
      //   shouldShow: ({ editor, view, state, oldState, from, to }) => {
      //     // only show the bubble menu for images and links
      //     return editor.isActive("equation");
      //   },
      // }),
      Equation.configure({
        HTMLAttributes: {
          class: "equationNode",

          // style: "background-color:#d5eee0",
        },
        suggestion: {
          render: () => {
            let reactRenderer;
            console.log("On start rendered", reactRenderer);
            return {
              onStart: (props) => {
                reactRenderer = new ReactRenderer(MentionList, {
                  props,
                  editor: props.editor,
                  // equations: equations,
                });

                console.log("On Start Mention renderer", reactRenderer);
              },

              onUpdate(props) {
                reactRenderer?.updateProps(props);
              },

              onKeyDown(props) {
                if (props.event.key === "Escape") {
                  reactRenderer?.destroy();
                  return true;
                }

                return reactRenderer?.ref?.onKeyDown(props);
              },

              onExit() {
                console.log("On Exit Renderer", reactRenderer);
                if (reactRenderer) {
                  reactRenderer.destroy();
                }
              },
            };
          },
        },
      }),
      DoubleUnderline.configure({
        style: "border-bottom:4px double;text-decoration:none",
      }),
    ],
    onCreate({ editor }) {
      // The editor is ready.
      console.log("On Create Editor", editor.getHTML());
    },
    onUpdate({ editor }) {
      // if (setData) {
      const data = editor.getHTML();
      const dataInText = editor.getText();
      setIsEdited(true);
      // console.log(editor.getHTML());
      // console.log("editor?.getHTML()", editor?.getHTML());
      // const boxes = document.querySelectorAll(".equationNode");

      // console.log("Boxes", boxes);

      // boxes.forEach((box) => {
      //   box.addEventListener("click", function handleClick(event) {
      //     console.log("box clicked", event);

      //     box.setAttribute("style", "background-color: yellow;");
      //   });
      // });

      if (setData) {
        setData(data);
      }

      // onSaveData(data);
    },
  });

  const onSaveData = (data) => {
    // if (isEditMode) {
    // if (onSave) {
    //   onSave(type, data);
    // }
    // }
  };

  useEffect(() => {
    if (!editor) return;
    let { from, to } = editor.state.selection;
    if (isEditMode) {
      editor.setOptions({ editable: true });
      editor.extensionManager.extensions.find(
        (extension) => extension.name === "equation"
      ).options.HTMLAttributes.isReadOnly = false;
      editor.commands.setContent(data, false, {
        preserveWhitespace: "full",
      });
    } else {
      editor.setOptions({ editable: false });
      editor.extensionManager.extensions.find(
        (extension) => extension.name === "equation"
      ).options.HTMLAttributes.isReadOnly = true;
      editor.commands.setContent(previewData, false, {
        preserveWhitespace: "full",
      });
    }
    // else {
    //   console.log("Edit Data", data, editor);
    //   editor.commands.setContent(previewData, false, {
    //     preserveWhitespace: "full",
    //   });
    // }

    editor.commands.setTextSelection({ from, to });
  }, [editor, data, isEditMode, previewData]);

  // useEffect(() => {
  //   if (!readOnlyEditor) return;
  //   let { from, to } = readOnlyEditor.state.selection;

  //   // console.log("Edit Data", previewData, readOnlyEditor);
  //   readOnlyEditor.commands.setContent(previewData, false, {
  //     preserveWhitespace: "full",
  //   });

  //   console.log("from, to", from, to);

  //   readOnlyEditor.commands.setTextSelection({ from, to });
  // }, [readOnlyEditor, previewData]);

  // useEffect(() => {
  //   if (isEditMode && editor?.getHTML()) {
  //     console.log("editor?.getHTML()", editor?.getHTML());
  //     const boxes = document.querySelectorAll(".equationNode");

  //     console.log("Boxes", boxes);

  //     boxes.forEach((box) => {
  //       box.addEventListener("click", function handleClick(event) {
  //         console.log("box clicked", editor.view.state.selection.$to.nodeAfter);
  //         console.log("Current Data", box.getAttribute("data-id"));
  //         setOpen(true);
  //         box.setAttribute("style", "background-color: yellow;");
  //       });
  //     });
  //   }
  // }, [isEditMode]);

  const onChangesSave = (type, toggle) => {
    if (type === "switch" && !toggle && onSave && setData && previewData) {
      if (isEdited) {
        setOpenDiscard(true);
      } else {
        setIsEditMode(!isEditMode);
      }
    } else {
      setIsEditMode(!isEditMode);
    }
  };

  return (
    <>
      <div
        className={`editor-container ${isFull ? "editor-container--full" : ""}`}
      >
        <MenuBar
          preview={preview}
          editor={editor}
          onPdf={onPdf}
          onWord={onWord}
          onChange={(type, toggle) => {
            onSwitch && onSwitch();
            // editor.setEditable(isEditMode);
            console.log("on change calling", toggle);
            onChangesSave(type, toggle);
          }}
          isFull={isFull}
          hideEquation={hideEquation}
          onChangeFull={() => {
            setIsFull(!isFull);
          }}
          isEditMode={isEditMode}
          onSave={onSave}
          data={data}
          type={type}
          isSaveButtonEnabled={isSaveButtonEnabled}
          hideSwitchBtn={hideSwitchBtn}
        />
        {/* {!isEditMode && (
          <EditorContent className="editor-content" editor={readOnlyEditor} />
        )} */}
        {/* {isEditMode && ( */}
        <>
          <EditorContent className="editor-content" editor={editor} />
        </>
        {/* )} */}
        {openDiscard && onSave && setData && previewData && (
          <SaveDiscardModal
            open={isEditMode}
            onSave={() => {
              setOpenDiscard(false);
              setIsEdited(false);
              onSave(type, data);
              setIsEditMode(!isEditMode);
            }}
            handleClose={() => {
              setIsEdited(false);
              setData(previewData);
              setOpenDiscard(false);
              setIsEditMode(false);
            }}
          />
        )}
      </div>
      {open && (
        <ShowEquationData open={open} setOpen={setOpen} equation="currency" />
      )}
    </>
  );
};

export default Editor;
