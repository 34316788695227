import React from "react";

import "./CreateOrEditReport.less";

const UploadOption = ({ icon, name, disabled, style }) => {
  const getClass = () => {
    if (disabled) {
      return `${"upload-data-container"} ${"disabled"}`;
    }
    return "upload-data-container";
  };

  return (
    <div className={getClass()} style={style}>
      {icon}
      {/* <img src={icon} /> */}
      <div style={{ marginTop: "5px" }}>{name}</div>
    </div>
  );
};

export default UploadOption;
