import api from "./api-intercepter";

export const addColumnHook = (values, callback) => {
  const data = JSON.stringify({
    name: values?.name,
    report: {
      id: values?.reportId,
    },
  });

  api
    .post("/dynamic-statement-columns", data)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};

export const getColumns = (callback) => {
  api.get("/dynamic-statement-columns").then((response) => {
    callback(response?.data?.data);
  });
};

export const getRows = (callback) => {
  api.get("/dynamic-statement-rows").then((response) => {
    callback(response?.data?.data);
  });
};

export const getValues = (callback) => {
  api.get("/dynamic-statements").then((response) => {
    callback(response?.data?.data);
  });
};

export const addRowsHook = (values, callback) => {
  const data = JSON.stringify({
    name: values?.name,
    report: {
      id: values?.reportId,
    },
    visibleIndex: values?.visibleIndex,
  });

  api
    .post("/dynamic-statement-rows", data)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};

export const addValuesHook = (values, callback) => {
  const data = JSON.stringify({
    value: values?.value,
    report: {
      id: values?.reportId,
    },
    row: {
      id: values?.rowId,
    },
    column: {
      id: values.columnId,
    },
  });

  api
    .post("/dynamic-statements", data)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};

export const updateValuesHook = (values, callback) => {
  const data = JSON.stringify({
    value: values?.value,
  });

  api
    .patch(`/dynamic-statements/${values.id}`, data)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};

export const updateColumnHook = (values, callback) => {
  const data = JSON.stringify({
    name: values?.name,
  });

  api
    .patch(`/dynamic-statement-columns/${values.id}`, data)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};

export const updateRowsHook = (values, callback) => {
  const data = JSON.stringify({
    name: values?.name,
  });

  api
    .patch(`/dynamic-statement-rows/${values.id}`, data)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};

export const deleteColumnHook = (values, callback) => {
  api
    .delete(`/dynamic-statement-columns/${values.id}`)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};

export const deleteRowHook = (values, callback) => {
  api
    .delete(`/dynamic-statement-rows/${values.id}`)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};
