import { CheckCircleFilled } from "@ant-design/icons";
import React from "react";

import "./ComponentItem.less";

import { ReactComponent as TickCircled } from "../../assets/svg-icons/tick-circle.svg";

const ComponentItem = ({ name, isSelected, isCompleted, onClick }) => {
  const getClass = () => {
    if (isSelected) {
      return `${"component-item-container"} ${"selected"}`;
    }
    return "component-item-container";
  };
  return (
    <div className={getClass()} onClick={onClick}>
      <div>{name}</div>
      {isCompleted && (
        <TickCircled style={{ color: "#52BD94", marginLeft: "10px" }} />
      )}
    </div>
  );
};

export default ComponentItem;
