import { mergeAttributes, Node } from "@tiptap/core";
import { Node as ProseMirrorNode } from "@tiptap/pm/model";
import { PluginKey } from "@tiptap/pm/state";
import Suggestion, { SuggestionOptions } from "@tiptap/suggestion";
import Dummy from "../../Dummy";

// export type MentionOptions = {
//   HTMLAttributes: Record<string, any>
//   renderLabel: (props: { options: MentionOptions; node: ProseMirrorNode }) => string
//   suggestion: Omit<SuggestionOptions, 'editor'>
// }

export const EquationPluginKey = new PluginKey("equation");

export const Equation = Node.create({
  name: "equation",

  addOptions() {
    return {
      HTMLAttributes: {},
      renderLabel({ options, node }) {
        console.log("Node Attr**", options.HTMLAttributes);
        if (options.HTMLAttributes?.isReadOnly) {
          return `${node.attrs.value ?? node.attrs.id}`;
        } else {
          // const splittedData = node.attrs.value?.split("#");
          // console.log("splittedData", splittedData);
          return `${node.attrs.label ?? node.attrs.id}`;
          // if (splittedData?.length === 1) {

          // } else {
          //   return `${splittedData[1] ?? node.attrs.id}`;
          // }
        }
      },
      renderValue({ options, node }) {
        console.log("Node Attr Value", node.attrs);
        return `${node.attrs.value ?? node.attrs.id}`;
      },
      suggestion: {
        char: "#",
        pluginKey: EquationPluginKey,
        command: ({ editor, range, props }) => {
          // increase range.to by one when the next node is of type "text"
          // and starts with a space character
          const nodeAfter = editor.view.state.selection.$to.nodeAfter;
          const overrideSpace = nodeAfter?.text?.startsWith(" ");

          if (overrideSpace) {
            range.to += 1;
          }

          editor
            .chain()
            .focus()
            .insertContentAt(range, [
              {
                type: this.name,
                attrs: props,
              },
              {
                type: "text",
                text: " ",
              },
            ])
            .run();

          window.getSelection()?.collapseToEnd();
        },
        allow: ({ state, range }) => {
          const $from = state.doc.resolve(range.from);
          const type = state.schema.nodes[this.name];
          const allow = !!$from.parent.type.contentMatch.matchType(type);

          return allow;
        },
      },
    };
  },

  group: "inline",

  inline: true,

  selectable: false,

  atom: true,

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-id"),
        renderHTML: (attributes) => {
          if (!attributes.id) {
            return {};
          }

          return {
            "data-id": attributes.id,
            id: attributes.value,
          };
        },
      },

      label: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-label"),
        renderHTML: (attributes) => {
          if (!attributes.label) {
            return {};
          }

          return {
            "data-label": attributes.label,
          };
        },
      },
      value: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-value"),
        renderHTML: (attributes) => {
          if (!attributes.value) {
            return {};
          }

          return {
            "data-value": attributes.value,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `span[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    console.log("Options", this.options);
    return [
      "span",
      mergeAttributes(
        { "data-type": this.name },
        this.options.HTMLAttributes,
        HTMLAttributes
      ),
      this.options.renderLabel({
        options: this.options,
        node,
      }),
    ];
  },

  renderText({ node }) {
    return this.options.renderValue({
      options: this.options,
      node,
    });
  },

  // addKeyboardShortcuts() {
  //   return {
  //     Backspace: () =>
  //       this.editor.commands.command(({ tr, state }) => {
  //         let isMention = false;
  //         const { selection } = state;
  //         const { empty, anchor } = selection;

  //         if (!empty) {
  //           return false;
  //         }

  //         state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
  //           if (node.type.name === this.name) {
  //             isMention = true;
  //             tr.insertText(
  //               this.options.suggestion.char || "",
  //               pos,
  //               pos + node.nodeSize
  //             );

  //             return false;
  //           }
  //         });

  //         return isMention;
  //       }),
  //   };
  // },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ];
  },
});
