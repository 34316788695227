import React from "react";
import "./index.less";

const Title = (props) => {
  const { title, renderRightPartDom, mode, titleStyle } = props;

  return (
    <>
      {mode !== "preview" && (
        <div
          className={"title title-box-1710"}
          style={{ display: "flex", alignItems: "center", width: "100%" }}
        >
          <span
            className={"tips left-border"}
            style={{ ...titleStyle, flex: "1 1 auto" }}
          >
            {title}
          </span>
          {renderRightPartDom && (
            <div className={"right-part r-p-box-1122"}>
              {renderRightPartDom}
            </div>
          )}
          <div style={{ clear: "both" }}></div>
        </div>
      )}
    </>
  );
};
export default Title;
