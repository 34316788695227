import {
  ArrowLeftOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  LeftOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Tooltip } from "antd";
import { useEffect, useState } from "react";
import {
  getAccountEquationHook,
  getEquationPreviewHook,
} from "../../api-hooks/account-equation";
import { getReportByIdHook } from "../../api-hooks/report";
import ComponentPopup from "../../atoms/ComponentPopup";
import PopupItem from "../../atoms/ComponentPopup/PopupItem";
import { useRouter } from "../../routes";
import CreateEquationModal from "../../sub-components/CreateEquationModal";
import PreviewReportSummaryHoc from "../../sub-components/PreviewReportSummaryHoc";
import { ReactComponent as EditIcon } from "../../assets/svg-icons/editIcon.svg";
import EquationPreviewPopup from "./EquationPreviewPopup";
import { getReportAccountSummaryHook } from "../../api-hooks/account";
import { removeBrackets, removeCommasFromNumbers } from "../../helper";
import { groupBy } from "lodash";
// import { ReactComponent as EditIcon } from "../../assets/svg-icons/editIcon.svg";

const EquationPreviewSheet = ({ title, type, setIsSheetPreviewEnabled }) => {
  const router = useRouter();
  const { query } = router;

  const [previewData, setPreviewData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isMakeAdjustmentDialogOpened, setIsMakeAdjustmentDialogOpened] =
    useState(false);
  const [isPopupOpened, setIsPopupOpened] = useState(false);

  const [yearList, setYearList] = useState([]);
  const [itemAmountObj, setItemAmountObj] = useState({});
  const [previewObj, setPreviewObj] = useState({});
  const [selectedEquationItem, setSelectedEquationItem] = useState([]);
  const [currentYear, setCurrentYear] = useState("2022");
  const [previousYear, setPreviousYear] = useState("2021");

  useEffect(() => {
    getReportByIdHook(query?.reportId, (response) => {
      console.log("Report By id", response);
      if (response?.fiscalYear?.name) {
        const yearList = response?.fiscalYear?.name?.split("/");
        setCurrentYear(yearList[1]);

        setPreviousYear(yearList[0]);
      }
    });
    getAccountEquationHook(
      {
        reportId: query?.reportId,
      },
      (response) => {
        console.log("Report Equation Summary", response);
        let str = "";
        // response.map(() => {
        const itemList = response;

        itemList?.forEach((item) => {
          str =
            str +
            `{{current|equation|${item.name}}}#{{previous|equation|${item.name}}}::`;
        });
        getEquationPreviewHook(
          { reportId: query?.reportId, content: str },
          (response) => {
            console.log("Equation Preview Hook", response);
            const list = response?.split("::");
            let previewList = [];
            let alllPreviewList = [];
            itemList?.forEach((item, index) => {
              const splittedData = list[index].split("#");
              previewList.push({
                ...item,
                current: splittedData[0],
                previous: splittedData[1],
                type: "equation",
                label: item?.name,
                value: item?.name,
              });
              console.log(
                " splittedData[0]?.[0]",
                splittedData[0]?.[0],
                splittedData[0]?.[0] === "("
                  ? removeBrackets(splittedData[0])
                  : ""
              );
              alllPreviewList.push({
                ...item,
                current:
                  splittedData[0]?.[0] === "("
                    ? removeCommasFromNumbers(removeBrackets(splittedData[0]))
                    : removeCommasFromNumbers(splittedData[0]),
                previous:
                  splittedData[1]?.[0] === "("
                    ? removeCommasFromNumbers(removeBrackets(splittedData[1]))
                    : removeCommasFromNumbers(splittedData[1]),
                type: "equation",
                label: item?.name,
                value: item?.name,
              });
            });
            getReportAccountSummaryHook(
              {
                reportId: query?.reportId,
              },
              (response) => {
                console.log("Account Summary", response);
                if (response) {
                  Object.keys(response)?.map((itemKey) => {
                    if (itemKey !== "null") {
                      console.log("Item Key", itemKey);
                      const childAccounts = response[itemKey];
                      const parentAccountType =
                        childAccounts[0]?.type === "income_item"
                          ? "income_header"
                          : "balance_header";
                      let previousPeriodTotal = 0;
                      let currentPeriodTotal = 0;
                      childAccounts?.forEach((childAccount) => {
                        alllPreviewList.push({
                          ...childAccount,
                          name: childAccount?.item,
                          label: childAccount?.item,
                          value: childAccount?.item,
                          current: childAccount?.currentPeriod
                            ? parseFloat(childAccount?.currentPeriod)
                            : 0,
                          previous: childAccount?.previousPeriod
                            ? parseFloat(childAccount?.previousPeriod)
                            : 0,
                        });
                        previousPeriodTotal += parseFloat(
                          childAccount?.previousPeriod
                        );
                        currentPeriodTotal += parseFloat(
                          childAccount?.currentPeriod
                        );
                      });
                      alllPreviewList.push({
                        name: itemKey,
                        type: parentAccountType,
                        current: currentPeriodTotal,
                        previous: previousPeriodTotal,
                        label: itemKey,
                        value: itemKey,
                      });
                    }
                  });
                }

                let itemAmountObj = {};
                const previewObj = groupBy(alllPreviewList, "type");
                console.log("Equation Obj", previewObj);
                const equationList = previewObj["equation"];
                itemAmountObj = getItemAcountObj(itemAmountObj, equationList);
                const incomeHeaderList = previewObj["income_header"];
                itemAmountObj = getItemAcountObj(
                  itemAmountObj,
                  incomeHeaderList
                );
                const balanceHeaderList = previewObj["balance_header"];
                itemAmountObj = getItemAcountObj(
                  itemAmountObj,
                  balanceHeaderList
                );
                const incomeItemList = previewObj["income_item"];
                itemAmountObj = getItemAcountObj(itemAmountObj, incomeItemList);
                const balanceItemList = previewObj["balance_item"];
                itemAmountObj = getItemAcountObj(
                  itemAmountObj,
                  balanceItemList
                );
                console.log("alllPreviewList", alllPreviewList);
                setPreviewObj({ ...previewObj });
                setItemAmountObj({ ...itemAmountObj });
                setPreviewData(previewList);
              }
            );
          }
        );
        const yearString =
          "current:{{current|financialPositionEndDate|year}}::previous:{{previous|financialPositionEndDate|year}}";
        getEquationPreviewHook(
          { reportId: query?.reportId, content: yearString },
          (response) => {
            console.log("Equation Preview Hook", response);
            const list = response?.split("::");
            let yearList = [];
            list?.forEach((yearData) => {
              const splittedData = yearData?.split?.(":");
              if (splittedData?.[1]) {
                const year = splittedData?.[1];
                yearList.push({
                  label: `${splittedData?.[0]} year - ${year}`,
                  value: splittedData?.[0],
                });
              }
            });
            setYearList(yearList);
          }
        );
      }
    );
  }, [type, isMakeAdjustmentDialogOpened]);

  const getItemAcountObj = (amountObj, list) => {
    list?.forEach((item) => {
      amountObj[item?.name] = {
        current: item?.current,
        previous: item.previous,
      };
    });
    return amountObj;
  };

  const openMakeAdjustmentDialog = (category) => {
    // setSelectedCategory(category);
    setIsMakeAdjustmentDialogOpened(true);
  };
  const closeMakeAdjustmentDialog = () => {
    // setSelectedCategory("");
    setIsMakeAdjustmentDialogOpened(false);
  };
  console.log("Preview 1", previewData, yearList);
  return (
    <section className={"right-comp2"}>
      <header
        className={"section-header"}
        style={{ height: "unset", minHeight: "unset", borderBottom: "unset" }}
      >
        {/* {!(type === "equation_item") && (
          <div
            className="preview-sheet-back-container"
            onClick={() => {
              setIsSheetPreviewEnabled(false);
            }}
          >
            <LeftOutlined />
            <span style={{ marginLeft: "11.5px" }}>Back to Mapping</span>
          </div>
        )} */}
        <div
          className={"header-right"}
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div className="preview-sheet-title-container">{}</div>
          <div
            onClick={() => {
              setSelectedEquationItem(null);
              openMakeAdjustmentDialog();
            }}
            style={{ cursor: "pointer" }}
          >
            <PlusOutlined
              style={{ position: "relative", top: "2px", marginRight: "5px" }}
            />
            New
          </div>
        </div>
      </header>
      <main className={"section-body"} style={{ padding: "10px 0px" }}>
        <div className="preview-sheet-data-table-header">
          <div
            className="preview-sheet-header-title"
            style={{ fontWeight: "700" }}
          >
            Name
          </div>
          <div
            className="preview-sheet-data-period preview-sheet-header-period"
            style={{ fontSize: "14px" }}
          >
            {currentYear}
          </div>
          <div
            className="preview-sheet-data-period preview-sheet-header-period"
            style={{ fontSize: "14px" }}
          >
            {previousYear}
          </div>
          <div
            className="preview-sheet-data-period preview-sheet-header-period"
            style={{ fontSize: "14px", width: "10%", marginRight: "20px" }}
          ></div>
        </div>

        {previewData.map((item) => {
          return (
            <div className="preview-sheet-data-item-container">
              <Tooltip
                title={
                  <div style={{ cursor: "pointer" }}>{item?.equation}</div>
                }
              >
                <div className="preview-sheet-data-title">{item?.name}</div>
              </Tooltip>

              <div className="preview-sheet-data-period">
                {/* <Tooltip
                  title={
                    <div style={{ cursor: "pointer" }}>{item?.description}</div>
                  }
                > */}
                <span className="ellipsis-text" style={{ textAlign: "right" }}>
                  {" "}
                  {item?.current}
                </span>
                {/* </Tooltip> */}
              </div>
              <div className="preview-sheet-data-period">
                {/* <Tooltip
                  title={
                    <div style={{ cursor: "pointer" }}>{item?.equation}</div>
                  }
                > */}
                <span className="ellipsis-text" style={{ textAlign: "right" }}>
                  {" "}
                  {item?.previous}
                </span>
                {/* </Tooltip> */}
              </div>
              <div
                className="preview-sheet-data-period"
                style={{ width: "10%", marginRight: "20px", cursor: "pointer" }}
              >
                <EditIcon
                  onClick={() => {
                    setSelectedEquationItem(item);
                    setIsMakeAdjustmentDialogOpened(true);
                  }}
                />
              </div>
            </div>
          );
        })}
      </main>
      {isMakeAdjustmentDialogOpened && (
        <CreateEquationModal
          open={isMakeAdjustmentDialogOpened}
          handleClose={closeMakeAdjustmentDialog}
          type={type}
          categoryName={selectedCategory}
          previewData={previewData}
          setPreviewData={setPreviewData}
          yearList={yearList}
          previewObj={previewObj}
          itemAmountObj={itemAmountObj}
          selectedEquationItem={selectedEquationItem}
        />
      )}
    </section>
  );
};

export default EquationPreviewSheet;
