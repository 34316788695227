import MainContainer from "../../sub-components/MainContainer";

import Lottie from "lottie-react";

import comingSoonAnimation from "../../assets/lottieFiles/lottieHourGlass.json";

const ReportDashboard = () => {
  return (
    <MainContainer>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Lottie
          animationData={comingSoonAnimation}
          loop={true}
          style={{ height: "100%", width: "300px" }}
        />
      </div>
    </MainContainer>
  );
};

export default ReportDashboard;
