import React from "react";

import "./CreateOrEditReport.less";

const Step = ({
  stepNumber,
  isCompleted,
  name,
  isProgress,
  onClick,
  isCurrentSelected,
}) => {
  let stepNumberStyle = {};
  let titleStyle = {};
  if (isCurrentSelected) {
    stepNumberStyle = {
      color: "#fff",
      backgroundColor: "#4BB354",
    };
    titleStyle = {
      color: "#4BB354",
    };
  }
  return (
    <div onClick={onClick} style={{ cursor: "pointer" }}>
      <div className={"steps-step-number"} style={stepNumberStyle}>
        {stepNumber}
      </div>
      <div className={"steps-step-title"} style={titleStyle}>
        {name}
      </div>
    </div>
  );
};

export default Step;
