import React from "react";
import { Button, Modal } from "antd";
import ComponentButton from "../ComponentButton";

import "./ComponentModal.css";
const ComponentModal = ({
  open,
  handleOk,
  handleClose,
  backText,
  submitText,
  title,
  children,
  width,
  footer
}) => {
  return (
    <Modal
      visible={open}
      title={title}
      style={{ borderRadius: "8px" }}
      width={width}
      onOk={handleOk}
      onCancel={handleClose}
      footer={footer === null ? footer: [
        <Button key="back" onClick={handleClose}>
          {backText}
        </Button>,
        <ComponentButton title={submitText} onClick={handleOk} />,
      ]}
    >
      {children}
    </Modal>
  );
};

export default ComponentModal;
