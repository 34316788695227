import React from "react";
import ComponentButton from "../../atoms/ComponentButton";
import "./balance.less";
// import newTemplateIcon from "../images/duplicate.png";

import StatementChangesTable from "./StatementChangesTable";

const fileAttributes = {
  fileType: "Statement: Changes in Equity",
};

const StatementChangesInEquity = ({
  selectedStatement,
  balanceSheetData,
  incomeSheetData,
  checkedData,
  currentFileName,
}) => {
  return (
    <section className={"right-comp2"}>
      <header className={"section-header"}>
        <h2>Map Items</h2>
      </header>
      <main className={"section-body"}>
        <div className={"file-attributes"}>
          <span
            className={"each-attribute"}
          >{`Statement: ${fileAttributes.fileType}`}</span>
        </div>
        <StatementChangesTable currentFileName={currentFileName} />
      </main>
    </section>
  );
};

export default StatementChangesInEquity;
