import { createSlice } from "@reduxjs/toolkit";
// Slice
const yearSlice = createSlice({
  name: "year",
  initialState: {
    years: [],
  },
  reducers: {
    setYears: (state, action) => {
      state.years = action.payload;
    },
  },
});

export default yearSlice.reducer;
// Actions
export const { setYears } = yearSlice.actions;
