import { message } from "antd";
import { deleteReportDrive } from "../../api-hooks/report";
import ComponentModal from "../../atoms/ComponentModal";

const DeleteReportFileModal = ({
  open,
  handleClose,
  selectedId,
  fetchData,
}) => {
  const onOkHandler = () => {
    deleteReportDrive(selectedId, (response) => {
      if (response) {
        fetchData();
        handleClose();
        message.success("Report drive deleted successfully");
      }
    });
  };

  return (
    <ComponentModal
      open={open}
      handleClose={handleClose}
      backText="No, Cancel"
      title="Delete This File"
      width={592}
      handleOk={onOkHandler}
      submitText="Yes, Delete"
      footer={true}
    >
      <div style={{ fontSize: "14px", lineHeight: "20px" }}>
        Are you sure you want to disable this account? This action can not be
        undone.
      </div>
    </ComponentModal>
  );
};

export default DeleteReportFileModal;
