import { PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ComponentButton from "../../atoms/ComponentButton";
import ComponentDropdown from "../../atoms/ComponentDropdown";
import ComponentSearch from "../../atoms/ComponentSearch";
import ContentCard from "../../atoms/ContentCard";
import ComponentInput from "../../atoms/ComponentInput";
import Editor from "../../sub-components/Editor";
import { useRouter } from "../../routes";
import { setCompaniesWithReports } from "../../store/company";
import { setYears } from "../../store/year";
import LeftControl from "../../sub-components/LeftControl";
import MainContainer from "../../sub-components/MainContainer";
import PreviousReportModal from "../../sub-components/PreviousReportModal";
import RightControl from "../../sub-components/RightControl";
import SaveReportModal from "../../sub-components/SaveReportModal";
import { ReactComponent as EditIcon } from "../../assets/svg-icons/editIcon.svg";
import AddVersionModal from "../../sub-components/AddVersionModal";
import "./NoteTemplate.less";
import { duplicateReportHook } from "../../api-hooks/report";
import { Button, message, notification, Tooltip } from "antd";
import { changePdfCollapsedState } from "../../store/user";
import NoteExpandable from "./NoteExpandable";
import NoteCreateNewTemplate from "./NoteCreateNewTemplate";
import DeleteTemplateItemModal from "../../sub-components/DeleteTemplateItemModal";
import {
  createSubSectionHook,
  getNoteTemplateHook,
  createSectionHook,
  updateNoteTemplateSubCategoryHook,
  updateNoteTemplateCategoryHook,
  deleteNoteTemplateCategoryHook,
  deleteSectionVersionHook,
  deleteNoteTemplateSubCategoryHook,
  createSectionVersionHook,
  createSubSectionVersionHook,
  updateSubSectionVersionHook,
  updateSectionVersionHook,
} from "../../api-hooks/note-templates";
import { ReactComponent as TrashIcon } from "../../assets/svg-icons/trashIcon.svg";
import PopupItem from "../../atoms/ComponentPopup/PopupItem";
import ComponentPopup from "../../atoms/ComponentPopup";
import { orderBy } from "lodash";
import { sortReportSections } from "../../helper";

const SelectType = [
  { key: "type", value: "All Type" },
  { key: "notes", value: "notes" },
  { key: "text", value: "text" },
];
const NoteTemplate = ({}) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const navigate = useNavigate();
  const [noteTemplateData, setNoteTemplateData] = useState();
  const [noteTemplateStore, setNoteTemplateStore] = useState();
  let list = [];
  let expandList = [];
  const [noteTemplateTextArea, setNoteTemplateTextArea] = useState("");
  // const [isCollapsed, setIsCollapsed] = useState(false);
  const [selectedTypeValue, setSelectedTypeValue] = useState("All Type");
  const [showEditNoteTemplate, setShowEditNoteTemplate] = useState(false);
  const [showNoteTemplateId, setShowNoteTemplateId] = useState("");
  const [showNoteTemplateSubId, setShowNoteTemplateSubId] = useState("");
  const [sectionTitle, setSectionTitle] = useState("");
  const [subSectionTitle, setSubSectionTitle] = useState("");
  const [itemType, setItemType] = useState("");
  const [noteSectionIndex, setNoteSectionIndex] = useState();
  const [noteSubSectionIndex, setNoteSubSectionIndex] = useState();
  const isCollapsed = useSelector((state) => state.user.isPdfCollapsed);
  const [selectedYear, setSelectedYear] = useState(null);
  const [viewSubSectionTitle, setViewSubSectionTitle] = useState(false);
  const [isPopupOpened, setIsPopupOpened] = useState(false);
  const [selectedVersionList, setSelectedVersionList] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [openAddVersionOpenModal, setOpenAddVersionModal] = useState(false);
  const [deleteAddVersionModal, setDeleteAddVersionModal] = useState(false);
  const [dropSelectSection, setDropSelectSection] = useState({});
  const [newItemType, setnewItemType] = useState("text");
  const [newSectionTitle, setNewSectionTitle] = useState("");
  const [newSubSectionTitle, setNewSubSectionTitle] = useState("");
  const [newVersion, setNewVersion] = useState("");
  const [newTextData, setNewTextData] = useState("");
  const [isVersionEditEnabled, setIsVersionEditEnabled] = useState(false);
  const [isOpenCreateNewSection, setIsOpenCreateNewSection] = useState(false);
  const [isNewCreateSubSection, setIsNewCreateSubSection] = useState(false);
  const [subSectionRequiredName, setSubSectionRequiredName] = useState("");
  const [deleteSectionsWarningModal, setDeleteSectionWarningModal] =
    useState(false);
  const [deleteSubSectionsWarningModal, setDeleteSubSectionWarningModal] =
    useState(false);
  const [deleteWarningModalName, setDeleteWarningModalName] = useState("");

  const years = useSelector((state) => state.year.years);
  const companiesWithReports = useSelector(
    (state) => state.company.companiesReports
  );
  const getSelectTypeDropdown = () => {
    SelectType?.forEach((item) => {
      list.push({
        value: item?.value,
      });
    });

    return list;
  };
  const closeDeleteSectionWarningModal = () => {
    setDeleteSectionWarningModal(false);
    setDeleteSubSectionWarningModal(false);
  };
  const closeOpenVaersionModal = () => {
    setDeleteAddVersionModal(false);
  };
  const getSectionSelectDropdown = () => {
    let sectionDropdownList = [];
    let selectedVal = selectedTypeValue;
    noteTemplateData?.map((item, itemIndex) => {
      console.log("full item list", item?.name);
      {
        (selectedVal === "All Type" || selectedVal === item.type) && (
          <>
            {sectionDropdownList.push({
              value: item?.id,
              label: item?.name,
            })}
            {/* {dropSelectSection[]

          } */}
          </>
        );
      }
    });
    return sectionDropdownList;
  };
  const reports = useSelector((state) => state.report.reports);
  const versionCloseModalTemplate = () => {
    console.log("create selected version", selectedVersion.id);
    if (!selectedVersion.id) {
      setSelectedVersion(selectedVersionList[0]);
    }
    setOpenAddVersionModal(false);
  };
  const viewNoteItemTemplate = (item, index, subItem, subIndex) => {
    setNoteSectionIndex(index);
    setSectionTitle(item?.name);
    setItemType(item.type);
    setNoteSubSectionIndex(subIndex);
    setShowEditNoteTemplate(true);
    setIsOpenCreateNewSection(false);
    setShowNoteTemplateId(item.id);
    setShowNoteTemplateSubId(subItem?.id);
    setSubSectionTitle(subItem?.data?.name);
    setNoteTemplateTextArea(subItem?.data?.html);
    if (subItem) {
      setSelectedVersionList(subItem?.templateVersions);
      setSelectedVersion(subItem?.templateVersions?.[0]);
      setNoteTemplateTextArea(subItem?.templateVersions?.[0]?.data?.html);
    } else if (!subItem) {
      setSelectedVersionList(item?.versions);
      setSelectedVersion(item?.versions?.[0]);
      setNoteTemplateTextArea(item?.versions?.[0]?.data?.html);
    }
  };
  const newViewNoteItemTemplate = (item) => {
    setShowEditNoteTemplate(true);
    setIsOpenCreateNewSection(false);
    setShowNoteTemplateId(item.id);
    setSectionTitle(item?.name);
    setItemType(item.type);
  };
  useEffect(() => {
    if (years?.length && !selectedYear) {
      setSelectedYear(years[0]);
    }
  }, [years?.length]);

  useEffect(() => {
    getNoteTemplateHook((response) => {
      setNoteTemplateData(response);
      setNoteTemplateStore(response);
    });
  }, []);

  const setIsCollapsed = (value) => {
    dispatch(changePdfCollapsedState(value));
  };

  console.log("sectionTitle", noteTemplateData);

  const onAddSubSection = (categoryId, item, index, values) => {
    console.log("Visiblity Index Item", item);
    createSubSectionHook(
      {
        sectionId: categoryId,
        visibleIndex: item?.templates?.length,
        name: values?.name,
      },
      (response) => {
        createSubSectionVersionHook(
          {
            reportTemplateId: response.id,
            name: values?.versionName,
            htmlData: values?.newTextData,
          },
          (versionResponse) => {
            getNoteTemplateHook((allResponse) => {
              response.templateVersions.push(versionResponse);

              setViewSubSectionTitle(true);
              viewNoteItemTemplate(item, index, response);
              setNoteTemplateData(allResponse);
              setNewSectionTitle("");
              setNewSubSectionTitle("");
              setNewVersion("");
              setNewTextData("");
              notification.success({
                message: "Sub Section created Successfully!",
              });
            });
            // response.templateVersions.push(versionResponse);
            // item.templates.push(response);
            // noteTemplateData.splice(index, 1, item);
            // setNoteTemplateData([...noteTemplateData]);
          }
        );
      }
    );
  };
  const closeCreateNewTemplate = () => {
    setIsOpenCreateNewSection(false);
  };
  const addNewNoteTemplate = (itemName) => {
    var noteTemplateLength = noteTemplateData.length;
    createSectionHook(
      {
        ...itemName,
        typeName: itemName.type,
        indexValue: noteTemplateLength,
      },
      (response) => {
        console.log("New Section Created Successfully", response);
        var item = response.data;
        createSectionVersionHook(
          {
            reportTemplateCategoryId: item.id,
            name: itemName.versionName,
            htmlData: itemName?.newTextData,
          },
          (response) => {
            item.versions.push(response);
            noteTemplateData.push(item);
            setNoteTemplateData([...noteTemplateData]);
            newViewNoteItemTemplate(item);
            // setSelectedVersionList(item.versions);
            // console.log(
            //   "item?.versions[0]?.id",
            //   item?.versions[0]?.id,
            //   item.versions
            // );
            // setSelectedVersion(item?.versions[0]?.id);
            viewNoteItemTemplate(item);
            setViewSubSectionTitle(false);
            setNewSectionTitle("");
            setNewSubSectionTitle("");
            setNewVersion("");
            setNewTextData("");
            notification.success({
              message: "  Section created Successfully!",
            });
          }
        );
      }
    );
    setIsOpenCreateNewSection(false);
  };
  const updateNoteTemplate = () => {
    const values = {
      name: sectionTitle,
      id: showNoteTemplateId,
      versionId: selectedVersion?.id,
      html: noteTemplateTextArea,
      versionName: selectedVersion?.name,
    };
    updateNoteTemplateCategoryHook(values, (response) => {
      console.log("patch occurs title updated", response);
      // console.log("test new update", noteTemplateData[noteSectionIndex].name);
      // noteTemplateData[noteSectionIndex].name = response.name;
      // setNoteTemplateData([...noteTemplateData]);
      getNoteTemplateHook((response) => {
        console.log("Note Summary", response);
        setNoteTemplateData(response);
        notification.success({
          message: " Section Updated Successfully!",
        });
      });
    });
  };
  const updateSubNoteTemplate = () => {
    console.log("Sub Section Update", subSectionTitle, showNoteTemplateSubId);
    const values = {
      name: subSectionTitle,
      id: showNoteTemplateSubId,
      html: noteTemplateTextArea,
      versionId: selectedVersion?.id,
      versionName: selectedVersion?.name,
      sectionId: showNoteTemplateId,
    };
    updateNoteTemplateSubCategoryHook(values, (response) => {
      console.log("patch called", response);
      getNoteTemplateHook((response) => {
        console.log("Note Summary", response);
        setNoteTemplateData(response);
        notification.success({
          message: "Sub Section Updated Successfully!",
        });
      });
      // noteTemplateData[noteSectionIndex].templates[noteSubSectionIndex].data =
      //   response;
      // setNoteTemplateData([...noteTemplateData]);
      // notification.success({
      //   message: "Sub Section Updated Successfully!",
      // });
    });
  };
  const deleteVersion = () => {
    setDeleteAddVersionModal(false);
    setOpenAddVersionModal(false);
    const values = {
      id: selectedVersion.id,
    };
    deleteSectionVersionHook(values, (response) => {
      selectedVersionList.map((item, itemIndex) => {
        if (item?.id === selectedVersion.id) {
          selectedVersionList.splice(itemIndex, 1);
        }
        setSelectedVersionList([...selectedVersionList]);
        if (selectedVersionList?.length) {
          setSelectedVersion(selectedVersionList[0]);
        }
      });
    });
  };
  const deleteNoteTemplate = () => {
    setDeleteSectionWarningModal(false);
    console.log("Show Template Id", showNoteTemplateId);
    const values = {
      id: showNoteTemplateId,
    };
    deleteNoteTemplateCategoryHook(values, (response) => {
      console.log("delete called", response);
      noteTemplateData.splice(noteSectionIndex, 1);
      setNoteTemplateData([...noteTemplateData]);
      setShowEditNoteTemplate(false);
      setNoteTemplateTextArea("");
      notification.success({
        message: " Section deleted Successfully!",
      });
    });
  };
  const deleteNoteSubTemplate = () => {
    setDeleteSubSectionWarningModal(false);
    const values = {
      id: showNoteTemplateSubId,
    };
    deleteNoteTemplateSubCategoryHook(values, (response) => {
      console.log("delete called sub category deleted", response);
      noteTemplateData[noteSectionIndex].templates.splice(
        noteSubSectionIndex,
        1
      );
      setNoteTemplateData([...noteTemplateData]);
      setViewSubSectionTitle(false);
      setShowEditNoteTemplate(false);
      setNoteTemplateTextArea("");
      notification.success({
        message: " Sub Section deleted Successfully!",
      });
    });
  };
  const VersionAddUpdate = () => {
    setOpenAddVersionModal(false);
    if (showNoteTemplateSubId) {
      if (!selectedVersion?.id) {
        createSubSectionVersionHook(
          {
            name: selectedVersion?.name,
            reportTemplateId: showNoteTemplateSubId,
          },
          (response) => {
            setSelectedVersion(response);
            selectedVersionList.push(response);
            console.log("Selected Version List", selectedVersionList);
            setSelectedVersionList([...selectedVersionList]);
            setNoteTemplateTextArea(response?.data?.html);
          }
        );
      } else {
        updateSubSectionVersionHook(
          {
            name: selectedVersion?.name,
            reportTemplateId: showNoteTemplateSubId,
            html: noteTemplateTextArea,
            id: selectedVersion?.id,
          },
          (response) => {
            setSelectedVersion(response);
            let list = [];
            selectedVersionList?.forEach((version) => {
              if (version?.id === selectedVersion?.id) {
                list.push({
                  ...version,
                  name: response?.name,
                });
              } else {
                list.push({
                  ...version,
                });
              }
            });
            setSelectedVersionList(list);

            setNoteTemplateTextArea(response?.data?.html);
          }
        );
      }
    } else if (showNoteTemplateId && !showNoteTemplateSubId) {
      if (!selectedVersion?.id) {
        createSectionVersionHook(
          {
            name: selectedVersion?.name,
            reportTemplateCategoryId: showNoteTemplateId,
          },
          (response) => {
            setSelectedVersion(response);
            selectedVersionList.push(response);
            setSelectedVersionList([...selectedVersionList]);
            setNoteTemplateTextArea(response?.data?.html);
          }
        );
      } else {
        updateSectionVersionHook(
          {
            name: selectedVersion?.name,
            reportTemplateCategoryId: showNoteTemplateId,
            html: noteTemplateTextArea,
            id: selectedVersion?.id,
          },
          (response) => {
            setSelectedVersion(response);
            let list = [];
            selectedVersionList?.forEach((version) => {
              if (version?.id === selectedVersion?.id) {
                list.push({
                  ...version,
                  name: response?.name,
                });
              } else {
                list.push({
                  ...version,
                });
              }
            });
            console.log("FInal List", list);

            setSelectedVersionList(list);
            setNoteTemplateTextArea(response?.data?.html);
          }
        );
      }
    }
    // onClick={() => {
    //   setIsVersionEditEnabled(false);

    // }}
  };

  const getVersionDropdown = () => {
    const dropdownList = [];
    selectedVersionList?.forEach((item) => {
      dropdownList.push({
        label: item?.name,
        value: item.id,
        ...item,
      });
    });
    return dropdownList;
  };

  const getAscendingTemplates = (templates) => {
    return orderBy(templates, ["visibleIndex"], ["asc"]);
  };

  const onFilterData = (searchValue = "") => {
    if (searchValue) {
      const newNoteTemplateList = [];
      noteTemplateStore &&
        noteTemplateStore?.map((item, index) => {
          if (item?.name?.toLowerCase()?.includes(searchValue?.toLowerCase())) {
            newNoteTemplateList.push(item);
          }
          if (item?.templates) {
            let templetList = [];
            item?.templates?.map((subItems) => {
              if (
                subItems?.data?.name
                  ?.toLowerCase()
                  ?.includes(searchValue?.toLowerCase())
              ) {
                templetList.push(subItems);
                const itemData = {
                  ...item,
                  templates: templetList,
                };
                newNoteTemplateList.push(itemData);
              }
            });
          }
        });
      setNoteTemplateData([...newNoteTemplateList]);
    } else {
      setNoteTemplateData(noteTemplateStore);
    }
  };

  return (
    <MainContainer>
      <div className="noteTemplateBreadrumb">
        <p>Home</p>
        <p>/</p>
        <p>Accounting</p>
        <p>/</p>
        <p>
          <b>Note Template</b>
        </p>
      </div>
      <div className="finacial-report-container note-template-height-setter">
        <LeftControl isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed}>
          <ContentCard
            style={{ padding: 32, height: "100%", background: "none" }}
            bodyStyle={{ padding: 0 }}
            size="large"
          >
            <div
              className="note-template-addnew"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div className={"saved-cards-heading"}>Note Template</div>
              <div
                style={{ position: "relative" }}
                className="tolltip-type-select"
              >
                <ComponentPopup
                  trigger={
                    <ComponentButton
                      title="New"
                      icon={<PlusOutlined />}
                      onClick={() => {
                        setIsPopupOpened(true);
                      }}
                    />
                  }
                  handleClose={() => {
                    console.log("On Handle close");
                    setIsPopupOpened(false);
                  }}
                  title=""
                  open={isPopupOpened}
                >
                  <div style={{ width: "150px" }}>
                    {/* <PopupItem name="Save as new version" Icon={CopyOutlined} />
          <PopupItem name="Approve as final version" Icon={CheckCircleFilled} /> */}
                    <PopupItem
                      name="Notes"
                      onClick={() => {
                        setIsOpenCreateNewSection(true);
                        setShowEditNoteTemplate(false);
                        setnewItemType("notes");
                        setIsNewCreateSubSection(false);
                        // addNewNoteTemplate("notes");
                      }}
                    />
                    <PopupItem
                      name="Text"
                      onClick={() => {
                        setIsOpenCreateNewSection(true);
                        setShowEditNoteTemplate(false);
                        // addNewNoteTemplate("text");
                        setnewItemType("text");
                        setIsNewCreateSubSection(false);
                      }}
                    />
                  </div>
                </ComponentPopup>

                {/* <
                  placement="bottom"
                  title={
                    <>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          addNewNoteTemplate("notes");
                        }}
                      >
                        Notes
                      </div>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          addNewNoteTemplate("text");
                        }}
                      >
                        Text
                      </div>
                    </>
                  }
                >
                  <div
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      left: "0",
                      top: "0",
                    }}
                  ></div>
                </Tooltip> */}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "29px",
              }}
              className="components-style"
            >
              <ComponentDropdown
                title="Select financial year"
                optionList={getSelectTypeDropdown()}
                value={selectedTypeValue}
                handleChange={(value) => {
                  setSelectedTypeValue(value);
                }}
                style={{ width: "100%" }}
              />
              <ComponentSearch
                placeholder="Keywords"
                style={{ width: "100%", marginTop: "15px" }}
                allowClear
                onSearch={(value, event) => {
                  onFilterData(value);
                }}
              />
            </div>
            <div style={{ marginTop: "45px" }}>
              {noteTemplateData &&
                noteTemplateData?.map((item, index) => {
                  let selectedVal = selectedTypeValue;
                  let style = {};
                  if (
                    item?.id === showNoteTemplateId &&
                    !showNoteTemplateSubId
                  ) {
                    style = {
                      // backgroundColor: "#F5FBF8",
                      // border: "0.5px solid #208460",
                      color: "#3D8E44",
                    };
                  }
                  return (
                    <Fragment key={index}>
                      {(selectedVal === "All Type" ||
                        selectedVal === item.type) && (
                        <div className="new-note-template">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              flexDirection: "column",
                              position: "relative",
                            }}
                          >
                            <div
                              className="item-type-name"
                              style={{ ...style }}
                            >
                              <span>{item.type}</span>
                              <div
                                style={{
                                  cursor: "pointer",
                                  paddingRight: "40px",
                                  ...style,
                                }}
                                onClick={() => {
                                  setViewSubSectionTitle(false);
                                  viewNoteItemTemplate(item, index);
                                }}
                              >
                                {item.name}
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <span
                                style={{
                                  cursor: "pointer",
                                  position: "absolute",
                                  top: "0px",
                                  right: "20px",
                                }}
                                onClick={() => {
                                  setIsNewCreateSubSection(true);
                                  setIsOpenCreateNewSection(true);
                                  setShowEditNoteTemplate(false);
                                  const sectionItem = { ...item };
                                  sectionItem.index = index;
                                  setSubSectionRequiredName(sectionItem);
                                  //add sub category
                                  // onAddSubSection(item?.id, item);
                                }}
                              >
                                <PlusOutlined />
                              </span>
                              {
                                <NoteExpandable
                                  item={item}
                                  noteTemplateData={noteTemplateData}
                                  setNoteTemplateData={setNoteTemplateData}
                                  index={index}
                                  setViewSubSectionTitle={
                                    setViewSubSectionTitle
                                  }
                                  viewNoteItemTemplate={viewNoteItemTemplate}
                                  showNoteTemplateSubId={showNoteTemplateSubId}
                                />
                              }
                            </div>
                          </div>
                          {/* <div>
                            {item.templates?.map((subItem, subIndex) => {
                              // setNoteSubSectionIndex(subIndex);
                              if (subItem.data.name) {
                                return (
                                  <>
                                    <div
                                      className="subitem-name"
                                      onClick={() => {
                                        setViewSubSectionTitle(true);
                                        viewNoteItemTemplate(
                                          item,
                                          index,
                                          subItem,
                                          subIndex
                                        );
                                      }}
                                    >
                                      {subItem.data.name}
                                    </div>
                                  </>
                                );
                              }
                            })}
                          </div> */}
                        </div>
                      )}
                    </Fragment>
                  );
                })}
            </div>
          </ContentCard>
        </LeftControl>
        <RightControl
          isPreview
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
        >
          <ContentCard
            style={{ padding: "0px 32px 32px", height: "100%" }}
            bodyStyle={{
              padding: 0,
              height: "inherit",
              display: "flex",
              flexDirection: "column",
            }}
            size="large"
          >
            {isOpenCreateNewSection && (
              <NoteCreateNewTemplate
                newItemType={newItemType}
                setNewSectionTitle={setNewSectionTitle}
                newSectionTitle={newSectionTitle}
                setNewVersion={setNewVersion}
                newVersion={newVersion}
                setNewTextData={setNewTextData}
                newTextData={newTextData}
                addNewNoteTemplate={addNewNoteTemplate}
                closeCreateNewTemplate={closeCreateNewTemplate}
                isNewCreateSubSection={isNewCreateSubSection}
                setNewSubSectionTitle={setNewSubSectionTitle}
                newSubSectionTitle={newSubSectionTitle}
                subSectionRequiredName={subSectionRequiredName}
                onAddSubSection={onAddSubSection}
              />
            )}
            {showEditNoteTemplate && (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    className="note-edit-template"
                    style={{ paddingRight: "32px" }}
                  >
                    <div className="note-template-title">Section</div>
                    {viewSubSectionTitle && (
                      <ComponentDropdown
                        title="Select Section"
                        optionList={getSectionSelectDropdown()}
                        value={showNoteTemplateId}
                        handleChange={(value) => {
                          setShowNoteTemplateId(value);
                          console.log("value final", value);
                        }}
                        style={{ width: "100%" }}
                      />
                    )}
                    {!viewSubSectionTitle && (
                      <ComponentInput
                        placeholder="Section Title"
                        value={sectionTitle}
                        onChange={(value) => {
                          setSectionTitle(value);
                        }}
                      />
                    )}

                    {viewSubSectionTitle && (
                      <>
                        <div
                          className="note-template-title"
                          style={{ paddingTop: "20px" }}
                        >
                          Sub-Section Title
                        </div>
                        <ComponentInput
                          placeholder="Sub Section Title"
                          value={subSectionTitle}
                          onChange={(value) => {
                            setSubSectionTitle(value);
                          }}
                        />
                      </>
                    )}
                    <div
                      className="note-template-title"
                      style={{ paddingTop: "20px" }}
                    >
                      Main Body
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        // justifyContent: "space-between",
                      }}
                    >
                      {!isVersionEditEnabled && (
                        <ComponentDropdown
                          placeholder="version1"
                          style={{ maxWidth: "216px", minWidth: "181px" }}
                          value={selectedVersion?.id}
                          optionList={getVersionDropdown()}
                          handleChange={(value, option) => {
                            console.log("Valuye", value);
                            selectedVersionList?.forEach((item) => {
                              if (item?.id === value) {
                                setSelectedVersion(item);
                                setNoteTemplateTextArea(item?.data?.html);
                              }
                            });
                          }}
                          dropdownRender={(menu) => (
                            <>
                              {menu}
                              {/* <Divider style={{ margin: "8px 0" }} />
                              <Space style={{ padding: "0 8px 4px" }}>
                                <Input
                                  placeholder="Please enter item"
                                  ref={inputRef}
                                  value={name}
                                  onChange={onNameChange}
                                /> */}
                              <Button
                                type="text"
                                icon={<PlusOutlined />}
                                onClick={() => {
                                  setSelectedVersion({});
                                  setOpenAddVersionModal(true);
                                  // setIsVersionEditEnabled(true);
                                }}

                                // onClick={addItem}
                              >
                                Add Version
                              </Button>
                              {/* </Space> */}
                            </>
                          )}
                        />
                      )}
                      {isVersionEditEnabled && (
                        <ComponentInput
                          value={selectedVersion?.name}
                          style={{ width: "fit-content" }}
                          onChange={(value) => {
                            console.log("asdascfd", value);
                            selectedVersion.name = value;
                            setSelectedVersion({ ...selectedVersion });
                          }}
                        />
                      )}
                      {!isVersionEditEnabled && (
                        <div
                          style={{ marginLeft: "10px" }}
                          onClick={() => {
                            setOpenAddVersionModal(true);
                            setDeleteWarningModalName("Version");
                          }}
                        >
                          <EditIcon />
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingTop: "20px",
                      }}
                    >
                      <span className="note-type">{itemType}</span>
                    </div>
                    <Editor
                      data={noteTemplateTextArea}
                      setData={setNoteTemplateTextArea}
                      type="coverPage"
                      hideEquation
                      hideSwitchBtn
                      defaultEnable
                      // onSave={onSave}
                      previewData={noteTemplateTextArea}
                    />
                  </div>
                  <div style={{width:'100%',paddingBottom:'30px'}}>
                    <div
                      className="footer-submit-section"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingRight: "32px",
                        
                      }}
                    >
                      {!viewSubSectionTitle && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setDeleteSectionWarningModal(true);
                            setDeleteWarningModalName("Section");
                            // deleteNoteTemplate();
                          }}
                        >
                          <TrashIcon />
                          <span>Delete Section</span>
                        </div>
                      )}
                      {viewSubSectionTitle && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setDeleteSubSectionWarningModal(true);
                            setDeleteWarningModalName("Sub Section");
                            // deleteNoteSubTemplate();
                          }}
                        >
                          <TrashIcon />
                          <span>Delete Sub-Section</span>
                        </div>
                      )}
                      {viewSubSectionTitle && (
                        <ComponentButton
                          title="Save"
                          onClick={() => {
                            updateSubNoteTemplate();
                          }}
                        />
                      )}
                      {!viewSubSectionTitle && (
                        <ComponentButton
                          title="Save"
                          onClick={() => {
                            updateNoteTemplate();
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
            {!showEditNoteTemplate && (
              <div
                style={{
                  height: "inherit",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {!isOpenCreateNewSection && <div>Note Template</div>}
              </div>
            )}
          </ContentCard>
        </RightControl>
        {openAddVersionOpenModal && (
          <AddVersionModal
            open={openAddVersionOpenModal}
            handleClose={versionCloseModalTemplate}
            setSelectedVersion={setSelectedVersion}
            selectedVersion={selectedVersion}
            handleOk={VersionAddUpdate}
            // deleteEditVersion={deleteVersion}
            selectedVersionList={selectedVersionList}
            setDeleteAddVersionModal={setDeleteAddVersionModal}
          />
        )}
        {deleteSectionsWarningModal && (
          <DeleteTemplateItemModal
            open={deleteSectionsWarningModal}
            handleClose={closeDeleteSectionWarningModal}
            confirmDelete={deleteNoteTemplate}
            deleteWarningModalName={deleteWarningModalName}
          />
        )}
        {deleteSubSectionsWarningModal && (
          <DeleteTemplateItemModal
            open={deleteSubSectionsWarningModal}
            handleClose={closeDeleteSectionWarningModal}
            confirmDelete={deleteNoteSubTemplate}
            deleteWarningModalName={deleteWarningModalName}
          />
        )}
        {deleteAddVersionModal && (
          <DeleteTemplateItemModal
            open={deleteAddVersionModal}
            handleClose={closeOpenVaersionModal}
            confirmDelete={deleteVersion}
            deleteWarningModalName={deleteWarningModalName}
          />
        )}
      </div>
    </MainContainer>
  );
};

export default NoteTemplate;
