import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getAccountTemplatesHook } from "../../api-hooks/account-templates";
import { setAccountTemplates } from "../../store/account-templates";

const AccountTemplatesHoc = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    getAccountTemplatesHook((response) => {
      dispatch(setAccountTemplates(response));
    });
  }, []);
  return children;
};

export default AccountTemplatesHoc;
