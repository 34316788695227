// import less
import ComponentModal from "../../atoms/ComponentModal";
import "./ChangeMappingLogicWarning.less";

const ChangeMappingLogicWarning = ({
  open,
  handleClose,
  applyMapping,
  duplicateReportId,
  selectedTemplateObj,
}) => {
  console.log("duplicateReportId", duplicateReportId);
  return (
    <ComponentModal
      open={open}
      handleClose={handleClose}
      handleOk={() => {
        applyMapping();
        handleClose();
      }}
      title="Override Mapping"
      backText="Cancel"
      submitText="Update Mapping"
    >
      <div className={"saved-delete-report-text-title"}>
        {duplicateReportId && !selectedTemplateObj?.templateId && (
          <span>
            Your current mapping logic is based on your duplicated report and
            will change if you apply this template. Do you wish to continue?
          </span>
        )}
        {selectedTemplateObj?.templateId && (
          <span>
            Your current mapping logic is based on selected template{" "}
            {selectedTemplateObj?.name} and will change if you apply this
            template. Do you wish to continue?
          </span>
        )}
      </div>
    </ComponentModal>
  );
};

export default ChangeMappingLogicWarning;
