import logo from "./logo.svg";
import "./App.css";

import axios from "axios";
import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import {
  HOME_URL,
  LOGIN_URL,
  SIGN_UP_URL,
  FINANCIAL_REPORT_URL,
  REPORT_DASHBOARD_URL,
  CREATE_FINANCIAL_REPORT_URL,
  NOTES_TEMPLATE_URL,
  EDIT_FINANCIAL_REPORT_URL,
  REPORT_DRIVE_URL,
} from "./routes";

import FinancialReport from "./components/FinancialReport";
import Login from "./components/Login";

import Signup from "./components/Signup";
import ReportDashboard from "./components/ReportDashboard";
import Auth from "./sub-components/Auth";
import CreateOrEditReport from "./components/CreateOrEditReport";
import NoteTemplate from "./components/NoteTemplate";
import ReportDrive from "./components/ReportDrive";

const App = () => {
  useEffect(() => {
    // var data = JSON.stringify({
    //   email: "admin@example.com",
    //   password: "!Welcome2022",
    // });
    // var config = {
    //   method: "post",
    //   url: "http://18.162.112.73/auth/login",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   data: data,
    // };
    // axios(config)
    //   .then(function (response) {
    //     console.log(JSON.stringify(response.data));
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
    // var data = JSON.stringify({
    //   email: "nik@example.com",
    //   name: "Admin",
    //   password: "!Welcome2022",
    // });
    // var config = {
    //   method: "post",
    //   url: "http://18.162.112.73/auth/register",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   data: data,
    // };
    // axios(config)
    //   .then(function (response) {
    //     console.log(JSON.stringify(response.data));
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  }, []);
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          exact
          element={
            <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
              <FinancialReport />
            </Auth>
          }
        />
        <Route
          path={CREATE_FINANCIAL_REPORT_URL}
          element={
            <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
              <CreateOrEditReport />
            </Auth>
          }
        />
        <Route
          path={EDIT_FINANCIAL_REPORT_URL}
          element={
            <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
              <CreateOrEditReport />
            </Auth>
          }
        />
        <Route
          path={FINANCIAL_REPORT_URL}
          element={
            <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
              <FinancialReport />
            </Auth>
          }
        />
        <Route
          path={FINANCIAL_REPORT_URL}
          element={
            <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
              <FinancialReport />
            </Auth>
          }
        />
        <Route
          path={LOGIN_URL}
          element={
            <Auth allow="$unauthenticated" redirectTo={FINANCIAL_REPORT_URL}>
              <Login />
            </Auth>
          }
        />
        <Route
          path={SIGN_UP_URL}
          element={
            <Auth allow="$unauthenticated" redirectTo={FINANCIAL_REPORT_URL}>
              <Signup />
            </Auth>
          }
        />
        <Route
          path={REPORT_DASHBOARD_URL}
          element={
            <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
              <ReportDashboard />
            </Auth>
          }
        />
        <Route
          path={NOTES_TEMPLATE_URL}
          element={
            <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
                <NoteTemplate/>
            </Auth>
          }
        />
        <Route
          path={REPORT_DRIVE_URL}
          element={
            <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
              <ReportDrive />
            </Auth>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
