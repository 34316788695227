import api from "./api-intercepter";

export const createAdjustmentsHook = (values, callback) => {
  const data = JSON.stringify({
    report: {
      id: values?.reportId,
    },
    type: "adjustment",
    ledgerEntries: values?.ledgerEntries,
    //   {
    //     entryType: "credit",
    //     amount: 9999999999,
    //     account: {
    //       id: "50d17149-639a-4b7b-9431-ded4dd4fbde5",
    //     },
    //   },
    //   {
    //     entryType: "debit",
    //     amount: 9999999999,
    //     account: {
    //       id: "392b9c57-8cb2-4c3e-8230-aa8c0ba314cd",
    //     },
    //   },
  });

  api
    .post("/transactions", data)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};
