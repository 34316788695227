import { sortBy } from "lodash";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getReportTemplatesByTypeHook } from "../../api-hooks/report-templates";
import {
  setNotesTemplates,
  setTextTemplates,
} from "../../store/report-templates";

const ReportSectionsHook = ({ children, type }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    getReportTemplatesByTypeHook(type, "Lify Company Limited", (response) => {
      console.log("Report Templates", response, type);
      if (type === "text") {
        let templateCategoryList = [];
        response?.forEach((item) => {
          let sortedTemplatesList = sortBy(item?.templates, [
            function (o) {
              return o.visibleIndex;
            },
          ]);
          item.templates = sortedTemplatesList;
          templateCategoryList.push(item);
        });
        templateCategoryList = sortBy(templateCategoryList, [
          function (o) {
            return o.visibleIndex;
          },
        ]);
        dispatch(setTextTemplates(templateCategoryList));
      } else if (type === "notes") {
        let templateCategoryList = [];
        response?.forEach((item) => {
          let sortedTemplatesList = sortBy(item?.templates, [
            function (o) {
              return o.visibleIndex;
            },
          ]);
          item.templates = sortedTemplatesList;
          templateCategoryList.push(item);
        });
        templateCategoryList = sortBy(templateCategoryList, [
          function (o) {
            return o.visibleIndex;
          },
        ]);
        dispatch(setNotesTemplates(templateCategoryList));
      }
    });
  }, [type]);
  return children;
};

export default ReportSectionsHook;
