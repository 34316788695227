import React, { useState } from "react";
import { useNavigate } from "react-router";
import {
  FileOutlined,
  EllipsisOutlined,
  CopyOutlined,
  CheckCircleFilled,
  DeleteOutlined,
} from "@ant-design/icons";

import ComponentPopup from "../../atoms/ComponentPopup";
import PopupItem from "../../atoms/ComponentPopup/PopupItem";
import DeleteReportModal from "../../sub-components/DeleteReportModal";

import { ReactComponent as EditIcon } from "../../assets/svg-icons/editIcon.svg";
import RenameReportModal from "../../sub-components/RenameReportModal";

const DraftItem = ({ draftItem, onSelect }) => {
  const [isPopupOpened, setIsPopupOpened] = useState(false);
  const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState(false);
  const [isRenameDialogOpened, setIsRenameDialogOpened] = useState(false);
  const navigate = useNavigate();
  return (
    <div
      className={"saved-draft-item-container"}
      onClick={() => {
        onSelect(draftItem?.id, draftItem?.name);
      }}
    >
      <FileOutlined color="#C1C4D6" />
      <div style={{ marginLeft: "9.5px", flex: "1 1 auto" }}>
        {draftItem?.name}
      </div>
      <ComponentPopup
        trigger={
          <div
            className={"saved-draft-item-menu"}
            onClick={() => {
              setIsPopupOpened(true);
            }}
          >
            <EllipsisOutlined />
          </div>
        }
        handleClose={() => {
          console.log("On Handle close");
          setIsPopupOpened(false);
        }}
        title="SELECT ACTION"
        open={isPopupOpened}
      >
        <div>
          {/* <PopupItem name="Save as new version" Icon={CopyOutlined} />
          <PopupItem name="Approve as final version" Icon={CheckCircleFilled} /> */}
          <PopupItem
            name="Edit"
            Icon={EditIcon}
            // isDelete
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              navigate(
                `/financial-report/create?reportId=${draftItem?.id}&step=2`
              );
              // navigate(
              //   `/financial-report/create?reportId=${draftItem?.id}&step=2`
              // );
              // setIsDeleteDialogOpened(true);
              // setIsPopupOpened(false);
            }}
          />
          <PopupItem
            name="Rename"
            Icon={EditIcon}
            // isDelete
            onClick={(e) => {
              setIsRenameDialogOpened(true);
              setIsPopupOpened(false);
              // navigate(
              //   `/financial-report/create?reportId=${draftItem?.id}&step=2`
              // );
              // setIsDeleteDialogOpened(true);
              // setIsPopupOpened(false);
            }}
          />
          <PopupItem
            name="Delete"
            Icon={DeleteOutlined}
            isDelete
            onClick={() => {
              setIsDeleteDialogOpened(true);
              setIsPopupOpened(false);
            }}
          />
        </div>
      </ComponentPopup>
      {isDeleteDialogOpened && (
        <DeleteReportModal
          open={isDeleteDialogOpened}
          name={draftItem?.name}
          reportId={draftItem?.id}
          handleClose={() => {
            setIsDeleteDialogOpened(false);
          }}
        />
      )}
      {isRenameDialogOpened && (
        <RenameReportModal
          open={isRenameDialogOpened}
          reportName={draftItem?.name}
          reportId={draftItem?.id}
          handleClose={() => {
            setIsRenameDialogOpened(false);
          }}
        />
      )}
    </div>
  );
};
export default DraftItem;
