import ContentCard from "../../atoms/ContentCard";
import React, { useContext, useState } from "react";
import Title from "../../atoms/Title";
import LeftControl from "../../sub-components/LeftControl";
import RightControl from "../../sub-components/RightControl";
import UploadOption from "./UploadOption";
import { DownloadOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import { v4 as uuidv4 } from "uuid";

import { ReactComponent as TableIcon } from "../../assets/svg-icons/table.svg";
import { ReactComponent as UploadIcon } from "../../assets/svg-icons/upload.svg";
import { ReactComponent as IntegrateIcon } from "../../assets/svg-icons/integrate.svg";
import ComponentTitle from "../../atoms/ComponentTitle";
import ComponentDropdown from "../../atoms/ComponentDropdown";
import ComponentSelectFileInput from "../../atoms/ComonentFileInput";
import ComponentSwitch from "../../atoms/ComponentSwitch";

// import tbFile from "@/assets/mockFiles/Preview.xlsx";

import DataUploadTable from "./DataUploadTable"; //pdf预览

import "./CreateOrEditReport.less";

import TB_Template from "../../assets/mockFiles/TB_Template.xlsx";
import {
  uploadDataSource,
  previewDataSourceHook,
} from "../../api-hooks/report";
import { useDispatch } from "react-redux";
import {
  setFirstDataSourceId,
  setSecondDataSourceId,
} from "../../store/report";
// import { ConfigContext } from "../newIndex";
// import { groupBy, unitConfigList } from "../common/helper";
// import { getTemplateData } from "../leftControl/UnitConfig/GroupRadio/templateData";

const StepOne = ({
  boxHeight,
  setBalanceSheetData,
  setIncomeSheetData,
  isEnablePeriod,
  setIsEnablePeriod,
  selectedSheet,
  setSelectedSheet,
  selectedFirstSheet,
  setSelectedFirstSheet,
  selectedSecondSheet,
  setSelectedSecondSheet,
  currentFileName,
  setCurrentFileName,
  previousFileName,
  setPreviousFileName,
  previewDataSource,
  setPreviewDataSource,
  secondPreviewDataSource,
  setSecondPreviewDataSource,
  setSelectedCurrentFile,
  selectedCurrentFile,
  selectedPreviousFile,
  setSelectedPreviousFile,
  stepOneErrorMessage,
  setStepOneErrorMessage,
  selectedColumns,
  setSelectedColumns,
  rowsToDisable,
  handleRowsChange,
}) => {
  const dispatch = useDispatch();
  const onUploadCurrentFile = async (file, period) => {
    console.log("Upload Current File", file);
    if (period === "current") {
      setSelectedCurrentFile(file);
    } else {
      setSelectedPreviousFile(file);
    }

    const data = new FormData();
    data.append("file", file);
    previewDataSourceHook(data, (response) => {
      let previewDataSource = [];
      response?.forEach((row) => {
        let colItems = [];
        row.forEach((cell) => {
          colItems.push({
            label: cell,
          });
        });
        previewDataSource.push({
          isDisabled: false,
          colItems: colItems,
        });
      });
      if (period === "current") {
        console.log("Preview Data Source Final data", previewDataSource);
        setPreviewDataSource([...previewDataSource]);
        dispatch(setFirstDataSourceId(""));
        setSelectedFirstSheet(true);
        setSelectedSecondSheet(false);
      } else {
        setSecondPreviewDataSource([...previewDataSource]);
        dispatch(setSecondDataSourceId(""));
        setSelectedFirstSheet(false);
        setSelectedSecondSheet(true);
      }
    });
  };

  return (
    <div style={{ display: "flex", flex: "1 1 auto" }}>
      <LeftControl boxHeight={boxHeight}>
        <ContentCard
          style={{ padding: 32, height: "100%" }}
          bodyStyle={{ padding: 0, height: "100%" }}
          size="large"
        >
          <div style={{ width: "inherit" }}>
            <Title
              title="Data Source"
              titleStyle={{
                fontSize: "18px",
                fontWeight: "800",
                lineHeight: "24px",
              }}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "32px",
              }}
            >
              <UploadOption
                name="Can Can Journal Entries"
                icon={<TableIcon />}
                style={{ marginRight: "16px" }}
                disabled
              />
              <UploadOption
                name="Data Upload"
                icon={<UploadIcon />}
                style={{ marginRight: "16px" }}
              />
              <UploadOption
                name="Integrate"
                icon={<IntegrateIcon />}
                disabled
              />
            </div>

            <div style={{ marginTop: "45px" }}>
              <ComponentTitle name="Select data type to upload:" />
              <ComponentDropdown
                style={{ width: "100%" }}
                optionList={[
                  {
                    label: "Trial Balance",
                    value: "TB",
                  },
                ]}
                value={selectedSheet}
                handleChange={(value) => {
                  setSelectedSheet(value);
                }}
              />
              <div style={{ display: "flex", alignItems: "center" }}>
                {selectedSheet === "JE" && (
                  <div
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      alignItems: "center",
                      color: "#4BB354",
                      cursor: "pointer",
                    }}
                  >
                    <DownloadOutlined />
                    <div style={{ marginLeft: "9px" }}>
                      <a href={TB_Template} download="JE_Template">
                        {" "}
                        Download JE Template
                      </a>
                    </div>
                  </div>
                )}
                {selectedSheet === "TB" && (
                  <div
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      alignItems: "center",
                      color: "#4BB354",
                      cursor: "pointer",
                    }}
                  >
                    <DownloadOutlined />
                    <div style={{ marginLeft: "9px" }}>
                      <a href={TB_Template} download="TB_Template">
                        Download TB Template
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div style={{ marginTop: "42px" }}>
              <ComponentTitle name="Current Period:" />
              <div style={{ display: "flex", alignItems: "center" }}>
                <ComponentSelectFileInput
                  onChange={(value) => {
                    setCurrentFileName(value);
                  }}
                  value={currentFileName}
                  onUploadFile={(file) => {
                    onUploadCurrentFile(file, "current");
                  }}
                />
                {currentFileName && previewDataSource && (
                  <div
                    className={`${"file-settings-container"} ${
                      selectedFirstSheet ? "selected" : ""
                    }`}
                    onClick={() => {
                      setSelectedSecondSheet(false);
                      setSelectedFirstSheet(!selectedFirstSheet);
                    }}
                  >
                    Settings
                  </div>
                )}
              </div>
              {stepOneErrorMessage && (
                <div
                  style={{
                    color: "#D14343",
                    fontSize: "12px",
                    marginTop: "2px",
                  }}
                >
                  {stepOneErrorMessage}
                </div>
              )}

              <ComponentSwitch
                name="Enable period comparison"
                checked={isEnablePeriod}
                onChange={(checked) => {
                  setIsEnablePeriod(checked);
                }}
                style={{ marginTop: "31px" }}
              />
              {isEnablePeriod && (
                <div style={{ marginTop: "23px" }}>
                  <ComponentTitle name="Previous Period:" />
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <ComponentSelectFileInput
                      onChange={(value) => {
                        setPreviousFileName(value);
                      }}
                      value={previousFileName}
                      onUploadFile={(file) => {
                        onUploadCurrentFile(file, "previous");
                      }}
                    />
                    {previousFileName && secondPreviewDataSource && (
                      <div
                        className={`${"file-settings-container"} ${
                          selectedSecondSheet ? "selected" : ""
                        }`}
                        onClick={() => {
                          setSelectedFirstSheet(false);
                          setSelectedSecondSheet(!selectedSecondSheet);
                        }}
                      >
                        Settings
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </ContentCard>
      </LeftControl>
      <RightControl boxHeight={boxHeight}>
        <ContentCard
          style={{ padding: "0px 32px", height: "100%" }}
          bodyStyle={{ padding: 0, height: "100%" }}
          size="large"
        >
          {!selectedFirstSheet && !selectedSecondSheet && (
            <div
              style={{
                height: "inherit",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              No actions Available
            </div>
          )}
          {(selectedFirstSheet || selectedSecondSheet) && (
            <DataUploadTable
              selectedSheet={selectedSheet}
              previewDataSource={previewDataSource}
              secondPreviewDataSource={secondPreviewDataSource}
              selectedFirstSheet={selectedFirstSheet}
              selectedSecondSheet={selectedSecondSheet}
              selectedCurrentFile={selectedCurrentFile}
              selectedPreviousFile={selectedPreviousFile}
              setStepOneErrorMessage={setStepOneErrorMessage}
              stepOneErrorMessage={stepOneErrorMessage}
              selectedColumns={selectedColumns}
              setSelectedColumns={setSelectedColumns}
              rowsToDisable={rowsToDisable}
              handleRowsChange={handleRowsChange}
            />
          )}
        </ContentCard>
      </RightControl>
    </div>
  );
};

export default StepOne;
