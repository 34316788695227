import React, { useEffect, useState } from "react";
import ComponentModal from "../../atoms/ComponentModal";

import "./CategoryWarningModal.less";
const CategoryWarningModal = ({ 
  open,
  handleClose,
  showAllCategoryData,
  setShowCategoryDataModal,
}) => {
  
  return (
    <ComponentModal
      open={open}
      handleClose={handleClose}
    //   backText="Back"
      submitText="Okay"
      title="Maping Warning"
      handleOk={() => {setShowCategoryDataModal(false)}}
    >
     <div style={{fontWeight:'500'}}>Maping is Incomplete for the following Accounts :</div>
      <div style={{marginTop:'10px'}}>
        {showAllCategoryData.map((item)=>{
            return <>
            <div>{item?.label}</div>
            </>
        })}
      </div>
    </ComponentModal>
  );
};

export default CategoryWarningModal;
