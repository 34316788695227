import React, { useState } from "react";
import { Input, Button } from "antd";

const ComponentSelectFileInput = ({ value, onUploadFile, onChange }) => {
  const [fileName, setFileName] = useState("");
  let upload = null;
  const onChangeFile = (event) => {
    event.stopPropagation();
    event.preventDefault();
    let file = event.target.files[0];
    // console.log("Fiole", file?.name, file);
    onChange(file?.name);
    if (onUploadFile) {
      onUploadFile(file);
    }

    // setState({ file }); /// if you want to upload latter
  };

  return (
    <Input.Group compact style={{ display: "flex" }}>
      <Input
        value={value}
        style={{
          borderTopLeftRadius: "4px",
          borderBottomLeftRadius: "4px",
          border: "1px solid #D8DAE5",
          borderRight: "none",
        }}
      />
      <Button
        onClick={() => {
          upload.click();
        }}
        style={{
          borderTopRightRadius: "4px",
          borderBottomRightRadius: "4px",
          border: "1px solid #D8DAE5",
          // borderLeft: "none",
        }}
      >
        Select File
      </Button>
      <input
        id="myInput"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        type="file"
        ref={(ref) => (upload = ref)}
        style={{ display: "none" }}
        onChange={onChangeFile}
      />
    </Input.Group>
  );
};

export default ComponentSelectFileInput;
