import { createSlice } from "@reduxjs/toolkit";
// Slice
const accountTemplatesSlice = createSlice({
  name: "accountTemplates",
  initialState: {
    templates: [],
  },
  reducers: {
    setAccountTemplates: (state, action) => {
      state.templates = action.payload;
    },
  },
});

export default accountTemplatesSlice.reducer;
// Actions
export const { setAccountTemplates } = accountTemplatesSlice.actions;
