// import less
import { Fragment, useEffect, useState } from "react";
import ComponentInput from "../../atoms/ComponentInput";
import ComponentModal from "../../atoms/ComponentModal";

import { useDispatch } from "react-redux";
import { renameReportHook } from "../../api-hooks/report";
import { setCompaniesWithReports } from "../../store/company";
import { fetchComapnyWithReportsHook } from "../../api-hooks/company";
import { notification } from "antd";

const RenameReportModal = ({ open, handleClose, reportId, reportName }) => {
  const [name, setName] = useState(reportName);
  const dispatch = useDispatch();
  const onRename = () => {
    renameReportHook(
      {
        reportId,
        name,
      },
      (response) => {
        console.log("Report Updated Successfully", response);
        notification.success({
          message: "Report Updated Successfully",
        });
        fetchComapnyWithReportsHook((response) => {
          if (response) {
            dispatch(setCompaniesWithReports(response));
          }
        });
        handleClose();
      }
    );
  };
  return (
    <ComponentModal
      open={open}
      handleClose={handleClose}
      backText="Back"
      submitText="Confirm"
      title="Rename Report"
      handleOk={() => {
        onRename(reportId, name);
        handleClose();
      }}
    >
      <div className={"save-reports-heading-container"}>
        <div className={"save-reports-heading-title"}>Report Name</div>
        <ComponentInput
          value={name}
          onChange={(value) => {
            setName(value);
          }}
        />
      </div>
    </ComponentModal>
  );
};

export default RenameReportModal;
