import api from "./api-intercepter";

export const getAccountEquationHook = (values, callback) => {
  api
    .get(`/accounting-equations?filter=report.id||eq||${values.reportId}`)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};

export const createAccountEquationHook = (values, callback) => {
  const data = JSON.stringify({
    name: values?.name,
    description: values?.description,
    equation: values?.equation,
    report: {
      id: values?.reportId,
    },
  });
  console.log("Create Equation Values", values);
  api
    .post(`/accounting-equations`, data)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};

export const deleteAccountEquationHook = (values, callback) => {
  api
    .delete(`/accounting-equations/${values?.id}`)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};

export const updateAccountEquationHook = (values, callback) => {
  const data = JSON.stringify({
    name: values?.name,
    description: values?.description,
    equation: values?.equation,
    report: {
      id: values?.reportId,
    },
  });
  api
    .patch(`/accounting-equations/${values?.id}`, data)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};

export const getEquationPreviewHook = (values, callback) => {
  const data = JSON.stringify({
    content: values?.content,
  });
  api
    .post(`/report-generators/equations/preview/${values?.reportId}`, data)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};
