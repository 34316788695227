import api from "./api-intercepter";

export const getNoteTemplateHook = (callback) => {
  api
    .get(`/report-template-categories/fetch-report-template`)
    .then(function (response) {
      if (response?.data?.data) {
        callback(response?.data?.data);
      }
    })
    .catch(function (error) {
      console.log(error);

      // callback(error);
    });
};

export const createSubSectionHook = (values, callback) => {
  const data = JSON.stringify({
    category: {
      id: values?.sectionId,
    },
    type: "text",
    data: {
      name: values?.name ?? "Untitled",
      html: "<p></p>",
    },
    visibleIndex: values?.visibleIndex,
  });
  api
    .post(`/report-templates`, data)
    .then(function (response) {
      if (response?.data) {
        callback(response?.data);
      }
    })
    .catch(function (error) {
      console.log(error);

      // callback(error);
    });
};

export const createSectionHook = (values, callback) => {
  const data = JSON.stringify({
    name: values?.name ?? "Untitled",
    type: values.typeName,
    visibleIndex: values.indexValue,
  });
  api
    .post(`/report-template-categories`, data)
    .then(function (response) {
      if (response?.data) {
        callback(response?.data);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const createSectionVersionHook = (values, callback) => {
  const data = JSON.stringify({
    reportTemplateCategory: {
      id: values?.reportTemplateCategoryId,
    },
    data: {
      name: "",
      html: values?.htmlData,
    },
    name: values?.name || "Version 1",
  });
  api
    .post(`/report-template-versions`, data)
    .then(function (response) {
      if (response?.data) {
        callback(response?.data);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const createSubSectionVersionHook = (values, callback) => {
  const data = JSON.stringify({
    reportTemplate: {
      id: values?.reportTemplateId,
    },
    data: {
      name: "",
      html: values?.htmlData,
    },
    name: values?.name || "Version 1",
  });
  api
    .post(`/report-template-versions`, data)
    .then(function (response) {
      if (response?.data) {
        callback(response?.data);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const updateSubSectionVersionHook = (values, callback) => {
  const data = JSON.stringify({
    reportTemplate: {
      id: values?.reportTemplateId,
    },
    data: {
      name: "",
      html: values?.html,
    },
    name: values?.name || "Version 1",
  });
  api
    .patch(`/report-template-versions/${values?.id}`, data)
    .then(function (response) {
      if (response?.data) {
        callback(response?.data);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const updateSectionVersionHook = (values, callback) => {
  const data = JSON.stringify({
    reportTemplateCategory: {
      id: values?.reportTemplateCategoryId,
    },
    data: {
      name: "",
      html: values?.html,
    },
    name: values?.name || "Version 1",
  });
  api
    .patch(`/report-template-versions/${values?.id}`, data)
    .then(function (response) {
      if (response?.data) {
        callback(response?.data);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
};
export const deleteSectionVersionHook = (values, callback) => {
  api
    .delete(`/report-template-versions/${values.id}`)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};
export const updateNoteTemplateSubCategoryHook = (values, callback) => {
  const data = JSON.stringify({
    name: values?.name,
    data: {
      html: values?.html,
      name: values?.name,
    },
    category: {
      id: values?.sectionId,
    },
  });

  updateSubSectionVersionHook(
    {
      reportTemplateId: values.id,
      html: values?.html,
      name: values?.versionName,
      id: values?.versionId,
    },
    (versionResponse) => {
      api
        .patch(`/report-templates/${values.id}`, data)
        .then(function (response) {
          callback(response?.data?.data);
        })
        .catch(function (error) {
          console.log(error);
          callback(error);
        });
    }
  );
};

export const updateNoteTemplateCategoryHook = (values, callback) => {
  const data = JSON.stringify({
    name: values?.name,
    data: {
      name: values?.name,
      html: values?.html,
    },
  });

  updateSubSectionVersionHook(
    {
      reportTemplateCategoryId: values.id,
      html: values?.html,
      name: values?.versionName,
      id: values?.versionId,
    },
    (versionResponse) => {
      api
        .patch(`/report-template-categories/${values.id}`, data)
        .then(function (response) {
          callback(response?.data?.data);
        })
        .catch(function (error) {
          console.log(error);
          callback(error);
        });
    }
  );
};

export const deleteNoteTemplateCategoryHook = (values, callback) => {
  api
    .delete(`/report-template-categories/${values.id}`)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};
export const deleteNoteTemplateSubCategoryHook = (values, callback) => {
  api
    .delete(`/report-templates/${values.id}`)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};

export const bulkUpdateNoteTemSubCatHook = (list) => {
  list.forEach((item) => {
    if (item.id) {
      const data = JSON.stringify({
        visibleIndex: item?.visibleIndex,
      });
      api
        .patch(`/report-templates/${item.id}`, data)
        .then(function (response) {
          //callback(response?.data?.data);
        })
        .catch(function (error) {
          console.log(error);
          //callback(error);
        });
    }
  });
};
