export const sortReportSections = (sections) => {
  const sortedSections = sections.sort((a, b) => {
    return a.visibleIndex - b.visibleIndex;
  });
  return sortedSections;
};

export const removeCommasFromNumbers = (value) => {
  let num = value.replace(/\,/g, ""); // 1125, but a string, so convert it to number
  return parseFloat(num, 10);
};

export const removeBrackets = (value) => {
  console.log("Remove Brackets", value);
  return `-${value.replace(/[()]/g, "")}`;
};

export const numberWithCommas = (price) => {
  price = Math.round(price);
  const positiveOrNegative = Math.sign(price);
  const priceWithComa = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if (positiveOrNegative === -1) {
    return `(${priceWithComa.replace("-", "")})`;
  }
  return priceWithComa;
};
