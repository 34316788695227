import { Tooltip } from "antd";
import "./RightControl.less";

import { ReactComponent as PreviewPDFIcon } from "../../assets/svg-icons/previewIcon.svg";

const RightControl = ({
  children,
  boxHeight,
  isCollapsed,
  setIsCollapsed,
  rightCollapsed,
}) => {
  return (
    <>
      {isCollapsed && (
        <div className="r-box-1550-collapsed" id="right-part-id">
          <div>
            <Tooltip placement="left" title="Show Preview">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setIsCollapsed(false);
                }}
              >
                <PreviewPDFIcon style={{ width: "16px", height: "16px" }} />
              </div>
            </Tooltip>
          </div>
        </div>
      )}
      {!isCollapsed && (
        <div
          className={rightCollapsed ? "r-boxed-1550-enlarged" : "r-box-1550"}
          id="right-part-id"
        >
          {children}
        </div>
      )}
    </>
  );
};

export default RightControl;
